import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  //z-index: 1200;

  .chips-title {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #998d96;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: capitalize;

    &.selected {
      color: #590060;
      border: none;
      background: #ffa9fc;
    }

    &:hover {
      background: #ffa9fc !important;
      color: #590060 !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 148px;
    left: 297px;
    //top: -90px;
    //left: 1000px;

    z-index: 10000;
    width: 420px;
    display: none;
    border-radius: 4px 4px 0 0;
    background: #372a36;
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: 16px 12px 4px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 207.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }
    }

    p {
      color: #d0c3cc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      border-bottom: 1px solid #614964;

      > div:first-of-type {
        border-right: 1px solid #614964;
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: #ffa9fc;
        color: #590060;
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }

  .select-field-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

export const ViewData = styled(Box)`
  position: absolute;
  top: 239px;
  left: 321px;
  background: #372a36;
  border-radius: 0 0 4px 4px;
  z-index: 10000;
  width: 420px;
  height: 56px;
  padding: 8px 12px;
  justify-content: flex-end;
  align-items: center;
  display: none;
  transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.isopen {
    display: flex;
  }
`

export const viewDataStyles = {
  borderColor: 'transparent !important',
  background: 'rgba(208, 188, 255, 0.08) !important',

  '&:hover': {
    background: 'rgba(208, 188, 255, 0.04) !important'
  }
}
