import { FC } from 'react'
import { Box } from '@mui/material'

/* Utils */
import { IntegrationInstance } from '../../../../models'

/* Components */
// import EnvironmentStats from './EnvironmentStats'
import StubPerformanceScore from './DashboardLoading/components/StubPerformanceScore'
import OverallPerformanceScore from './OverallPerformanceScore/OverallPerformanceScore'
import InsightsWidget from './InsightsWidget'

interface Props {
  performanceScore: number
  configuredIntegrations: IntegrationInstance[]
  activeUsers: number
  availableCpis: string[]
  cpisActivated: number
}

const DashboardHeroEmpty: FC<Props> = ({
  performanceScore
  // configuredIntegrations,
  // activeUsers,
  // availableCpis,
  // cpisActivated
}) => (
  <>
    <Box className="performance-score-section">
      <OverallPerformanceScore performanceScore={performanceScore} />
      <StubPerformanceScore />
    </Box>
    <InsightsWidget unreadInsightsCount={0} insightsCountLoading={false} insightsData={null} />
  </>
  // <OverallPerformanceScore performanceScore={performanceScore} />
  // <StubPerformanceScore />
  // <EnvironmentStats
  //   cpisActivated={cpisActivated}
  //   dataSources={configuredIntegrations.length}
  //   activeUsers={activeUsers}
  //   availableCPIs={availableCpis.length}
)

export default DashboardHeroEmpty
