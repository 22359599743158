import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled/macro'

export const popperProps = {
  sx: {
    marginTop: '-10px !important'
  }
}

export const Container = styled(Box)`
  position: relative;
  display: inline-flex;
  margin: 0 auto;
  flex-grow: 1;

  @media (max-width: 1445px) {
    flex-grow: 0;
    margin: 0;
  }

  .over-perform {
    position: absolute;
    right: -151px;
    bottom: 0;

    .info {
      display: flex;
      align-items: center;
      gap: 7px;

      .over-perform-value {
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }

    .over-perform-desc {
      color: white;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`

export const CircleInner = styled(CircularProgress)`
  color: #3754fc;
  animation-duration: 550ms;
  position: absolute;
  left: 0;
`

export const CircleOuter = styled(CircularProgress)`
  color: #ffa9fc;
`

export const ValueWrapper = styled(Box)`
  top: 5px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 200px;
  flex-direction: column;

  @media (max-width: 1440px) {
    top: 0px;
  }

  .value {
    color: #fff;
    text-shadow: 0px 4.341px 4.341px rgba(0, 0, 0, 0.3), 0px 8.683px 13.024px rgba(0, 0, 0, 0.15);
    font-family: 'Quicksand', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 7px;
  }

  .caption {
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`
