import { FC } from 'react'
import { Typography, OutlinedInput, InputProps } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { FormControl } from './styles/controlledTextField.styles'

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-background-clip': 'text !important',
            '-webkit-text-fill-color': '#d0c3cc'
          },
          '&::placeholder': {
            overflow: 'hidden',
            color: '#867083',
            textOverflow: 'ellipsis',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            opacity: 1
          }
        }
      }
    }
  }
})

type Props = InputProps & {
  name: string
  label: string
}

const ControlledTextFieldCustomLabel: FC<Props> = ({ label, ...rest }) => {
  // const [field] = useField(name)

  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="standard">
        <Typography className="form-control-label">{label}</Typography>
        {/* <TextField {...field} {...rest} /> */}
        <OutlinedInput {...rest} />
      </FormControl>
    </ThemeProvider>
  )
}

export default ControlledTextFieldCustomLabel
