import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  //width: 428px;
  width: 100%;
  //height: 380px;
  border-radius: 24px;
  flex-basis: 33.33%;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 16px 16px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  //@media (min-width: 1100px) {
  //  width: 370px !important;
  //  min-width: 370px;
  //  max-width: 370px;
  //}
  //@media (min-width: 1440px) {
  //  width: 423px !important;
  //  min-width: 423px;
  //}
  //
  //@media (min-width: 1920px) {
  //  width: 529px;
  //  min-width: 529px;
  //}

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;

    .header-title {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      .title {
        color: #e9e0e4;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
        margin-left: 8px;
      }
    }

    .select-wrapper {
      .MuiFormControl-root {
        margin-bottom: 0;

        .MuiOutlinedInput-root {
          outline: 0 !important;
          background: transparent !important;
          border-radius: 8px !important;
          border: 1px solid #998d96 !important;
          color: #d0c3cc !important;
        }
      }
    }
  }

  .card-container {
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;

    .benchmark-card-wrapper {
      border-radius: 24px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      padding: 12.75px 16px;
    }
  }
`
