import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const ConnectionDetailsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#998D96'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    fontFamily: '"Quicksand", sans-serif',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    background: '#362E35',
    color: '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
  }
})
