import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ResetWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1e1a1d;

  .reset-button {
    border-radius: 100px;
    cursor: pointer;
    color: #ffa9fc;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: none;
    width: 100% !important;
    height: 40px;

    &:hover {
      background: rgba(234, 221, 255, 0.08);
    }

    &.Mui-disabled {
      background: #483646 !important;
      opacity: 1;
      color: #7f747c;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
    }
  }
`
