import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #372a36;

  .search-icon {
    position: absolute;
    top: 18px;
    left: 8px;
    z-index: 1;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  .clear-btn {
    position: absolute;
    right: 20px;
  }

  .search-bar {
    width: 100%;
    border-radius: 4px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border: 1px solid #998d96;
    border-radius: 4px;
    background: #372a36;
    height: 40px;
    padding: 0 50px 0 40px;
    color: #d0c3cc;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    &::placeholder {
      color: #867083;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:focus {
      border-color: #ffa9fc;
    }
  }

  fieldset {
    border: none;
  }
`
