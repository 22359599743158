import { IntelFeedCardWrapper } from '../../../../dashboard.styles'
import { Skeleton } from '@mui/material'

import { SSMCardSkeletonWrapper, SSMCardSkeleton } from '../../../../../security-stack-map/components/ssmCard.styles'

const StubIntelFeedCard = () => (
  <IntelFeedCardWrapper
    read={false}
    sx={{
      width: '100% !important'
      // height: '100% !important'
    }}
  >
    <SSMCardSkeletonWrapper
      sx={
        {
          // width: '100% !important', height: '40px !important'
        }
      }
    >
      <SSMCardSkeleton>
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner"
        />
      </SSMCardSkeleton>
    </SSMCardSkeletonWrapper>
    <SSMCardSkeletonWrapper
      sx={
        {
          // width: '100% !important', height: '100% !important'
        }
      }
    >
      <SSMCardSkeleton>
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner"
        />
      </SSMCardSkeleton>
    </SSMCardSkeletonWrapper>
  </IntelFeedCardWrapper>
)

export default StubIntelFeedCard
