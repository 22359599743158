import { Box, Tabs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from '../../../theme'

export const DashboardContainer = styled(Box)`
  width: 100%;
  background: ${theme.colorTokens.surface.pageBackground};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .dashboard-title {
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .desktop-section {
    display: flex;
    flex-direction: column;
  }
`

export const HeaderWrapper = styled(Box)`
  //position: absolute;
  //top: 20px;
  //left: 88px;
  //display: flex;
  //align-items: center;
  //gap: 24px;
`

export const CompanyInfo = styled(Box)`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #4d444b;
  background: #3f293e;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 8px;

  .company-name {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const LogoWrapper = styled(Box)`
  z-index: 10000;
  display: flex;
  align-items: center;

  .MuiTypography-root {
    margin: 0 24px;
  }
`

export const LogoNewWrapper = styled(Box)`
  display: flex;
  align-items: center;

  img {
    max-width: 52px;
    height: 24px;
    object-fit: cover;
    object-position: center center;
  }

  .MuiTypography-root {
    margin: 0 24px;
  }
`

export const Main = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  background: #1e1a1d;
`

export const DashboardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #1e1a1d;
  //margin: 0 auto;
  margin: 0;
  //padding-top: 40px;
  //padding-bottom: 40px;
  padding: 24px;
  gap: 24px;

  //@media (min-width: 1920px) {
  //  max-width: 1681px !important;
  //}
  //@media (min-width: 1440px) {
  //  max-width: 1364px;
  //}
  //@media (max-width: 1439px) {
  //  max-width: 1204px;
  //}

  .main-content-container {
    display: flex;
    //flex-grow: 1;
    width: 100%;
    //gap: 40px;
    gap: 24px;
    border-radius: 16px;
    flex-basis: 100%;

    .main-scroll-wrapper {
      width: 100%;
      flex-basis: 66.66%;
    }
  }
`

export const HeroSectionWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  position: relative;
  width: 100%;
  flex-basis: 100%;

  .performance-score-section {
    width: 100%;
    flex-basis: 66.66%;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  //@media (max-width: 1400px) {
  //  justify-content: center;
  //  margin-bottom: 24px;
  //}
`

export const DashboardEmptyStateWrapper = styled(Box)`
  border-radius: 24px;
  background: #590060;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  width: 837px;
  height: 210px;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  opacity: 1;

  .text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin-right: 21px;
  }
`

export const IntelFeedWrapper = styled(Box)<{ width: number }>`
  background: transparent;
  border-radius: 16px;
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  box-sizing: border-box;
  flex-basis: 40%;
  width: 100%;

  @media (min-width: 1440px) {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;

      svg {
        margin-right: 8px;
      }
    }

    .MuiTypography-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }
`

export const IntelFeedCardWrapper = styled(Box)<{ read: boolean }>`
  border-radius: 8px;
  background: #372a36;
  display: flex;
  width: 488px;
  min-width: 488px;
  height: 270px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .header-container {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
  }

  .expanded-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      path {
        fill: #ffa9fc;
      }
    }

    .report-button {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      color: #ffa9fc;
      gap: 8px;
      text-transform: none;
      border-radius: 100px;

      svg {
        path {
          fill: #ffa9fc;
        }
      }

      &:hover {
        border-radius: 100px;
        background: #ec85ec;
        color: #590060;

        svg {
          path {
            fill: #590060;
          }
        }
      }
    }

    .expanded-icons {
      .MuiIconButton-root {
        &:hover {
          background: #ec85ec;
          color: #590060;

          svg {
            path {
              fill: #590060;
            }
          }
        }
      }
    }
  }
`

export const IntelFeedBackground = styled(Box)<{ url: string; fallBackUrl: string }>`
  width: 107px;
  min-width: 107px;
  height: 70px;
  border-radius: 8px;
  background: ${({ url, fallBackUrl }) =>
    `url("${url}") center/cover no-repeat, url("${fallBackUrl}") center/cover no-repeat`};
`

export const StubIntelFeedBackground = styled(Box)`
  width: 57px;
  height: 40px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
`

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-grow: 1;
`

export const IntelFeedTabs = styled(Tabs)`
  background: transparent;
  min-height: unset;
  height: 32px;

  .MuiButtonBase-root {
    width: 90px;
    height: 32px;
    min-height: unset;
    padding: 6px 12px;
    gap: 10px;
    background: transparent;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    text-transform: none;
    border: 1px solid #998d96;
    border-radius: 100px 0 0 100px;
    color: #ffa9fc;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;

    &:last-of-type {
      width: 80px;
      border-radius: 0 100px 100px 0;
      border-left: 0;
    }

    &.Mui-selected {
      background: #590060;
      color: #fff;
      border: 1px solid #998d96;

      &:last-of-type {
        border-left: 0;
      }
    }
  }
`

export const IntelFeedTabPanel = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 8px;
  min-height: 292px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 14px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: #4d444b;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }
`

export const FeedSectionWrapper = styled(Box)`
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 24px;
  border-radius: 16px;
  background: #292128;
  //height: 100%;
  //max-height: 405px;
  height: 405px;
`

export const MainScrollSection = styled(Box)`
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //width: calc(100% + 12px);
  //height: 100%;
  //max-height: 400px;
  height: 100%;
  max-height: unset;
  //overflow-y: hidden;
  overflow: visible;

  @media (max-width: 1440px) {
    //width: 750px;
  }

  @media (min-width: 1440px) {
    //width: 837px;
  }

  @media (min-width: 1920px) {
    //width: 1112px;
    padding: 22px 32px;
  }

  @media (max-width: 1400px) {
    flex-grow: unset;
  }

  .empty-wrapper {
    margin-top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      width: 577px;
      margin: 24px auto;
      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;

      @media (max-width: 600px) {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  .chart-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 3px;
    }

    .chart-title {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }

  .cpis-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .MuiTypography-root {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #e9e0e4;
    }

    svg {
      margin-right: 8px;

      path {
        fill: #e9e0e4;
      }
    }
  }

  .no-data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      color: #ffffff;
    }
  }
`

export const CPIsSectionHeader = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  .title-block {
    display: flex;
    align-items: center;
  }

  .MuiTypography-root {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #e9e0e4;
  }

  .date {
    color: #998d96;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  svg {
    margin-right: 8px;

    path {
      fill: #e9e0e4;
    }
  }
`

export const InfoChipsWrapper = styled(Box)<{ forbenchmark?: string; isstub?: string }>`
  display: flex;
  align-items: center;

  ${({ forbenchmark }) =>
    forbenchmark &&
    forbenchmark === 'true' &&
    `
    margin-bottom: 26px;
    width: 100%;
  `};

  ${({ isstub }) =>
    isstub &&
    isstub === 'true' &&
    `
      margin-top: 12px;
  `};

  .chip {
    display: flex;
    align-items: center;

    .chip-circle {
      margin-right: 4px;
      border-radius: 12px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      width: 12px;
      height: 12px;

      &.pink {
        background: #ffa9fc;
      }

      &.blue {
        background: #3754fc;
      }

      &.less {
        background: #8e9aff;
      }

      &.same {
        background: #f09543;
      }

      &.more {
        background: #b051b3;
      }

      &.stub {
        background: #4d444b;
      }
    }

    .MuiTypography-root {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      white-space: nowrap;

      ${({ forbenchmark }) =>
        forbenchmark &&
        forbenchmark === 'true' &&
        `
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.5px;
        `};
    }

    &:not(:first-of-type) {
      margin-left: 24px;
    }
  }
`

export const ExploreCategoriesWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`
