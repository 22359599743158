import { Wrapper } from './stubBenchmarking.styles'
import { Box, Typography } from '@mui/material'
import { BenchmarkingIcon } from '../../../../../components/svg'
import BenchmarkingSelectField from '../../Benchmarking/components/BenchmarkingSelectField'
import { InfoChipsWrapper } from '../../../dashboard.styles'
import StubBenchmarkCard from './Stubs/StubBenchmarkCard'

const cards = [0, 1, 2, 3, 4]

const StubBenchmarking = () => (
  <Wrapper>
    <Box className="header">
      <Box className="header-title">
        <BenchmarkingIcon />
        <Typography className="title">Benchmarking</Typography>
      </Box>
      <Box className="select-wrapper">
        <BenchmarkingSelectField
          options={['All Industries']}
          value="All Industries"
          handleChange={() => {
            //
          }}
        />
      </Box>
    </Box>

    <InfoChipsWrapper forbenchmark="true" isstub="true">
      <Box className="chip">
        <Box className="chip-circle stub" /> <Typography>Less Strict SLA</Typography>
      </Box>
      <Box className="chip">
        <Box className="chip-circle stub" /> <Typography>Same SLA</Typography>
      </Box>
      <Box className="chip">
        <Box className="chip-circle stub" /> <Typography>More Strict SLA</Typography>
      </Box>
    </InfoChipsWrapper>

    <Box className="card-container">
      {cards.map((n) => (
        <Box className="benchmark-card-wrapper" key={n}>
          <StubBenchmarkCard />
        </Box>
      ))}
    </Box>
  </Wrapper>
)

export default StubBenchmarking
