import { Box, AppBar as MuiAppBar } from '@mui/material'
import styled from '@emotion/styled'

export const Wrapper = styled(Box)<{ isFiltersOpen: string }>`
  .MuiPaper-root {
    overflow: visible;
    transition: all 0.2s ease-in-out;

    ${({ isFiltersOpen }) =>
      isFiltersOpen === 'false'
        ? `
        height: 0;
        opacity: 0;
    `
        : `
        height: 88px;
        opacity: 1;
  `}
  }

  transition: all 0.2s ease-in-out;
  overflow-x: auto;
  overflow-y: hidden;

  ${({ isFiltersOpen }) =>
    isFiltersOpen === 'false'
      ? `
        height: 0;
        opacity: 0;
    `
      : `
        opacity: 1;
        height: 88px;
  `}
`

export const AppBar = styled(MuiAppBar)<{ isFiltersOpen: string }>`
  top: 100px;
  position: unset;
  background: transparent;
  box-shadow: none;
  padding: 24px 24px 24px 24px;
  transition: 0.2s all ease-in-out;
  overflow-x: auto;

  .MuiToolbar-root {
    height: 40px;
    min-height: 40px;
    padding: 0;
    transition: 0.2s all ease-in-out;
    gap: 24px;
  }
`
