import { FC, MouseEvent } from 'react'
import { IconButton, MenuItem, Typography } from '@mui/material'

/* Components */
import { MarkMenu } from '../listToolbar.styles'

interface Props {
  markMenuOpen: boolean
  anchorEl: null | HTMLElement
  handleClick: (event: MouseEvent<HTMLElement>) => void
  handleClose: (option: string) => void
  markMenuOptions: string[]
  selectedOption: string
}

const SortMenu: FC<Props> = ({ markMenuOptions, markMenuOpen, selectedOption, handleClick, handleClose, anchorEl }) => {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={markMenuOpen ? 'long-menu' : undefined}
        aria-expanded={markMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        sx={{
          color: markMenuOpen ? '#D9BFD4' : '#FFA9FC',
          background: markMenuOpen ? '#590060' : '',
          '&:hover': {
            background: '#EC85EC',

            svg: {
              path: {
                fill: '#590060'
              }
            }
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M2 18.3333H8.66667V16.1111H2V18.3333ZM2 5V7.22222H22V5H2ZM2 12.7778H15.3333V10.5556H2V12.7778Z"
            fill={markMenuOpen ? '#fff' : '#FFA9FC'}
          />
        </svg>
      </IconButton>
      <MarkMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={markMenuOpen}
        onClose={() => handleClose('')}
        sx={{
          '.MuiPaper-root': {
            marginTop: '5.5px',
            borderRadius: '4px',
            width: '200px',
            maxHeight: '167px',
            border: '1px solid #4D444B',
            background: '#372A36',
            padding: 0,

            '.MuiList-root': {
              padding: '8px',

              '.MuiMenuItem-root': {
                justifyContent: 'space-between',
                borderRadius: '4px',
                height: '40px',
                overflow: 'hidden',
                color: '#FFF',
                textOverflow: 'ellipsis',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                padding: '0 8px',

                svg: {
                  transition: 'all .4s ease',

                  path: {
                    transition: 'all .4s ease'
                  }
                },

                '&:hover': {
                  background: '#573F56'
                }
              }
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography className="sort-menu-title">Sort by</Typography>
        {markMenuOptions.map((option) => (
          <MenuItem key={option} selected={false} onClick={() => handleClose(option)}>
            {option}
            {selectedOption === option && (
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.35588 16.5296L3.61455 11.7882L2 13.3914L8.35588 19.7473L22 6.10318L20.3968 4.5L8.35588 16.5296Z"
                  fill="#FFA9FC"
                />
              </svg>
            )}
          </MenuItem>
        ))}
      </MarkMenu>
    </div>
  )
}

export default SortMenu
