import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { DataStatus } from '../../../../../../models'

export const ChartWrapper = styled(Box)<{ status?: DataStatus; severity: 'healthy' | 'problematic'; zone: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding: 24px;
  max-width: 1686px;
  margin-left: auto;
  margin-right: auto;

  .chart-container {
    width: 100%;
    height: 499px;
    margin-right: 41px;
    max-width: 1724px;

    @media (min-height: 801px) {
      height: 500px;
    }

    @media (min-height: 901px) {
      height: 568px;
    }

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: #4e414d;
              stroke: #d0c3cc;
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
          border: 0 solid #998d96;
          border-radius: 16px;
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
            border: 0 solid #998d96;
            border-radius: 16px;
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #998d96;
        background: #4d444b;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
        z-index: 10000;

        .prediction-text-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 232px;
          gap: 8px;
          margin-top: -4px;

          .tooltip-performance {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          svg {
            margin-left: -6px;
          }
        }

        .tooltip-units-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 232px;

          .tooltip-units-prediction {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-performance {
          margin: 8px 0;

          &.prediction-date {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-top: 2px;
          }
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`

export const DescriptionWrapper = styled(Box)<{
  status?: DataStatus
  zone: string
}>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 16px 16px 0 0;
  background: #131013;
  border: 1px solid rgba(147, 143, 153, 0.16);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  height: 150px;

  @media (min-height: 801px) {
    gap: 10px;
  }

  @media (max-width: 1440px) and (max-height: 900px) {
    gap: 10px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1440px) and (min-height: 901px) {
    gap: 10px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1366px) and (max-height: 768px) {
    gap: 10px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1280px) {
    gap: 10px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-height: 800px) {
    gap: 10px;
    height: 119px;
  }

  .box-wrapper {
    border-radius: 12px;
    background: #281e28;
    padding: 16px;

    .measurement-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 16px;

      .status {
        display: flex;
        align-items: flex-end;

        span {
          font-family: 'Quicksand', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4px;
        }

        @media (max-width: 1440px) {
          font-size: 2.3rem !important;
          line-height: normal !important;
        }
      }

      .column {
        flex-direction: column;
      }
    }

    .box-wrapper-options {
      display: flex;
      gap: 12px;

      @media (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }

    .label-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      text-transform: none;

      > div {
        cursor: pointer;
      }

      .title {
        margin-bottom: 8px;
      }

      .count {
        border-radius: 100px;
        border: 1px solid #998d96;
        background: #ffe082;
        position: absolute;
        top: 30px;
        right: 5px;
        min-width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #66006e;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }

  .wrapper-options {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 679px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  .wrapper-options,
  .legend-wrapper,
  .wrapper-measurement {
    width: 100%;
  }

  .wrapper-measurement {
    @media (max-width: 1280px) {
      max-width: 266px;
    }

    @media (max-width: 1440px) {
      max-width: 312px;
    }

    @media (min-width: 1441px) {
      max-width: 422px;
    }
  }

  .px-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .legend-wrapper {
    padding: 16px;
    max-width: 363px;

    @media (max-width: 1400px) {
      max-width: 187px;
      width: auto;
    }

    @media (max-width: 1280px) {
      max-width: 187px;
    }

    @media (max-height: 800px) {
      padding-top: 10px;
      padding-bottom: unset;

      .legend-key {
        margin-right: 13px;
      }

      .legend-container {
        gap: 3px !important;
      }
    }

    .legend {
      @media (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        flex-flow: nowrap;
        gap: 12px;
        align-items: start;
        justify-content: center;
      }
    }

    @media screen and (max-width: 1440px) and (max-height: 800px) {
      max-width: 279px;

      .legend {
        flex-flow: column wrap;

        .legend-container {
          flex-flow: row wrap;
          gap: 8px 16px !important;
        }
      }
    }
  }

  .text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #ffffff;

    .severity {
      text-transform: capitalize;
    }
  }

  .status {
    font-family: 'Quicksand', sans-serif;
    font-size: 76.533px;
    font-style: normal;
    font-weight: 400;
    line-height: 57.439px;
    color: #aed581;
    margin-top: 0;
    text-transform: capitalize;

    ${({ status, zone }) =>
      status &&
      status === DataStatus.Ready &&
      zone === 'green' &&
      `
        color: #AED581;
      `};

    ${({ status, zone }) =>
      status &&
      status === DataStatus.Ready &&
      zone === 'red' &&
      `
        color: #FF8A80;
      `};

    ${({ status }) =>
      status &&
      status === DataStatus.Initializing &&
      `
        color: #FAC364;
      `};

    ${({ status }) =>
      status &&
      status === DataStatus.Recalculating &&
      `
        font-size: 58px;
        line-height: normal;
        color: #FAC364;
      `};
  }

  .updated-at {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #d0c3cc;
  }

  .legend {
    width: auto;
    display: flex;
    flex-flow: column wrap;

    .legend-container {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;

      @media (max-width: 1500px) {
        gap: 8px;
      }
    }

    .legend-key {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin-bottom: 8px;
    }

    .legend-item {
      display: flex;
      align-items: center;

      .MuiTypography-root {
        margin-left: 8px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }

  .data-source-wrapper {
    display: flex;
    flex-flow: column wrap;

    .data-source-select-wrapper {
      max-width: 180px;
      margin-top: 4px;
    }

    .title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin-bottom: 4px;
    }
  }
`

export const LabelsMenuWrapper = styled(Box)`
  border-radius: 12px;
  background: #281e28;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 128px;
  width: 100%;

  @media (min-width: 1920px) {
    padding-top: 0 !important;
  }

  @media (max-width: 1400px) {
    max-width: none;
    width: auto;
  }

  @media (max-height: 800px) {
    padding: 16px 5px 0 5px;
    max-width: 60px;
  }

  .measurement-wrapper {
    width: 100%;
    min-width: 350px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .box-wrapper-options {
    display: flex;
    gap: 12px;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-transform: none;

    > div {
      cursor: pointer;
    }

    .title {
      margin-bottom: 7px;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    .count {
      border-radius: 100px;
      border: 1px solid #998d96;
      background: #ffe082;
      position: absolute;
      top: 30px;
      right: 5px;
      min-width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #66006e;
      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 145.455% */
      letter-spacing: 0.5px;
    }
  }
`

export const StatusValueText = styled(Typography)<{ status?: DataStatus; zone: string }>`
  font-family: 'Quicksand', sans-serif;
  font-size: 76.533px;
  font-style: normal;
  font-weight: 400;
  line-height: 57.439px;
  color: #aed581;
  margin-top: 0;
  text-transform: capitalize;

  ${({ status }) =>
    (status && status === DataStatus.Initializing) ||
    (status === DataStatus.Recalculating &&
      `
        color: #FAC364;
      `)};

  ${({ status, zone }) =>
    status &&
    status === DataStatus.Ready &&
    zone === 'green' &&
    `
        color: #AED581;
      `};

  ${({ status, zone }) =>
    status &&
    status === DataStatus.Ready &&
    zone === 'red' &&
    `
        color: #FF8A80;
      `};

  ${({ status }) =>
    status &&
    status === DataStatus.Initializing &&
    `
        color: #FAC364;
      `};
`

export const OnyxAIWrapper = styled(Box)<{ has_model: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #281e28;
  padding: 12px 16px;
  width: 100%;
  max-width: 80px;

  .onyxai-text {
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-top: 8px;
    margin-bottom: 2px;

    ${({ has_model: hasModel }) =>
      hasModel === 'false' &&
      `
      margin-top: 0;
    `};
  }

  .onyxai-btn {
    margin-top: -2px;
    text-transform: none;
    padding: 0;
  }

  .not-available-svg {
    margin-top: 4px;

    &:hover {
      circle {
        fill-opacity: 0.12;
        stroke-opacity: 0.4;
      }
    }
  }
`

export const OnyxAITooltipWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .tooltip-title {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .tooltip-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`

export const PredictionDescriptionWrapper = styled(Box)<{ status?: DataStatus; zone: string }>`
  border-radius: 16px 16px 0 0;
  background: #131013;
  padding: 18px;
  gap: 18px;
  width: 100%;
  height: 119px;
  display: flex;
  align-items: center;

  @media (min-width: 1920px) {
    height: 150px;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1px;
    border-radius: 12px;
    background: #281e28;
    width: 217px;
    height: 99px;
    padding: 12px 16px 12px 12px;

    @media (min-width: 1440px) {
      width: 262px;
      padding: 12px 37px;
    }

    @media (min-width: 1920px) {
      width: 270px;
      height: 114px;
      padding: 19px 40px;
    }

    .recent-performance-value {
      color: #aed581;
      font-family: Quicksand, sans-serif;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 5px;

      span {
        color: #aed581;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
      }

      ${({ status, zone }) =>
        status &&
        zone === 'green' &&
        `
        color: #AED581;
        
        span {
          color: #AED581;
        }
      `};

      ${({ status, zone }) =>
        status &&
        zone === 'red' &&
        `
        color: #FF8A80;
        
        span {
          color: #FF8A80;
        }
      `};
    }

    .recent-performance-text {
      color: #aed581;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;

      ${({ status, zone }) =>
        status &&
        zone === 'green' &&
        `
        color: #AED581;
      `};

      ${({ status, zone }) =>
        status &&
        zone === 'red' &&
        `
        color: #FF8A80;
      `};
    }

    .updated-at {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
    }

    &.section-prediction {
      width: 220px;
      padding: 12px 16px 12px 12px;

      .recent-performance-value {
        color: #bb95ea;

        span {
          color: #bb95ea;
        }
      }

      .recent-performance-text {
        color: #bb95ea;
      }

      @media (min-width: 1440px) {
        width: 265px;
        padding: 12px 37px;
      }

      @media (min-width: 1920px) {
        width: 267px;
        padding: 19px 40px;
      }
    }

    &.section-legend {
      width: 198px;
      padding: 10.8px 16px;

      @media (min-width: 1440px) {
        width: 232px;
        padding: 10.8px 33px;
      }

      @media (min-width: 1920px) {
        width: 393px;
        padding: 19px 29px 20.4px;
      }

      .legend-title {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-bottom: 10px;
      }

      .legend-wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        flex-basis: 100%;
        row-gap: 4px;

        @media (min-width: 1920px) {
          row-gap: 10px;
        }

        .legend-item {
          display: flex;
          align-items: center;
          flex-basis: 50%;
          gap: 8px;

          .MuiTypography-root {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
      }
    }

    &.section-controls {
      flex-direction: row;
      gap: 36px;
      justify-content: flex-start;
      width: 459px;
      padding: 10px 0 21px 17px;

      @media (min-width: 1440px) {
        width: 477px;
        flex-grow: 1;
        padding: 10px 0 21px 26.5px;
      }

      @media (min-width: 1920px) {
        width: 666px;
        padding: 19px 0 19px 36px;
      }

      .data-source-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
`
