import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, FormControl, InputAdornment, MenuItem as MuiMenuItem, MenuList, Select } from '@mui/material'
import { getCpiCategoryColors } from '../../../../lib/utils'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #998d96;
  position: relative;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: #FFA9FC !important;
      }
    }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    min-width: 122px;
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.6em);
    right: 13px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: #ffa9fc !important;
    }
  }
`
export const MenuListWrapper = styled(MenuList)<{ length: number }>`
  ${({ length }) =>
    length &&
    length > 10 &&
    `
       overflow-y: scroll;
       max-height: 480px;
  `};

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background: #483646;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }
`

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: 8px;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    white-space: nowrap;
  }
`

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
  minwidth?: string
}>`
  outline: none;
  min-width: 121px !important;
  height: 40px;
  border-radius: 100px;
  display: flex;
  position: relative;
  max-width: ${({ isSort }) => (isSort ? 'auto' : '121px')};

  @media (min-width: 1170px) {
    //max-width: 175px !important;
    max-width: 200px !important;
  }

  ${({ noborder }) =>
    noborder !== 'true' &&
    `
    border: 1px solid #998d96;
  `};

  ${({ minwidth }) =>
    minwidth &&
    `
      min-width: ${minwidth};
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: white;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${({ isSort }) => (isSort ? '#fff' : '#d0c3cc')};
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
      }

      @media (max-width: 1170px) {
        display: none;
      }
    }
  }

  &:hover {
    border-radius: 100px;
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #ffa9fc;
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const MenuItem = styled(MuiMenuItem)`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;

  img {
    margin-right: 8px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.16) !important;
  }

  .MuiCheckbox-root {
    padding-right: 19px;
    padding-left: 0;
  }

  .MuiListItemText-root .MuiTypography-root {
    font-family: Quicksand, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #d0c3cc;
  }

  display: flex;
  align-items: center;
  width: 100%;

  .image-wrapper {
    display: flex;

    > img {
      width: 18px;
      height: 18px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      margin: -4.5px;
      border-radius: 50%;

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }

  .text-wrapper {
    margin-left: 13px;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      minWidth: '321px !important',
      maxHeight: '600px !important',

      '.MuiList-root': {
        // background: '#573F56',
        paddingTop: '0px',
        paddingBottom: '0px',
        maxHeight: '248px'
      },

      '.MuiMenuItem-root': {
        height: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#E9E0E4',
          paddingLeft: '19px'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.sources-count-text': {
          color: '#fff',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          marginLeft: '8px'
        },

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#483646'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#ffa9fc',
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}

export const DataSourceInputAdornment = styled(InputAdornment)`
  .adornment-wrapper {
    display: flex;
    gap: 8px;

    .sources-count-text {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    img {
      width: 18px;
      height: 18px;
      //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      border-radius: 50%;

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }
`

export const LibraryCardWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;
  width: 880px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #342f32;
  }

  .cpi-new {
    border-radius: 18px;
    background: #3754fc;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 8px;
    margin-left: 8px;

    > .MuiTypography-root {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .favorite-icon {
    position: absolute;
    top: 5px;
    right: 16px;

    .MuiButtonBase-root {
      &:hover,
      &:focus,
      &:active {
        background: rgba(202, 196, 208, 0.08);
      }
    }
  }

  @media (min-width: 1280px) {
    width: 920px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin: 20px 0 8px;

    .title {
      color: #fff;
      text-align: start;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .cpi-name {
    display: inline-block;
    padding: 2px 8px;
    align-items: center;
    gap: 3px;
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    > .MuiTypography-root {
      color: #3f293e;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const LibraryFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  .left-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;

    .data-sources {
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #4d444b;
      padding: 7px 8px;
      position: relative;

      > .MuiTypography-root {
        color: #e9e0e4;
        font-family: Quicksand, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      .data-sources-wrapper {
        display: flex;
        align-items: center;
        margin-left: 7px;
        gap: 4px;

        .error-wrapper {
          svg {
            &:first-of-type {
              left: 10px !important;
            }
            &:last-of-type {
              left: 12px !important;
            }
          }
        }

        .data-sources-count-text {
          color: #e9e0e4;
          font-family: Quicksand, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4px;
          margin-left: 8px;
        }
      }
    }

    .framework-panel {
      border-radius: 8px;
      border: 1px solid #4d444b;
      padding: 7px 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .MuiButtonBase-root {
      margin-left: 16px;
    }
  }
`

export const LibraryFooterCategory = styled(Box)<{ category: string }>`
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 6px 16px;

  > .MuiTypography-root {
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  ${({ category }) => {
    const colors = getCpiCategoryColors(category)

    return `
      background: ${colors.background};

      > .MuiTypography-root {
        color: ${colors.color};
    `
  }};
`

export const LibraryFooterCustomCategory = styled(Box)<{ bgColor: string; textColor: string }>`
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 6px 16px;
  background: ${({ bgColor }) => bgColor};

  > .MuiTypography-root {
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${({ textColor }) => textColor};
  }
`

export const LibraryFooterLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${({ logo }) => `url('${logo}') no-repeat center`};
  background-size: cover;
  margin-left: 4px;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;

    &.category-breadcrumb {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #b15c0f;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
    margin-left: 8px;
    margin-right: 8px;
  }
`

export const ViewData = styled(Box)`
  position: absolute;
  top: 317px;
  left: 169px;
  background: #573f56;
  border-radius: 0 0 4px 4px;
  z-index: 10000;
  width: 420px;
  height: 56px;
  padding: 8px 12px;
  justify-content: flex-end;
  align-items: center;
  display: none;
  transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.isopen {
    display: flex;
  }
`

export const viewDataStyles = {
  borderColor: 'transparent !important',
  background: 'rgba(208, 188, 255, 0.08) !important',

  '&:hover': {
    background: 'rgba(208, 188, 255, 0.04) !important'
  }
}

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  z-index: 1350;

  @media (max-width: 1440px) {
    margin-top: 16px;
  }

  .chips-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #d0c3cc;
    margin-bottom: 8px;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #998d96;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: capitalize;

    &.selected {
      color: #590060;
      border: none;
      background: #ffa9fc;
    }

    &:hover {
      background: #ffa9fc !important;
      color: #590060 !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 148px;
    left: 169px;
    z-index: 10000;
    width: 420px;
    display: none;
    border-radius: 4px 4px 0 0;
    background: #573f56;
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: 16px 12px 4px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 207.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }
    }

    p {
      color: #d0c3cc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      border-bottom: 1px solid #614964;

      > div:first-of-type {
        border-right: 1px solid #614964;
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: #ffa9fc;
        color: #590060;
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }
`

export const IntegrationItem = styled(Box)<{ active?: boolean; iserrored?: string }>`
  position: relative;

  img {
    border: 1px solid #998d96;
    border-radius: 50%;

    ${({ active }) =>
      active &&
      `
       border: 1px solid #aed581;
    `};
  }

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #998d96;
    position: absolute;
    right: -3px;
    top: 10px;

    ${({ active }) =>
      active &&
      `
        background: #aed581;
      `};
  }

  .circle-new {
    z-index: 10;
    top: 6px;
    right: -7px;
  }

  ${({ iserrored }) =>
    iserrored &&
    iserrored === 'true' &&
    `
      span {
        background: #ff8a80;
      }
      
      img {
        border-color: #ff8a80;
      }
  `};
`

export const IntegrationTextItem = styled(Box)<{ active: boolean }>`
  .box-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 8px;

    .text {
      font-family: 'Quicksand', sans-serif;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 0 !important;
      color: #998d96 !important;

      ${({ active }) =>
        active &&
        `
        color: #aed581!important;
      `};
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: #998d96;
      ${({ active }) =>
        active &&
        `
        background: #aed581;
      `};
    }
  }
`
