import { FC } from 'react'
import { NoResultsWrapper } from '../onyxAIInsights.styles'
import { Link as MuiLink, Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M20.2778 19.7778H4.72222V4.22222H12.5V2H4.72222C3.48889 2 2.5 3 2.5 4.22222V19.7778C2.5 21 3.48889 22 4.72222 22H20.2778C21.5 22 22.5 21 22.5 19.7778V12H20.2778V19.7778ZM14.7222 2V4.22222H18.7111L7.78889 15.1444L9.35556 16.7111L20.2778 5.78889V9.77778H22.5V2H14.7222Z"
      fill="#FFA9FC"
    />
  </svg>
)

interface Props {
  version?: string
}

const NoIntegrations: FC<Props> = ({ version }) => {
  return (
    <NoResultsWrapper>
      <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="124" height="124" rx="62" stroke="#4D444B" strokeWidth="4" strokeDasharray="8 8" />
        <path
          d="M58 82C58 83.1 58.9 84 60 84H68C69.1 84 70 83.1 70 82V80H58V82ZM64 44C56.28 44 50 50.28 50 58C50 62.76 52.38 66.94 56 69.48V74C56 75.1 56.9 76 58 76H70C71.1 76 72 75.1 72 74V69.48C75.62 66.94 78 62.76 78 58C78 50.28 71.72 44 64 44ZM69.7 66.2L68 67.4V72H60V67.4L58.3 66.2C55.6 64.32 54 61.26 54 58C54 52.48 58.48 48 64 48C69.52 48 74 52.48 74 58C74 61.26 72.4 64.32 69.7 66.2Z"
          fill="#D9BFD4"
        />
      </svg>

      <Box className="no-results-content">
        <Typography className="no-results-header">No Insights Found</Typography>
        <Box>
          <Typography className="no-results-text">
            {version && version === 'cpi'
              ? 'You must have at least one CPI activated'
              : 'You must have at least one integration added and one CPI activated'}
          </Typography>
          <Typography className="no-results-text">for insights to be generated</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '32px'
          }}
        >
          {!version && (
            <Box
              sx={{
                a: {
                  padding: '8px 16px',
                  border: '1px solid #998d96',
                  backgroud: 'transparent',
                  borderRadius: '100px',
                  color: '#FFA9FC',
                  fontSize: '16px',
                  fontWeight: 510,
                  gap: '8px',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',

                  '&:hover': {
                    background: '#544152'
                  }
                }
              }}
            >
              <MuiLink component={Link} to="/settings/integrations" target="_blank" rel="noopener noreferrer">
                <Icon />
                Open Integrations Page...
              </MuiLink>
            </Box>
          )}
          <Box
            sx={{
              a: {
                padding: '8px 16px',
                border: '1px solid #998d96',
                background: 'transparent',
                borderRadius: '100px',
                color: '#FFA9FC',
                fontSize: '16px',
                fontWeight: 510,
                gap: '8px',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',

                '&:hover': {
                  background: '#544152'
                }
              }
            }}
          >
            <MuiLink component={Link} to="/library" target="_blank" rel="noopener noreferrer">
              <Icon />
              Open CPI Library Page...
            </MuiLink>
          </Box>
        </Box>
      </Box>
    </NoResultsWrapper>
  )
}

export default NoIntegrations
