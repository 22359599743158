import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 32px;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    .content-title {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .content-description {
      color: #d9bfd4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`
