import React, { FC, useState } from 'react'
import { ListItemText, MenuItem, OutlinedInput, Typography, SelectProps } from '@mui/material'

/* Components */
import { Container, FormControl, Select, MenuProps } from './styles/controlledTrendsSelectField.styles'
import { SelectDropdownIcon } from 'ui/components/svg'

interface Props extends SelectProps {
  options: string[]
  minWidth?: string
  noborder?: string
  labeltext?: string
  disabled: boolean
  hideSelected?: boolean
  value: any
}

const ControlledCustomTrendsSelect: FC<Props> = ({
  options,
  labeltext,
  placeholder,
  noborder,
  minWidth,
  disabled,
  hideSelected,
  value,
  name,
  ...rest
}) => {
  // const [field] = useField(name as string)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  return (
    <Container className="controlled-trends-select-container">
      {labeltext && <Typography className="form-control-label">{labeltext}</Typography>}

      <FormControl
        sx={{ minWidth }}
        isselected={
          hideSelected
            ? 'false'
            : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
            ? 'true'
            : 'false'
        }
        is_opened={anchorEl ? 'true' : 'false'}
        is_disabled={disabled ? 'true' : 'false'}
      >
        <Select
          name={name}
          noborder={noborder}
          isselected={
            hideSelected
              ? 'false'
              : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
              ? 'true'
              : 'false'
          }
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...MenuProps,
            PaperProps: {
              ...MenuProps.PaperProps,
              sx: {
                ...MenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              },
              style: { maxHeight: 'unset' }
            },
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          sx={{
            minWidth,
            '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
          }}
          IconComponent={(props) => <SelectDropdownIcon {...props} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned[0]} +${pruned.length - 1}`
            }

            if (labeltext) {
              return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
            }
            return typeof noborder !== 'undefined' ? `Sort by: ${selected}` : selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          input={<OutlinedInput onMouseDown={handleClick} />}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
              disableRipple
            >
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  )
}

export default ControlledCustomTrendsSelect
