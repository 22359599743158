import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  gap: 18.5px;
  width: 100%;
  position: relative;
`

export const InsightGroupWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18.5px;
  width: 100%;
  color: #d9bfd4;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .group-header-title {
    color: #d9bfd4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const CardsGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`

export const SSMCardSkeleton = styled(Box)`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: #483646;

    &:after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`

export const CardLoadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #372a36;
  border-radius: 8px;
  padding: 16px;
  height: 108px;
  width: 100%;
  gap: 8px;

  .card-skeleton-inner {
    border-radius: 4px;
  }

  .up {
    gap: 16px;
    display: flex;
    align-items: center;
    width: 100%;

    .up-left {
      display: flex;
      align-items: center;

      .card-skeleton-inner.card-up-left {
        width: 60px !important;
        height: 60px !important;
      }
    }

    .up-right {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .card-up-right-1 {
        width: 120px !important;
        height: 20px !important;
      }

      .card-up-right-2 {
        width: 100% !important;
        height: 30px !important;
      }
    }
  }

  .down {
    display: flex;
    align-items: center;
    gap: 16px;

    .card-skeleton-inner.card-bottom {
      width: 100px !important;
      height: 20px !important;
    }
  }

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: #483646;

    &:after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`
