import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, FormControl, InputAdornment, MenuItem as MuiMenuItem, Select } from '@mui/material'
import { getCpiCategoryColors } from '../../../../lib/utils'

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

export const NavigationSelect = styled(Select)<{ isselected?: string; noborder?: string; isCustom?: boolean }>`
  min-width: 113px;
  height: 42px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid #998d96;
  gap: 8px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: #6C586A;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    path {
      fill: ${({ isCustom }) => (isCustom ? '#ffa9fc' : '#d0c3cc')};
    }
  }
`

export const NavigationFormControl = styled(FormControl)<{ isselected?: string; isCustom?: boolean }>`
  width: 200px;
  margin-top: 0px;
  margin-right: 28px;
  border-radius: ${({ isCustom }) => (isCustom ? '100px' : '40px')};
  border: 1px solid #998d96;
  background: transparent;
  outline: none;
  min-width: 113px;
  height: ${({ isCustom }) => (isCustom ? '40px' : '42px')};

  &:hover {
    border: 1px solid #ffa9fc;
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ isCustom }) =>
    isCustom &&
    `
    justify-content: center;
  `};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    border: 1px solid #FFA9FC;
    background: rgba(208, 188, 255, 0.16);
  `};
`

export const MenuItem = styled(MuiMenuItem)`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;

  img {
    margin-right: 8px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.16) !important;
  }

  .MuiCheckbox-root {
    padding-right: 19px;
    padding-left: 0;

    svg {
      path {
        fill: #d0c3cc;
      }
    }
  }

  .MuiListItemText-root .MuiTypography-root {
    font-family: Quicksand, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #d0c3cc;
  }

  display: flex;
  align-items: center;
  width: 100%;

  .image-wrapper {
    display: flex;

    > img {
      width: 18px;
      height: 18px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      margin: -4.5px;
      border-radius: 50%;

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }

  .text-wrapper {
    margin-left: 13px;
  }
`

export const CategoryMenuProps = {
  sx: {
    zIndex: 1500
  },
  PaperProps: {
    sx: {
      background: '#573f56',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      width: '480px !important',
      maxHeight: '351px !important',
      marginTop: '3px',
      zIndex: '10000000',
      overflow: 'hidden',

      '.MuiList-root': {
        paddingTop: '0'
      },

      '.MuiMenuItem-root': {
        height: '40px',
        paddingLeft: '16px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0,

          svg: {
            path: {
              fill: '#d0c3cc'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#fff',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#573f56'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'center' as const
  },
  variant: 'menu' as const
  // getContentAnchorEl: null
}

export const DataSourceInputAdornment = styled(InputAdornment)`
  .adornment-wrapper {
    display: flex;
    gap: 8px;

    img {
      width: 18px;
      height: 18px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      border-radius: 50%;

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }
`

export const LibraryCardWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;
  width: 880px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #342f32;
  }

  .cpi-new {
    border-radius: 18px;
    background: #3754fc;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 8px;
    margin-left: 8px;

    > .MuiTypography-root {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .favorite-icon {
    position: absolute;
    top: 5px;
    right: 16px;

    .MuiButtonBase-root {
      &:hover,
      &:focus,
      &:active {
        background: rgba(202, 196, 208, 0.08);
      }
    }
  }

  @media (min-width: 1300px) {
    width: 920px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin: 20px 0 8px;

    .title {
      color: #fff;
      text-align: start;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .cpi-name {
    display: inline-block;
    padding: 2px 8px;
    align-items: center;
    gap: 3px;
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    > .MuiTypography-root {
      color: #3f293e;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const LibraryFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  .left-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;

    .data-sources {
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #4d444b;
      padding: 7px 8px;

      > .MuiTypography-root {
        color: #e9e0e4;
        font-family: Quicksand, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      .data-sources-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .framework-panel {
      border-radius: 8px;
      border: 1px solid #4d444b;
      padding: 7px 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .cpi-active-button {
      width: fit-content;
      height: 32px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 6px 16px 6px 8px;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
    }

    .cpi-active {
      background: #3754fc;
    }

    .cpi-inactive {
      background: #4d444b;
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .MuiButtonBase-root {
      margin-left: 16px;
    }
  }
`

export const LibraryFooterCategory = styled(Box)<{ category: string }>`
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 6px 16px;

  > .MuiTypography-root {
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  ${({ category }) => {
    const colors = getCpiCategoryColors(category)
    return `
  background: ${colors.background};
  > .MuiTypography-root {
    color: ${colors.color};
  `
  }};
`

export const LibraryFooterLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${({ logo }) => `url('${logo}') no-repeat center`};
  background-size: cover;
  margin-left: 4px;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    color: #ffa9fc;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    &.breadcrumb-links {
      color: #fff;
    }

    &.category-breadcrumb {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #b15c0f;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #fff;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #fff;
    margin-left: 16px;
    margin-right: 16px;
  }
`

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 1440px) {
    margin-top: 16px;
  }

  .chips-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-bottom: 8px;
    white-space: nowrap;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #998d96;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: capitalize;

    &.selected {
      color: #590060;
      border: none;
      background: #ffa9fc;
    }

    &:hover {
      background: #ffa9fc !important;
      color: #590060 !important;
      opacity: 0.9 !important;
    }
  }
`

export const IntegrationItem = styled(Box)<{ active?: boolean }>`
  position: relative;

  img {
    border: 1px solid #998d96;
    border-radius: 50%;
    ${({ active }) =>
      active &&
      `
       border: 1px solid #aed581;
      `};
  }

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #998d96;
    position: absolute;
    right: -3px;
    top: 10px;
    ${({ active }) =>
      active &&
      `
        background: #aed581;
      `};
  }

  .circle-new {
    z-index: 10;
    top: 6px;
    right: -7px;
  }
`

export const IntegrationTextItem = styled(Box)<{ active: boolean }>`
  .box-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 8px;

    .text {
      font-family: 'Quicksand', sans-serif;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
      margin-left: 0 !important;
      color: #998d96 !important;
      ${({ active }) =>
        active &&
        `
        color: #aed581!important;
      `};
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: #998d96;
      ${({ active }) =>
        active &&
        `
        background: #aed581;
      `};
    }
  }
`
