import { Dispatch, forwardRef, SetStateAction, useState } from 'react'
import {
  Box,
  ClickAwayListener,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { FilterAltOutlined } from '@mui/icons-material'

/* Utils */
import { ConfiguredDataSource, LibraryFilters } from '../../../../../models'

/* Components */
import { ThemeButton } from '../../../../components/buttons'
import { Popper, PopperInner } from './filterCategoryCpis.styles'
import ResetIcon from '../../../../components/svg/ResetIcon'
import SearchDataSources from './search-data-sources'
import { useAutocomplete } from '@mui/base/useAutocomplete'

type Ref = HTMLButtonElement | null

const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="9" fill="#FFA9FC" stroke="#FFA9FC" strokeWidth="2" />
    <circle cx="10" cy="10" r="5" fill="#590060" />
  </svg>
)

const UncheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="9" stroke="#998D96" strokeWidth="2" />
  </svg>
)

interface Props {
  filtersOpen: boolean
  handleCloseFilters: () => void
  handleClickFilter: () => void
  cpiActivationStatus: 'all' | 'active' | 'inactive'
  setCpiActivationStatus: Dispatch<SetStateAction<'all' | 'active' | 'inactive'>>
  cpiAssignment: 'all' | 'assigned' | 'unassigned'
  setCpiAssignment: Dispatch<SetStateAction<'all' | 'assigned' | 'unassigned'>>
  configuredIntegrations: ConfiguredDataSource[]
  setFilters: Dispatch<SetStateAction<LibraryFilters>>
  defaultFilters: LibraryFilters
}

const FilterCategoryCpis = forwardRef<Ref, Props>(
  (
    {
      filtersOpen,
      handleCloseFilters,
      handleClickFilter,
      cpiActivationStatus,
      setCpiActivationStatus,
      cpiAssignment,
      setCpiAssignment,
      configuredIntegrations,
      setFilters,
      defaultFilters
    },
    ref
  ) => {
    const [tempFilters, setTempFilters] = useState<LibraryFilters>(defaultFilters)
    const [value, setValue] = useState<ConfiguredDataSource[]>([])

    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      getClearProps,
      focused,
      setAnchorEl
    } = useAutocomplete({
      id: 'data-source-autocomplete-hook',
      value,
      onChange: (event, newValue) => {
        setValue(newValue)
        setTempFilters((pre) => ({
          ...pre,
          source: newValue.map((n) => n.name)
        }))
      },
      multiple: true,
      options: configuredIntegrations,
      getOptionLabel: (option) => option.name
    })

    const selectedValueIds = value.map((n) => n.id)
    const groupedFilteredOptions = ((groupedOptions as any) || []).filter((n: any) => !selectedValueIds.includes(n.id))

    const handleStatusChange = (status: 'all' | 'active' | 'inactive') => {
      setCpiActivationStatus(status)
      setTempFilters((pre) => ({
        ...pre,
        status: [status]
      }))
    }

    const handleFrameworkCategoryChange = (category: 'all' | 'assigned' | 'unassigned') => {
      setCpiAssignment(category)
      setTempFilters((pre) => ({
        ...pre,
        frameworkCategory: category === 'all' ? '' : category
      }))
    }

    const handleApply = () => {
      setFilters(tempFilters)
      handleCloseFilters()
    }

    const handleReset = () => {
      setCpiActivationStatus('all')
      setCpiAssignment('all')
      setValue([])
      setTempFilters(defaultFilters)
    }

    return (
      <>
        <ThemeButton
          themevariant="secondary"
          sx={{
            minWidth: '112px',
            background: filtersOpen ? '#590060 !important' : undefined,
            color: filtersOpen ? '#FFF !important' : undefined,
            borderColor: filtersOpen ? '#998D96 !important' : undefined,
            svg: {
              path: {
                fill: filtersOpen ? '#fff !important' : undefined
              }
            }
          }}
          ref={ref}
          id="filter-composition-button"
          aria-controls={filtersOpen ? 'filter-composition-menu' : undefined}
          aria-expanded={filtersOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickFilter}
        >
          <>
            <FilterAltOutlined />
            Filters
          </>
        </ThemeButton>

        <Popper
          open={filtersOpen}
          anchorEl={(ref as any)?.current || null}
          // placement="bottom-start"
          placement="auto"
          // transition
          // disablePortal
          modifiers={[
            {
              // name: 'preventOverflow',
              // enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                // rootBoundary: 'document',
                padding: 24
              }
            }
          ]}
        >
          <ClickAwayListener
            onClickAway={() => {
              handleReset()
              handleCloseFilters()
            }}
          >
            <PopperInner className="popper-inner">
              <Box className="popper-inner-wrapper">
                <Box className="popper-search-container">
                  <Typography className="header-text">Choose CPI data source</Typography>
                  <SearchDataSources
                    options={configuredIntegrations}
                    getRootProps={getRootProps}
                    getTagProps={getTagProps}
                    getListboxProps={getListboxProps}
                    getOptionProps={getOptionProps}
                    focused={focused}
                    setAnchorEl={setAnchorEl}
                    value={value}
                    groupedOptions={groupedOptions as ConfiguredDataSource[]}
                    groupedFilteredOptions={groupedFilteredOptions}
                    getInputProps={getInputProps}
                    selectedValueIds={selectedValueIds}
                    getClearProps={getClearProps}
                  />
                </Box>

                {/* <Box className="popper-inner-scrollable"> */}
                <FormControl className="checkbox-form-control" component="fieldset" variant="standard">
                  <FormLabel component="legend">Select CPI activation status:</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiActivationStatus === 'all'}
                          onChange={() => handleStatusChange('all')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiActivationStatus === 'active'}
                          onChange={() => handleStatusChange('active')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="Active"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiActivationStatus === 'inactive'}
                          onChange={() => handleStatusChange('inactive')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="Inactive"
                    />
                  </FormGroup>
                </FormControl>

                <FormControl className="checkbox-form-control" component="fieldset" variant="standard">
                  <FormLabel component="legend">Choose if CPI is assigned to a framework category:</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiAssignment === 'all'}
                          onChange={() => handleFrameworkCategoryChange('all')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiAssignment === 'assigned'}
                          onChange={() => handleFrameworkCategoryChange('assigned')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="Assigned"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cpiAssignment === 'unassigned'}
                          onChange={() => handleFrameworkCategoryChange('unassigned')}
                          checkedIcon={<CheckedIcon />}
                          icon={<UncheckedIcon />}
                        />
                      }
                      label="Unassigned"
                    />
                  </FormGroup>
                </FormControl>
                {/* </Box> */}

                <Box className="popper-footer">
                  <ThemeButton
                    themevariant="tertiary"
                    // disabled={cpiActivationStatus === 'all' && cpiAssignment === 'all' && !value.length}
                    onClick={handleReset}
                  >
                    <>
                      <ResetIcon />
                      Reset
                    </>
                  </ThemeButton>
                  <Box className="footer-actions-wrapper">
                    <ThemeButton
                      themevariant="tertiary"
                      onClick={() => {
                        handleReset()
                        handleCloseFilters()
                      }}
                    >
                      Cancel
                    </ThemeButton>
                    <ThemeButton
                      themevariant="primary"
                      // disabled={cpiActivationStatus === 'all' && cpiAssignment === 'all' && !value.length}
                      sx={{ minWidth: '95px' }}
                      onClick={handleApply}
                    >
                      Apply
                    </ThemeButton>
                  </Box>
                </Box>
              </Box>
            </PopperInner>
          </ClickAwayListener>
        </Popper>
      </>
    )
  }
)

export default FilterCategoryCpis
