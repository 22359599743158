import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  height: 310px;
  //min-width: 480px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: ${theme.colors.surfaceContainerBackground};
  width: 100%;
  flex-basis: 33.33%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .header-start {
      display: flex;
      align-items: center;
      gap: 8px;

      .header-title {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }

    .header-date {
      color: ${theme.colors.textSecondaryLow};
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media (max-width: 1463px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
    }
  }

  @media (max-width: 1463px) {
    overflow-y: auto;
  }
`
