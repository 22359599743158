import { Dialog as MuiDialog } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Dialog = styled(MuiDialog)`
  width: 100%;
  height: 100%;

  .MuiDialog-container {
    .MuiPaper-root {
      width: 66.67%;
      max-width: unset;
      height: 50%;
      min-height: 720px;
      background: #292128;
      border-radius: 16px;

      .header {
        display: flex;
        align-items: center;
        height: 72px;
        padding: 16px 24px;
        gap: 8px;
        border-radius: 16px 16px 0 0;
        background: #372a36;

        .header-title {
          color: #ffa9fc;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .MuiDialogContent-root {
        padding: 24px;
        gap: 24px;
        display: flex;
        align-items: flex-start;
        width: 100%;

        .image-wrapper {
          width: 65%;

          .image {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
          }
        }

        .instructions-right-side {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 16px;
          overflow-y: auto;

          .step-text {
            color: #ffa9fc;
            font-size: 16px;
            font-style: normal;
            font-weight: 510;
            line-height: normal;
          }

          a {
            color: rgb(255, 169, 252) !important;

            &:visited,
            &:focus,
            &:focus-visible,
            &:active,
            &:default {
              color: rgb(255, 169, 252) !important;
            }
          }

          p {
            margin: 0;
          }
        }

        .section-list-item {
          padding: 0 0 16px 0;
          width: 100%;
          line-break: auto;
          word-break: break-word;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &:first-of-type {
            margin-top: 16px;
          }

          .circle-bullet-list {
            list-style-type: disc;
            margin-top: 16px;

            .circle-bullet {
            }
          }

          .list-bullet {
            color: #ffa9fc;
          }

          a {
            color: rgb(255, 169, 252) !important;

            &:visited,
            &:focus,
            &:focus-visible,
            &:active,
            &:default {
              color: rgb(255, 169, 252) !important;
            }
          }
        }

        ol {
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #ffffff;
          margin: 0;
          padding: 0 0 0 29px;
          line-break: auto;

          a {
            color: rgb(255, 169, 252);

            &:visited {
              color: rgb(255, 169, 252);
            }
          }

          li:not(:last-of-type) {
            margin-bottom: 16px;
          }

          li .MuiLink-root {
            color: #ffa9fc;
            text-decoration: underline;
            margin: 0;
            padding: 0;
            font-weight: 600;
          }

          li {
            ul {
              li {
                margin-bottom: 0 !important;
              }
            }
          }

          .tooltip-icon-padded {
            margin-left: 8px;
          }

          p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 24px;
        border-radius: 0 0 16px 16px;
        background: #372a36;
        gap: 8px;
      }
    }
  }
`
