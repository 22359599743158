import { FC, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/* Components */
import { Wrapper } from './insightsWidget.styles'
import DetailsCard from './components/DetailsCard'
import UnreadInsights from './components/UnreadInsights'
import { InsightSubType } from '../../../../../models'

const infoIcon = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5555 22C13.7828 22 14.7777 21.0051 14.7777 19.7778C14.7777 18.5505 13.7828 17.5556 12.5555 17.5556C11.3282 17.5556 10.3333 18.5505 10.3333 19.7778C10.3333 21.0051 11.3282 22 12.5555 22Z"
      fill="#FFA9FC"
    />
    <path d="M10.3333 2H14.7777V15.3333H10.3333V2Z" fill="#FFA9FC" />
  </svg>
)

const optimizationIcon = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 17.5556V19.7778H9.16667V17.5556H2.5ZM2.5 4.22222V6.44444H13.6111V4.22222H2.5ZM13.6111 22V19.7778H22.5V17.5556H13.6111V15.3333H11.3889V22H13.6111ZM6.94444 8.66667V10.8889H2.5V13.1111H6.94444V15.3333H9.16667V8.66667H6.94444ZM22.5 13.1111V10.8889H11.3889V13.1111H22.5ZM15.8333 8.66667H18.0556V6.44444H22.5V4.22222H18.0556V2H15.8333V8.66667Z"
      fill="#FFA9FC"
    />
  </svg>
)

const performanceIcon = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1665 9H14.8565L15.8065 4.43L15.8365 4.11C15.8365 3.7 15.6665 3.32 15.3965 3.05L14.3365 2L7.7565 8.59C7.3865 8.95 7.1665 9.45 7.1665 10V20C7.1665 21.1 8.0665 22 9.1665 22H18.1665C18.9965 22 19.7065 21.5 20.0065 20.78L23.0265 13.73C23.1165 13.5 23.1665 13.26 23.1665 13V11C23.1665 9.9 22.2665 9 21.1665 9ZM21.1665 13L18.1665 20H9.1665V10L13.5065 5.66L12.3965 11H21.1665V13ZM1.1665 10H5.1665V22H1.1665V10Z"
      fill="#FFA9FC"
    />
  </svg>
)

interface Props {
  unreadInsightsCount: number
  insightsCountLoading: boolean
  insightsData: any
  isLoading?: boolean
}

const InsightsWidget: FC<Props> = ({ insightsData, unreadInsightsCount, insightsCountLoading, isLoading }) => {
  const navigate = useNavigate()

  const items = useMemo(() => {
    return [
      {
        icon: infoIcon,
        value: insightsData?.performanceDegradation || 0,
        text: 'Performance Degradation'
      },
      {
        icon: optimizationIcon,
        value: insightsData?.suggestedOptimization || 0,
        text: 'Suggested Optimization'
      },
      {
        icon: performanceIcon,
        value: insightsData?.performanceImprovement || 0,
        text: 'Performance Improvement'
      }
    ]
  }, [insightsData])

  const handleCardClick = (text: string) => {
    const types =
      text === 'Performance Degradation'
        ? [InsightSubType.Reduction, InsightSubType.CrossedIntoRed]
        : text === 'Suggested Optimization'
        ? [InsightSubType.CpiSlaOptimization]
        : [InsightSubType.Improvement, InsightSubType.CrossedIntoGreen]
    navigate('/onyxai-insights', { state: { types } })
  }

  return (
    <Wrapper>
      <Box className="header">
        <Box className="header-start">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M7.50008 17.5C7.50008 17.9583 7.87508 18.3333 8.33341 18.3333H11.6667C12.1251 18.3333 12.5001 17.9583 12.5001 17.5V16.6666H7.50008V17.5ZM10.0001 1.66663C6.78341 1.66663 4.16675 4.28329 4.16675 7.49996C4.16675 9.48329 5.15841 11.225 6.66675 12.2833V14.1666C6.66675 14.625 7.04175 15 7.50008 15H12.5001C12.9584 15 13.3334 14.625 13.3334 14.1666V12.2833C14.8417 11.225 15.8334 9.48329 15.8334 7.49996C15.8334 4.28329 13.2167 1.66663 10.0001 1.66663ZM12.3751 10.9166L11.6667 11.4166V13.3333H8.33341V11.4166L7.62508 10.9166C6.50008 10.1333 5.83341 8.85829 5.83341 7.49996C5.83341 5.19996 7.70008 3.33329 10.0001 3.33329C12.3001 3.33329 14.1667 5.19996 14.1667 7.49996C14.1667 8.85829 13.5001 10.1333 12.3751 10.9166Z"
              fill="white"
            />
          </svg>
          <Typography className="header-title">Insights</Typography>
        </Box>
        <Typography className="header-date">Last 30 days</Typography>
      </Box>
      {!insightsCountLoading && unreadInsightsCount > 0 && <UnreadInsights value={unreadInsightsCount} />}
      <Box className="content">
        {items.map((item) => (
          <DetailsCard key={item.text} {...item} handleClick={handleCardClick} isLoading={isLoading} />
        ))}
      </Box>
    </Wrapper>
  )
}

export default InsightsWidget
