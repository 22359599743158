import axios from '../../../../../lib/axios'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Box, Checkbox, CircularProgress, IconButton, Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { ThemeButton } from '../../../buttons'
import { DeleteIcon, InstanceCheckedIcon, InstanceUncheckedIcon, IntegrationInfoIcon } from '../../../svg'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'
import DisabledCheckboxSvg from '../../../svg/DisabledCheckboxSvg'
import {
  CPILoadingWrapper,
  DeleteInstanceActionsWrapper,
  DeleteInstanceCPICard,
  DeleteInstanceCPICardWrapper,
  DeleteInstanceSubtitle,
  DeleteInstanceTitle,
  DeleteInstanceWrapper,
  InfoTooltipWrapper,
  LoadingDeleteInstanceWrapper
} from './deleteIntegrationInstance.styles'
import { IntegrationHoverTooltip } from '../../../../pages/library/components/LibraryDetailsTooltip'
import IntegrationWarningIcon from '../../../svg/IntegrationWarningIcon'

interface activeCPI {
  name: string
  title: string
  displayName: string
  checked: boolean
  isOnly?: boolean
}

const DeleteIntegrationInstance: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const [loading, setLoading] = useState(false)
  const [cpisLoading, setCpisLoading] = useState(true)
  const [step, setStep] = useState(1)
  const [affectedCPIs, setAffectedCPIs] = useState<activeCPI[]>([])
  const [preservedCPIs, setNoPreservedCPIs] = useState<activeCPI[]>([])
  const instanceId = modalProps.props?.integrationInstance?.id

  useEffect(() => {
    findAffectedCpis().catch((e) => e)
  }, [])

  const findAffectedCpis = async () => {
    try {
      const res = await axios.get(`/api/v3/integrations/config/${instanceId}/cpis`)

      if (res.status === 200) {
        const cpis = res.data.cpis
        const haveInstance: activeCPI[] = []
        cpis.forEach(
          (n: { integrations: any; name: string; title: string; display_name: string; configuration: any }) => {
            haveInstance.push({
              name: n.name,
              displayName: n.display_name,
              title: n.title,
              checked: false,
              isOnly: n.configuration.integrations?.length <= 1
            })
          }
        )

        setAffectedCPIs(haveInstance.sort((a, b) => (a.displayName < b.displayName ? -1 : 0)))
      }
    } catch (e) {
      console.error(e)
    } finally {
      setCpisLoading(false)
    }
  }

  useEffect(() => {
    findAffectedCpis().catch((e) => e)
  }, [])

  const handleClose = () => {
    dispatch(closeModal())
  }

  const getDeletingCPis = () => {
    const newArr = [...affectedCPIs]
    const listOfDeletingCpis = [] as activeCPI[]
    newArr.forEach((item) => {
      listOfDeletingCpis.push(item)
    })
    setNoPreservedCPIs(listOfDeletingCpis)
  }

  const changeHandler = (e: ChangeEvent<HTMLInputElement>, integration: activeCPI) => {
    const newArr = [...affectedCPIs]
    const findIndex = newArr.findIndex((item) => item.name === integration.name)
    newArr[findIndex].checked = e.target.checked
    setAffectedCPIs(newArr.sort((a, b) => (a.displayName < b.displayName ? -1 : 0)))
    getDeletingCPis()
  }

  const updateStep = () => {
    setStep(2)
    getDeletingCPis()
  }

  const handleDelete = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const listOfDeletingCpis = [] as string[]
    affectedCPIs.forEach((item) => {
      if (item.checked) {
        const name = item.name.toLowerCase().replace('-', '')
        listOfDeletingCpis.push(name)
      }
    })

    const body = {} as any
    if (listOfDeletingCpis.length) {
      body.cpis = listOfDeletingCpis
    }
    setLoading(true)

    try {
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: 'Instance Deleted',
            description: `Instance "${modalProps.props?.integrationInstance?.name}" has been succcessfully removed from your ${modalProps.props?.integrationInstance?.integration_name} integration.`
          }
        })
      )
      handleClose()

      if (modalProps.props?.closeCallback) {
        modalProps.props.closeCallback()
      }

      if (Object.keys(body).length) {
        await axios.delete(`/api/v3/integrations/config/${instanceId}`, { data: body } as any)
      } else await axios.delete(`/api/v3/integrations/config/${instanceId}`)

      if (modalProps.props?.callback) {
        await modalProps.props.callback()
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed deleting instance',
            description: (e as any).response?.data?.message
              ? (e as any).response?.data?.message
              : 'There was a problem while deleting this instance.'
          }
        })
      )
    } finally {
      setLoading(false)
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const InfoTooltipText = (
    <InfoTooltipWrapper>
      <Box className="header">
        <IntegrationInfoIcon size={20} />
        <Typography className="title">Data can be preserved</Typography>
      </Box>

      <Typography className="description">
        Deleting the instance is going to stop the data collection. However, preserving the data will allow you to still
        view the previous collected data.
      </Typography>
    </InfoTooltipWrapper>
  )

  const WarningTooltipText = (name: string, title: string) => {
    return (
      <InfoTooltipWrapper>
        <Box className="header">
          <IntegrationWarningIcon />
          <Typography className="title">CPI Deactivation</Typography>
        </Box>

        <Typography className="description">
          “{modalProps.props?.integrationInstance?.integration_name}” is the only source that {name} ({title}) is
          connected with. Deleting this instance will deactivate {name} and all data will be permanently deleted.
        </Typography>
      </InfoTooltipWrapper>
    )
  }

  if (cpisLoading) {
    return (
      <LoadingDeleteInstanceWrapper>
        <CPILoadingWrapper>
          <CircularProgress size="40px" color="secondary" />
        </CPILoadingWrapper>
      </LoadingDeleteInstanceWrapper>
    )
  }
  return (
    <DeleteInstanceWrapper>
      {step === 1 ? (
        <>
          {!affectedCPIs.length ? (
            <>
              <DeleteInstanceTitle>Delete Instance “{modalProps.props?.integrationInstance?.name}”</DeleteInstanceTitle>
              <DeleteInstanceSubtitle>No CPIs will be affected if you delete this Instance.</DeleteInstanceSubtitle>
              <DeleteInstanceActionsWrapper>
                <ThemeButton themevariant="tertiary" onClick={handleClose}>
                  Go Back
                </ThemeButton>
                <ThemeButton themevariant="primary" type="button" onClick={(e) => handleDelete(e)}>
                  <>
                    <DeleteIcon />
                    Delete
                  </>
                </ThemeButton>
              </DeleteInstanceActionsWrapper>
            </>
          ) : (
            <Box>
              <DeleteInstanceTitle>Delete Instance “{modalProps.props?.integrationInstance?.name}”</DeleteInstanceTitle>

              <DeleteInstanceSubtitle>Deleting this Instance affects following CPIs :</DeleteInstanceSubtitle>

              <DeleteInstanceCPICardWrapper noBg={false}>
                {affectedCPIs.map((item) => {
                  return (
                    <DeleteInstanceCPICard key={item.name} justifyCenter={false} step={1}>
                      <Box className="cpi">{item.displayName}</Box>

                      <Typography className="name">{item.title}</Typography>

                      {item.isOnly ? (
                        <IntegrationHoverTooltip title={WarningTooltipText(item.displayName, item.title)}>
                          <IconButton>
                            <IntegrationWarningIcon />
                          </IconButton>
                        </IntegrationHoverTooltip>
                      ) : (
                        <IntegrationHoverTooltip title={InfoTooltipText}>
                          <IconButton>
                            <IntegrationInfoIcon size={20} />
                          </IconButton>
                        </IntegrationHoverTooltip>
                      )}

                      <Box display="flex" alignItems="center">
                        {item.isOnly ? (
                          <Box sx={{ marginLeft: '8px', marginRight: '9px', display: 'flex' }}>
                            <DisabledCheckboxSvg />
                          </Box>
                        ) : (
                          <Checkbox
                            inputProps={{ 'aria-label': 'Checkbox' }}
                            checked={item.checked}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => changeHandler(e, item)}
                            disabled={item.isOnly}
                            icon={<InstanceUncheckedIcon />}
                            checkedIcon={<InstanceCheckedIcon />}
                          />
                        )}
                        <Typography className={`${item.isOnly ? 'disabled-label' : ''} label`}>
                          Preserve Data
                        </Typography>
                      </Box>
                    </DeleteInstanceCPICard>
                  )
                })}

                {modalProps.props?.isAnalyzer ? (
                  <DeleteInstanceCPICard key="analyzer" justifyCenter={false} step={1}>
                    <Box className="cpi-empty"></Box>

                    <Typography className="name">Asset Coverage Analyzer</Typography>

                    <IntegrationHoverTooltip
                      title={
                        <InfoTooltipWrapper>
                          <Typography className="description small">
                            You will no longer be able to use {modalProps.props?.integrationInstance?.name} as a data
                            source for the Asset Coverage Analyzer.
                          </Typography>
                        </InfoTooltipWrapper>
                      }
                    >
                      <IconButton>
                        <IntegrationWarningIcon />
                      </IconButton>
                    </IntegrationHoverTooltip>

                    <Box display="flex" alignItems="center">
                      <Box sx={{ marginLeft: '8px', marginRight: '9px', display: 'flex' }}>
                        <DisabledCheckboxSvg />
                      </Box>
                      <Typography className={`disabled-label label`}>Preserve Data</Typography>
                    </Box>
                  </DeleteInstanceCPICard>
                ) : (
                  ''
                )}
              </DeleteInstanceCPICardWrapper>

              <DeleteInstanceActionsWrapper>
                <ThemeButton themevariant="tertiary" onClick={handleClose}>
                  Go Back
                </ThemeButton>
                <ThemeButton themevariant="primary" type="button" onClick={() => updateStep()}>
                  <>
                    <DeleteIcon />
                    Delete
                  </>
                </ThemeButton>
              </DeleteInstanceActionsWrapper>
            </Box>
          )}
        </>
      ) : (
        <>
          <DeleteInstanceTitle>Are You Sure?</DeleteInstanceTitle>
          <DeleteInstanceSubtitle>
            Please review how the deletion is affecting the following CPIs and confirm :
          </DeleteInstanceSubtitle>

          <DeleteInstanceCPICardWrapper noBg={false}>
            {preservedCPIs.map((item) => {
              return (
                <DeleteInstanceCPICard key={item.name} justifyCenter={true} step={2}>
                  <Box className="cpi">{item.displayName}</Box>

                  <Typography className="name">
                    {item.checked
                      ? `Instance data will be preserved for ${item.displayName}`
                      : item.isOnly
                      ? `Instance data is going to be permanently deleted & ${item.displayName} deactivated`
                      : `Instance data is going to be permanently deleted for ${item.displayName}`}
                  </Typography>
                </DeleteInstanceCPICard>
              )
            })}
          </DeleteInstanceCPICardWrapper>

          <DeleteInstanceActionsWrapper>
            <ThemeButton themevariant="tertiary" onClick={() => setStep(1)}>
              Go Back
            </ThemeButton>

            <ThemeButton
              themevariant="primary"
              type="button"
              onClick={handleDelete}
              endIcon={loading && <CircularProgress size="20px" />}
            >
              <>
                <DeleteIcon />
                Confirm and Delete
              </>
            </ThemeButton>
          </DeleteInstanceActionsWrapper>
        </>
      )}
    </DeleteInstanceWrapper>
  )
}

export default DeleteIntegrationInstance
