import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../components/svg'
import {
  CategoryMenuProps,
  LibraryFormControl,
  LibrarySelect,
  Wrapper,
  ResetDivider
} from './onyxiaSelectSearch.styles'
import useSearchLabel from '../../../../hooks/useSearchLabel'
import SortIcon from '../../../components/svg/SortIcon'
import OnyxiaSelectSearchbar from './OnyxiaSelectSearchbar'
import ResetSearchOnyxiaSelect from './ResetSearchOnyxiaSelect'
import { getIntegrationLogo } from '../../coverage-analyzer/utils'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  maxHeight?: string
  noborder?: string
  iscustom?: string
  hideSearch?: boolean
  label?: string
  isSort?: boolean
  inputWidth?: string
  width?: string
  showIcons?: boolean
  maxWidth?: string
}

const OnyxiaSelectSearch: FC<Props> = ({
  isSort,
  label,
  options,
  inputWidth,
  handleChange,
  placeholder,
  maxHeight,
  handleReset,
  noborder,
  minWidth,
  iscustom,
  value,
  showIcons,
  width,
  hideSearch = false,
  maxWidth,
  disabled,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const convertedOptions = useMemo(() => {
    return options.map((item) => {
      return {
        label: item,
        name: item
      }
    })
  }, [options])

  const { search, setSearch, labelOptions, resetData, highlightMatch } = useSearchLabel(convertedOptions, handleReset)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}
      {isSort && <SortIcon />}
      <LibraryFormControl
        sx={{ minWidth, maxWidth: maxWidth || 'unset' }}
        isSort={isSort}
        iscustom={iscustom}
        noborder={noborder}
        isselected={
          anchorEl || (placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')))
            ? 'true'
            : 'false'
        }
        isdisabled={disabled ? 'true' : 'false'}
      >
        <LibrarySelect
          noborder={noborder}
          disabled={disabled}
          isselected={
            placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
          }
          labelId="library-select-label"
          id="library-select"
          iscustom={iscustom}
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none',
                width: width || ''
              },
              style: {
                ...CategoryMenuProps.PaperProps.style,
                maxHeight: maxHeight ?? CategoryMenuProps.PaperProps.style.maxHeight
              }
            },
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned.length} selected`
            }

            return typeof noborder !== 'undefined' ? `${selected}` : selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth, width: inputWidth ?? 'auto' }}
          {...rest}
        >
          {!hideSearch && <OnyxiaSelectSearchbar search={search} setSearch={setSearch} placeholder="Search..." />}

          <Typography className="results-header-text">RESULTS ({labelOptions.length})</Typography>

          {labelOptions.map((option) => (
            <MenuItem
              key={option.name}
              value={option.name}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
            >
              <Checkbox
                checked={value.indexOf(option.name) > -1}
                icon={<InstanceUncheckedIcon />}
                checkedIcon={<InstanceCheckedIcon />}
              />

              {showIcons && (
                <img
                  src={getIntegrationLogo(option.name)}
                  alt=""
                  width="24px"
                  height="24px"
                  className="integration-icon"
                />
              )}
              <ListItemText primary={highlightMatch(option.label)} />
            </MenuItem>
          ))}

          <ResetDivider />
          <ResetSearchOnyxiaSelect
            resetData={resetData}
            disabled={!value.length || (value.length === 1 && value[0] === '')}
          />
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default OnyxiaSelectSearch
