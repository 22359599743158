import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  flex-direction: column;
  //width: 497px;
  //min-width: 497px;
  width: 100%;
  height: 310px;

  //@media (min-width: 1110px) {
  //  width: 571px;
  //  min-width: 571px;
  //}

  //@media (min-width: 1440px) {
  //  width: 633px;
  //  min-width: 633px;
  //}

  //@media (min-width: 1920px) {
  //  width: 775px;
  //  min-width: 775px;
  //  padding: 16px 32px;
  //}

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .inner {
    border-radius: 24px;
    padding: 12px 20px 24px;
    display: flex;
    gap: 46px;
    justify-content: center;

    @media (min-width: 1920px) {
      gap: 103px;
      padding: 0px 20px 24px;
    }

    .chart {
      display: flex;
      flex-direction: column;
      gap: 17px;
      justify-content: center;
      align-items: center;

      .chart-description {
        display: flex;
        align-items: center;
        gap: 24px;

        .chart-description-item {
          display: flex;
          align-items: center;

          .chart-description-icon {
            border-radius: 12px;
            background: #4d444b;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
            width: 8px;
            height: 8px;
          }

          .MuiTypography-root {
            color: #e9e0e4;
            font-family: Quicksand, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin-left: 4px;
          }
        }
      }
    }

    .categories {
    }
  }
`
