import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const InsightsIcon: FC<Props> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M21 48.9998C21 50.2832 22.05 51.3332 23.3333 51.3332H32.6666C33.95 51.3332 35 50.2832 35 48.9998V46.6665H21V48.9998ZM28 4.6665C18.9933 4.6665 11.6666 11.9932 11.6666 20.9998C11.6666 26.5532 14.4433 31.4298 18.6666 34.3932V39.6665C18.6666 40.9498 19.7166 41.9998 21 41.9998H35C36.2833 41.9998 37.3333 40.9498 37.3333 39.6665V34.3932C41.5566 31.4298 44.3333 26.5532 44.3333 20.9998C44.3333 11.9932 37.0066 4.6665 28 4.6665ZM34.65 30.5665L32.6666 31.9665V37.3332H23.3333V31.9665L21.35 30.5665C18.2 28.3732 16.3333 24.8032 16.3333 20.9998C16.3333 14.5598 21.56 9.33317 28 9.33317C34.44 9.33317 39.6666 14.5598 39.6666 20.9998C39.6666 24.8032 37.8 28.3732 34.65 30.5665Z"
      fill="rgb(208, 195, 204)"
    />
  </svg>
)

export default InsightsIcon
