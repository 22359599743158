import { Dispatch, FC, SetStateAction, useCallback, useLayoutEffect, useEffect, useRef, useState } from 'react'
import { Box, Fade, IconButton, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material'
import { ChartWrapperPredictions, Modal } from './predictionModal.styles'
import {
  CloseModalIcon,
  CPIChangeLabelIcon,
  CustomLabelIcon,
  InstanceAddedLabelIcon,
  InstanceRemovedLabelIcon,
  OnyxAIIcon,
  SLAChangeLabelIcon
} from '../../../../../../components/svg'
import TrendsChart from '../TrendsChart'
import {
  ChartData,
  ConfiguredCPI,
  CPIFrequency,
  CPILibraryItem,
  CPIManifest,
  CPISeverity,
  CPITimePeriod,
  DataStatus,
  IntegrationInstance,
  PredictionChartData,
  SystemEventName
} from '../../../../../../../models'
import {
  findNearestXIndex,
  findUniqueMonthsPredictions,
  getChartHeight,
  getChartWidth,
  getPathAndPositionsPredictions,
  getSingularSectionsName,
  getUtcDateAndTime
} from '../../../../utils'
import TrendsChartSelectField from '../components/TrendsChartSelectField'
import * as d3 from 'd3'
import {
  appendPredictionTrendsChartElements,
  chartHeightStart,
  getAreaFill,
  getAreaStroke,
  getDataStartsAt,
  getLineColor,
  getYLine,
  onMouseMove,
  xAxisHeight
} from '../chart-utils'
import { raiseElements } from '../chart-components'
import PredictionChartDescription from './PredictionChartDescription'

interface Props {
  open: boolean
  handleClose: () => void
  manifestData: CPIManifest
  isMediumHeight: boolean
  currentSeverityStatus: 'healthy' | 'problematic'
  setRedirectToMode: Dispatch<SetStateAction<string>>
  selectedTimePeriod: CPITimePeriod
  selectedSeverity: CPISeverity
  selectedFrequency: CPIFrequency
  setSelectedDataSourceIds: Dispatch<SetStateAction<string[]>>
  cpi: CPILibraryItem | null
  userConfiguredIntegrations: IntegrationInstance[]
  cpiConfiguration: ConfiguredCPI | null
  cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  dataSourceOptions: IntegrationInstance[]
  dataSourceValue: string
  setDataSourceValue: Dispatch<SetStateAction<string>>
  setLabelDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  setSelectedSeverity: Dispatch<SetStateAction<CPISeverity>>
  defaultValues: Record<string, unknown>
  predictionData: PredictionChartData | null
  refetchPredictionData: ({
    thisCpiName,
    severity,
    selectedSources
  }: {
    thisCpiName: string
    severity?: CPISeverity
    selectedSources?: string[]
  }) => Promise<ChartData | null>
  activeSources: IntegrationInstance[]
}

const PredictionModal: FC<Props> = ({
  open,
  currentSeverityStatus,
  handleClose,
  isMediumHeight,
  manifestData,
  setRedirectToMode,
  setDataSourceValue,
  selectedTimePeriod,
  selectedSeverity,
  selectedFrequency,
  setSelectedDataSourceIds,
  cpi,
  userConfiguredIntegrations,
  cpiConfiguration,
  cpiSupportedAndUserConfiguredList,
  dataSourceOptions,
  dataSourceValue,
  setLabelDrawerOpen,
  setMode,
  setSelectedSeverity,
  defaultValues,
  predictionData,
  refetchPredictionData,
  activeSources
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)

  const isSmallHeight = useMediaQuery('(max-height:800px)')
  const isBigHeight = useMediaQuery('(min-height:901px)')
  const isSmall = useMediaQuery('(max-width:1200px)')
  const isLaptop = useMediaQuery('(max-width:1280px)')
  const isLargeScreen = useMediaQuery('(max-width:1570px)')
  const isExtraLargeScreen = useMediaQuery('(max-width:1770px)')

  const [width, setWidth] = useState(getChartWidth(isSmall, isLaptop, isLargeScreen, isExtraLargeScreen))
  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const [height, setHeight] = useState(
    getChartHeight(isSmallHeight, isMediumHeight, isBigHeight) - margin.top - margin.bottom
  )
  const [maxValueY, setMaxValueY] = useState(100)

  const handleChangeSeverity = async (event: SelectChangeEvent) => {
    const value = event.target.value as CPISeverity
    setSelectedSeverity(value)

    if (manifestData) {
      await refetchPredictionData({ thisCpiName: manifestData?.name || '', severity: value })
    }
  }

  const renderSeverities = () => {
    if (!manifestData) return null
    const { user_input: userInput } = manifestData.ui.trend_chart

    if (userInput.visualization_param.sections.length <= 1) return null

    if (userInput.formula_params[0]?.sections.length > 0 || userInput.visualization_param.sections.length > 1) {
      let severities: string[] = []
      if (userInput.formula_params[0]?.sections.length > 0) {
        severities = userInput.formula_params[0]?.sections.map((n) => n.display_name as string)
      } else if (userInput.visualization_param.sections.length > 1) {
        severities = userInput.visualization_param.sections.map((n) => n.display_name as string)
      } else {
        return null
      }

      return (
        <>
          <Typography className="chips-title">
            {`${getSingularSectionsName(userInput.visualization_param.sections_display_name)}`}:
          </Typography>
          <TrendsChartSelectField
            options={severities}
            label=""
            placeholder="Filter by Severity"
            labeltext="Filter by: "
            value={selectedSeverity}
            handleChange={handleChangeSeverity}
            disabled={predictionData?.status !== DataStatus.Ready}
            minWidth="75px !important"
            hideSelected
            raise
          />
        </>
      )
    }

    return null
  }

  const buildTrendsChart = (chartData: PredictionChartData, yValues: number[]) => {
    const isNoSeveritiesCpi =
      !manifestData?.ui.trend_chart.user_input.formula_params ||
      (manifestData?.ui.trend_chart.user_input.visualization_param.identical_section_values &&
        manifestData?.ui.trend_chart.user_input.visualization_param.sections.length < 2)
    const offsetLeft = containerRef?.current?.offsetLeft || 0
    const healthUnit = manifestData?.ui.trend_chart.user_input.visualization_param.unit
    const tooltipUnit = manifestData?.ui.trend_chart.user_input.visualization_param.tooltip_unit
    const dataFirstValue = getUtcDateAndTime(chartData.values[0].xValue || '').labelFormatDate

    const svg = d3.select(svgRef.current)
    const tooltip = d3.select('.data-point-tooltip')
    const everything = svg.selectAll('*')
    everything.remove()

    const data = chartData

    /* ONYXIA-3546 */
    const filteredData = data.values
    const dataStartsAt = getDataStartsAt(dataFirstValue, null, selectedFrequency)

    const maxYScaleValue = Number(d3.max(yValues))
    const getYAxisDomain = (): [number, number] => {
      const defaultPercent: [number, number] = [0, 100]
      const nonPercentRange: [number, number] = [0, maxYScaleValue * 2]

      if (healthUnit === 'percent') {
        return defaultPercent
      }
      if (maxYScaleValue === 0 || data.status !== DataStatus.Ready) {
        return [0, 30]
      }
      return nonPercentRange
    }
    const yAxisDomain = getYAxisDomain()
    const uniqueMonths = findUniqueMonthsPredictions({ filteredData })
    const stripeWidth = width / uniqueMonths.length

    const [pathStr, positions, pathStrPrediction] = getPathAndPositionsPredictions({
      uniqueMonths,
      stripeWidth,
      height,
      filteredData: filteredData as any,
      selectedTimePeriod: CPITimePeriod.ThreeMonths,
      selectedFrequency: CPIFrequency.Daily,
      yAxisDomain,
      dataStatus: data.status,
      chartHeightStart,
      groupedByWeeks: null
    })

    const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
    const yScale = d3.scaleLinear().domain(yAxisDomain).range([height, chartHeightStart]).nice()
    const yLine = getYLine(chartData as any, maxValueY)
    const healthBorder = yScale(yLine)

    /*
     * Mouse Move for data point hover tooltip
     * */
    svg.on('mousemove', (event) => {
      if (data.status !== DataStatus.Ready || !positions.length) return

      const [mouseX, mouseY] = d3.pointer(event)
      const dataPoint = findNearestXIndex(positions, mouseX)
      const barHeight = height + margin.top + margin.bottom
      const diff = barHeight - xAxisHeight
      const isHoveringXAxis = mouseY >= diff
      const matchingDataPoint = filteredData[dataPoint?.index || 0]

      if (mouseX > width || mouseY > height || mouseY < height) {
        const addCustomEventButton = svg.select('g.add-event-button')
        addCustomEventButton.style('display', 'block')
      }

      if (!dataPoint) return
      onMouseMove({
        cpi: manifestData.name,
        svg,
        tooltip,
        event,
        dataPoint,
        d: matchingDataPoint,
        selectedTimePeriod,
        selectedFrequency,
        selectedSeverity,
        stripeWidth,
        isHoveringXAxis,
        healthUnit,
        tooltipUnit,
        isNoSeveritiesCpi,
        dataStartsAt,
        mouseX,
        mouseY,
        predictionTooltip: true
      })
    })

    appendPredictionTrendsChartElements({
      svg,
      tooltip,
      xScaleGroup,
      sectionsDisplayName: manifestData?.ui.trend_chart.user_input.visualization_param.sections_display_name,
      sectionsDisplayNameSingular:
        manifestData?.ui.trend_chart.user_input.visualization_param.sections_singular_display_name,
      isNoSeveritiesCpi,
      yScale,
      pathStrPrediction,
      pathStr,
      getAreaFill,
      uniqueMonths,
      filteredData,
      groupedByWeeks: null,
      data: data as any,
      pinnedLabels: [],
      dataDates: [],
      selectedTimePeriod,
      selectedFrequency,
      selectedSeverity,
      width,
      height,
      chartHeightStart,
      stripeWidth,
      healthUnit,
      healthBorder,
      maxYValue: yAxisDomain[1],
      maxYScaleValue,
      marginTop: margin.top,
      marginBottom: margin.bottom,
      offsetLeft,
      dataStartsAt,
      getAreaStroke,
      getLineColor,
      setDrawerOpen: setLabelDrawerOpen,
      setMode,
      setRedirectToMode,
      chartData: chartData as any,
      visibleLabels: [],
      hideEventIndicators: true
    })

    raiseElements(svg)
  }

  const handlePin = async () => {
    //
  }

  const handleEdit = async () => {
    //
  }

  const getCardColors = useCallback((action: SystemEventName) => {
    switch (action) {
      case SystemEventName.CpiInstanceAdded:
        return {
          background: '#402C3F',
          border: '1px solid #CE6BCF',
          titleColor: '#EC85EC',
          icon: <InstanceAddedLabelIcon />
        }
      case SystemEventName.CpiInstanceRemoved:
        return {
          background: '#402C3F',
          border: '1px solid #CE6BCF',
          titleColor: '#EC85EC',
          icon: <InstanceRemovedLabelIcon />
        }
      case SystemEventName.SlaChangedEvent:
        return {
          background: '#45312A',
          border: '1px solid #F09543',
          titleColor: '#F09543',
          icon: <SLAChangeLabelIcon />
        }
      case SystemEventName.CpiActivated:
        return {
          background: '#393144',
          border: '1px solid #8E9AFF',
          titleColor: '#8E9AFF',
          icon: <CPIChangeLabelIcon />
        }
      case SystemEventName.CpiCustom:
      default:
        return {
          background: '#453837',
          border: '1px solid #FFE082',
          titleColor: '#FFE082',
          icon: <CustomLabelIcon />
        }
    }
  }, [])

  useEffect(() => {
    if (!cpi || !manifestData || !predictionData) return

    const yValues = predictionData.values.map((d) => d.yValue)
    const dataMaxValue = Math.max(...predictionData.values.map((e) => e.yValue))
    setMaxValueY(dataMaxValue)

    buildTrendsChart(predictionData, yValues)
  }, [predictionData, cpi, manifestData, width, height])

  useLayoutEffect(() => {
    if (modalRef && modalRef.current) {
      const clientHeight = modalRef?.current?.clientHeight || 685
      const height = clientHeight - 270
      setHeight(height > 700 ? height - 8 : height - 30)
      const clientWidth = modalRef?.current?.clientWidth || 1200
      const width = clientWidth - 33
      setWidth(width > 1686 ? 1686 - 12 : width)
    }
  })

  return (
    <div>
      <Modal
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        aria-labelledby="transition-modal-title"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper-inner" ref={modalRef}>
            <IconButton className="close-modal-button" onClick={handleClose}>
              <CloseModalIcon />
            </IconButton>
            <Box className="header">
              <OnyxAIIcon />
              <Typography className="header-title">
                OnyxAI Trend Prediction : Percent of Overdue Vulnerabilities
              </Typography>
            </Box>

            <ChartWrapperPredictions
              status={predictionData?.status}
              severity={currentSeverityStatus}
              zone={predictionData ? predictionData.zoneColor : 'initializing'}
              sx={{
                marginTop: '0 !important',
                paddingTop: '0 !important',
                paddingLeft: '12px !important',
                paddingRight: '0 !important',
                width: '100%',
                maxWidth: '1724px'
              }}
            >
              <PredictionChartDescription
                zone={predictionData ? predictionData.zoneColor : 'initializing'}
                activeSources={activeSources}
                manifestData={manifestData}
                chartData={predictionData}
                setSelectedDataSourceIds={setSelectedDataSourceIds}
                setDataSourceValue={setDataSourceValue}
                selectedSeverity={selectedSeverity}
                userConfiguredIntegrations={userConfiguredIntegrations}
                cpiConfiguration={cpiConfiguration}
                cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
                dataSourceOptions={dataSourceOptions}
                dataSourceValue={dataSourceValue}
                renderSeverities={renderSeverities}
                refetchPredictionData={refetchPredictionData}
                lastValuePrediction={
                  predictionData && predictionData.values.length > 0
                    ? predictionData.values[predictionData.values.length - 1]
                    : null
                }
              />
              <Box className="chart-container">
                <div ref={containerRef} className="trends-d3-wrapper">
                  <TrendsChart
                    ref={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      containerRef,
                      svgRef
                    }}
                    visibleLabels={[]}
                    width={width}
                    height={height}
                    margin={margin}
                    setWidth={setWidth}
                    setHeight={setHeight}
                    healthValue={(defaultValues.sla as string) || ''}
                    currentSeverityStatus={currentSeverityStatus}
                    handlePin={handlePin}
                    handleEdit={handleEdit}
                    getCardColors={getCardColors}
                  />
                </div>
              </Box>
            </ChartWrapperPredictions>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}

export default PredictionModal
