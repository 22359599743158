import { useMemo, FC, useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material'
import axios from '../../../../lib/axios'

/* Utils */
import { defaultValues } from './setupIntegration.constants'
import { useAppDispatch } from '../../../../store/hooks'

/* Components */
import { EditIntegrationContainer, IntegrationsContainer } from './integrations.styles'
import { ManifestIntegration, ManifestIntegrationConfigSchema } from '../../../../models'
import { ThemeButton } from '../../../components/buttons'
import {
  IntegrationsCheckIcon,
  IntegrationsFailIcon,
  // RightIconButton,
  // SaveIconButton,
  VisibilityOffIcon,
  VisibilityOnIcon
} from '../../../components/svg'
// import ControlledTextField from '../../../components/form-components/ControlledTextField'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'
import ReactMarkdown from 'react-markdown'
import {
  // breadcrumbs,
  stringOfNumberedListToArray
} from '../../../../lib/utils'
// import { EnlargedImage } from './components'
import rehypeRaw from 'rehype-raw'
// import ControlledTrendsSelectField from '../../../components/form-components/ControlledTrendsSelectField'
// import InsightsCustomHeader from '../../onyxai-insights/components/InsightsCustomHeader'
// import HeaderComponent from '../../../components/header/HeaderComponent'
import ThemeHeader from '../../../components/header/ThemeHeader'
import ControlledTextFieldCustomLabel from '../../../components/form-components/ControlledTextFieldCustomLabel'
import EnlargeImageDialog from './components/EnlargeImageDialog'
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt'
import { ModalType, openModal } from '../../../../store/modals/modalSlice'
import ControlledCustomTrendsSelect from '../../../components/form-components/ControlledCustomTrendsSelect'
import { ConnectionDetailsTooltip } from './components/ConnectionDetailsTooltip'

export enum TestStatus {
  unchecked = 'unchecked',
  successful = 'successful',
  failed = 'failed'
}

interface SchemaItem {
  name: string
  label: string
  default: string | null
  required: boolean
}

interface Props {
  setupType: 'add' | 'edit'
}

const SetupIntegrationInstance: FC<Props> = ({ setupType }) => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const closeNotification = useRef<any>(null)
  const [integration, setIntegration] = useState<ManifestIntegration | null>(null)
  const [integrationLoading, setIntegrationLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [testing, setTesting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saving, setSaving] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [testStatus, setTestStatus] = useState<TestStatus>(TestStatus.unchecked)
  const [initialValues, setInitialValues] = useState<Record<string, string>>(defaultValues)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configSchema, setSchema] = useState<SchemaItem[] | null>(null)
  const [instructions, setInstructions] = useState<RegExpMatchArray | null>(null)
  const [visibleValues, setVisibleValues] = useState<Record<string, boolean>>({})
  const [instructionsOpen, setInstructionsOpen] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState({
    image: '',
    instruction: '',
    index: 0
  })
  const [savedValues, setSavedValues] = useState(defaultValues)
  const [allowSave, setAllowSave] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    setTesting(true)
    const configValues = {
      ...formik.values
    }
    delete configValues.integrationName
    delete configValues.description
    delete configValues.name

    try {
      const payload = {
        integrationName: integration?.name || '',
        description: formik.values.description,
        name: formik.values.name,
        config: configValues
      }

      let res
      if (setupType === 'add') {
        res = await axios.post(`/api/v3/integrations/${integration?.name || ''}/check_connection`, payload)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.description
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.integrationName
        res = await axios.post(`/api/v3/integrations/config/${params.instanceId}/check_connection`, payload)
      }

      if (res.data.status === 'failed') {
        setTestStatus(TestStatus.failed)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Connection test failed. Check your network and settings.',
              description: ''
            }
          })
        )
      } else {
        setTestStatus(TestStatus.successful)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Connection Test was successful!',
              description: "Click 'Save' to complete integration process"
            }
          })
        )
        setAllowSave(true)
      }
    } catch (e) {
      console.error(e)
      setTestStatus(TestStatus.failed)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Test Failed',
            description: (e as any).response?.data?.message || 'Please double check your configuration'
          }
        })
      )
    } finally {
      setLoading(false)
      setTesting(false)
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit
  })

  const discardDisabled = useMemo(() => {
    let disabled = true
    const values = formik.values

    if (instructionsOpen) {
      return false
    }

    for (const [key, value] of Object.entries(values)) {
      if (value !== (savedValues as any)[key]) {
        disabled = false
      }
    }

    return disabled
  }, [formik.values, savedValues, instructionsOpen])

  const [enablePrompt, setEnablePrompt] = useState(discardDisabled)

  useEffect(() => {
    if (setupType === 'add') {
      setEnablePrompt(false)
    } else {
      setEnablePrompt(!discardDisabled)
    }
  }, [discardDisabled, setupType])

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(enablePrompt)

  useEffect(() => {
    if (showPrompt) {
      dispatch(
        openModal({
          type: ModalType.cancelSetupIntegrationChanges,
          props: { confirmNavigation, cancelNavigation }
        })
      )
    }
  }, [showPrompt])

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    if (initialValues) {
      const valuesArr = Object.keys(initialValues)
      const valuesObj: Record<string, boolean> = {}
      valuesArr.forEach((key) => (valuesObj[key] = false))
      setVisibleValues(valuesObj)
    }
  }, [initialValues])

  const stepsImages: { [key: string]: number[] } = {
    microsoft_sentinel: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    microsoft_defender365: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    microsoft_defendervm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    crowdstrike_falcon: [1, 2, 3, 4, 5, 6, 7],
    crowdstrike_spotlight: [2, 3, 4, 5],
    knowbe4_kmsat: [1, 2, 3, 4],
    qualys_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    rapid7_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    tenable_vm: [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17],
    microsoft_entraid: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    google_workspace: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    orca: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    microsoft_defenderforcloud: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    cofense_phishme: [1, 2, 3, 4, 5],
    proofpoint_sat: [2, 3, 4, 5],
    sentinelone_singularity: [1, 2, 3, 4, 5, 6, 7, 8],
    okta: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    microsoft_intune: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    cisco_duo: [1, 2, 3, 4, 5],
    cisco_umbrella: [1, 2, 3, 4],
    jamf_pro: [1, 2, 3, 4, 5, 6, 7, 8],
    jumpcloud: [1, 2, 3, 4, 5],
    cloudflare: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    mimecast: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    rapid7_appsec: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    vmware_workspaceone: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    checkmarx_one: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    knowbe4_phisher: [2, 3, 4, 5, 6, 7],
    proofpoint_tap: [1, 2, 3, 4]
  }

  useEffect(() => {
    if (!integration) return

    if (integration?.documentation?.path) {
      import(`./instructions/${integration?.documentation?.path}_instructions.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setInstructions(stringOfNumberedListToArray(res, integration.name))
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setIntegrationLoading(false)
            })
        })
        .catch((err) => console.error(err))
    } else {
      setTimeout(() => {
        setIntegrationLoading(false)
      }, 200)
    }
  }, [integration])

  const fetchIntegrations = useCallback(async () => {
    try {
      const res = await axios.get('/api/v3/integrations/supported')

      if (res.status === 200) {
        const { integrations } = res.data
        const integration = integrations.find((n: ManifestIntegration) => n.name === params.integrationName)
        setIntegration(integration)

        let currentName = integration.name
        if (integration.name === 'microsoft_defender365') {
          currentName = integration.display_name.toLowerCase().replaceAll(' ', '_')
        }
        const instanceValues: Record<string, string> = {
          name: currentName,
          description: integration.description || ''
        }
        if (integration.config.schema) {
          integration.config.schema.forEach((n: Record<string, any>) => {
            instanceValues[n.name] = ''
          })
        }
        setInitialValues(instanceValues)
        formik.setValues(instanceValues)
        setSavedValues(instanceValues as any)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchInstanceConfig = useCallback(async () => {
    try {
      const res = await axios.get(`/api/v3/integrations/config/${params.instanceId}`)

      if (res.status === 200) {
        const { data: instance } = res.data
        const { config } = instance
        let instanceValues: Record<string, string> = {
          name: instance.name,
          description: instance.description || ''
        }
        if (config.schema) {
          config.schema.forEach((n: Record<string, any>) => {
            instanceValues[n.name] = ''
          })
        } else {
          // const regex = /^\*.*\*$/
          instanceValues = {
            ...instanceValues,
            ...config
          }

          // for (const [key, value] of Object.entries(config)) {
          // const test = regex.test(value as string)
          // if (test) {
          //   instanceValues[key] = ''
          // }
          // }
        }
        setInitialValues(instanceValues)
        formik.setValues(instanceValues)
        setSavedValues(instanceValues as any)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchData = async () => {
    setDataLoading(true)

    try {
      await fetchIntegrations()
      if (setupType === 'edit' && params.instanceId) {
        await fetchInstanceConfig()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setDataLoading(false)
    }
  }

  useEffect(() => {
    fetchData().catch((e) => e)
  }, [setupType, params.instanceId])

  useEffect(() => {
    if (integration && integration.config.schema) {
      const result = integration.config.schema
      const items: SchemaItem[] = []
      result.forEach((n) => {
        const item = {
          name: n.name,
          label: n.title,
          default: n.default ?? null,
          required: n.required
        }
        items.push(item)
      })
      setSchema(items)

      if (setupType === 'add') {
        const initialValues: Record<string, string> = {}
        if (integration.config.schema) {
          integration.config.schema.forEach((n) => {
            initialValues[n.name] = ''
          })
        }
        setInitialValues(initialValues)
        formik.setValues({ ...formik.values, ...initialValues })
        setSavedValues({ ...formik.values, ...initialValues } as any)
      }
    }
  }, [integration])

  const handleSave = async () => {
    clearTimeout(closeNotification.current)
    setLoading(true)
    setSaving(true)
    const configValues = {
      ...formik.values
    }
    delete configValues.integrationName
    delete configValues.description
    delete configValues.name

    try {
      if (setupType === 'edit') {
        const payload = {
          name: formik.values.name,
          description: formik.values.description,
          config: configValues
        }

        await axios.put(`/api/v3/integrations/config/${params.instanceId}`, payload)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Integration changes were saved successfully!',
              description: `${integration?.display_name}`
            }
          })
        )
        navigate('/settings/integrations')
      } else {
        const payload = {
          integrationName: integration?.name || '',
          description: formik.values.description,
          name: formik.values.name,
          config: configValues
        }

        await axios.post('/api/v3/integrations/config', payload)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Integration changes were saved successfully!',
              description: `${integration?.display_name}`
            }
          })
        )
        navigate('/settings/integrations')
      }

      setSavedValues({
        name: formik.values.name,
        description: formik.values.description,
        ...configValues
      })
      setAllowSave(false)
      setEnablePrompt(false)
    } catch (e) {
      console.error(e)
      setTestStatus(TestStatus.failed)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed Saving Configuration',
            description: (e as any).response?.data?.message || 'Please double check your configuration'
          }
        })
      )
    } finally {
      setLoading(false)
      setSaving(false)
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const sortIntegrationsSchemaByOrder = (items: ManifestIntegrationConfigSchema[]) => {
    return items.sort((a, b) => ((a as any).order > (b as any).order ? 1 : -1))
  }

  const getSetupTypeText = () => {
    const firstLetter = setupType.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = setupType.slice(1)

    return firstLetterCap + remainingLetters
  }

  const handleExpand = () => {
    setInstructionsOpen(!instructionsOpen)
  }

  const handleDiscard = () => {
    formik.setValues(savedValues)
    setAllowSave(false)
    // setInstructionsOpen(false)
  }

  const handleClearAll = () => {
    const fields = formik.values
    const newValues: any = {}
    for (const key of Object.keys(fields)) {
      if (key === 'name' || key === 'description') {
        newValues[key] = fields[key]
      } else {
        newValues[key] = ''
      }
    }
    formik.setValues(newValues)
  }

  const testDisabled = useMemo(() => {
    if (!integration) return true

    const values = formik.values
    const schema = integration.config.schema

    if (!values.name || !values.name.length) {
      return true
    }

    let disabled = false
    schema.forEach((item) => {
      if (item.required) {
        const found = values[item.name]
        if (typeof found !== 'undefined') {
          if (item.required && item.type === 'string') {
            if (found.length < 1) {
              disabled = true
            }
          }
        }

        if (!found) {
          disabled = true
        }
      }
    })

    return disabled
  }, [formik.values, integration])

  if (!integration || dataLoading) {
    return <IntegrationsContainer />
  }
  return (
    <>
      <IntegrationsContainer hasPadding={true}>
        <ThemeHeader
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          allowSave={allowSave}
          handleBack={() => {
            navigate(-1)
          }}
          headerTitle={`${getSetupTypeText()} ${integration.display_name} Integration Instance`}
          testStatus={testStatus}
          isSubmitting={formik.isSubmitting}
          isValid={formik.isValid}
          loading={loading}
          schema={integration?.config?.schema}
          discardDisabled={discardDisabled}
        />

        {/* <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnMount> */}

        {/*        {({ isSubmitting, isValid, values }) => (
       <Form autoComplete="off"> */}
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <EditIntegrationContainer
            testStatus={testStatus}
            className="sections-wrapper"
            isopen={instructionsOpen ? 'true' : 'false'}
          >
            <Box id="left-side" className="left">
              <Box className="info">
                <Box className="connection-details-wrapper">
                  <Box className="connection-details-description">
                    <Typography className="connection-details-title">Connection Details</Typography>
                    <Typography className="connection-details-text">
                      Provide the necessary information to establish and configure your connection
                    </Typography>
                  </Box>
                  <Box className="connection-details-action">
                    <ConnectionDetailsTooltip title={instructionsOpen ? 'Less information' : 'More information'} arrow>
                      <IconButton
                        onClick={handleExpand}
                        sx={{
                          background: instructionsOpen ? '#590060' : '',

                          svg: {
                            path: {
                              fill: instructionsOpen ? '#D9BFD4' : '#FFA9FC'
                            }
                          },

                          '&:hover': {
                            background: '#544152',

                            svg: {
                              path: {
                                fill: '#FFA9FC'
                              }
                            }
                          }
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#FFA9FC"
                          />
                        </svg>
                      </IconButton>
                    </ConnectionDetailsTooltip>
                  </Box>
                </Box>

                <ControlledTextFieldCustomLabel
                  name="name"
                  label="Name"
                  required
                  onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                  value={formik.values.name}
                  onChange={(e) => {
                    setAllowSave(true)
                    formik.handleChange(e)
                  }}
                  disabled={loading || formik.isSubmitting}
                />

                <ControlledTextFieldCustomLabel
                  name="description"
                  label="Description (Optional)"
                  value={formik.values.description}
                  onChange={(e) => {
                    setAllowSave(true)
                    formik.handleChange(e)
                  }}
                  onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                  disabled={loading || formik.isSubmitting}
                />

                {integration.config.schema &&
                  sortIntegrationsSchemaByOrder(integration.config.schema).map((n) => {
                    if (n.enum && n.enum.length > 0) {
                      return (
                        <ControlledCustomTrendsSelect
                          options={n.enum}
                          placeholder={n.title}
                          label="Region"
                          labeltext={n.title}
                          value={formik.values[n.name]}
                          onChange={(e) => {
                            setAllowSave(false)
                            formik.handleChange(e)
                          }}
                          name={n.name}
                          disabled={false}
                          multiple={false}
                          fullWidth
                          autoComplete="off"
                        />
                      )
                    }
                    return (
                      <ControlledTextFieldCustomLabel
                        data-private
                        key={n.name}
                        name={n.name}
                        label={n.title}
                        required={n.required}
                        placeholder={`Enter ${n.title}`}
                        onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                        autoComplete="off"
                        value={formik.values[n.name]}
                        onChange={(e) => {
                          setAllowSave(false)
                          formik.handleChange(e)
                        }}
                        type={n.type === 'integer' ? 'number' : visibleValues[n.name] ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle Client Secret visibility"
                              onClick={() => setVisibleValues((pre) => ({ ...pre, [n.name]: !visibleValues[n.name] }))}
                              edge="end"
                            >
                              {!visibleValues[n.name] ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        disabled={loading || formik.isSubmitting}
                      />
                    )
                  })}
              </Box>

              <Box className="actions">
                <ThemeButton
                  type="submit"
                  disabled={
                    formik.isSubmitting || testDisabled || !formik.isValid || loading || !integration?.config?.schema
                  }
                  themevariant="secondary"
                  startIcon={testing && <CircularProgress size="24px" />}
                  sx={{
                    minWidth: '190px',

                    '.MuiCircularProgress-root': {
                      color: '#FFA9FC'
                    },
                    '&.Mui-disabled': {
                      borderRadius: '100px',
                      border: testStatus === TestStatus.unchecked && !testing ? '' : '1px solid #998D96 !important',
                      background: testStatus === TestStatus.unchecked && !testing ? '' : 'transparent !important',
                      color: testStatus === TestStatus.unchecked && !testing ? '#FFA9FC' : '#fff !important'
                    }
                  }}
                >
                  <>
                    {testStatus === TestStatus.unchecked && (
                      <>
                        {testing ? (
                          'Testing...'
                        ) : (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5556 3L22 7.44444L17.5556 11.8889V8.55556H13.1111V6.33333H17.5556V3ZM9.77778 6.33333C9.16667 6.33333 8.66667 6.83333 8.66667 7.44444C8.66667 8.05556 9.16667 8.55556 9.77778 8.55556C10.3889 8.55556 10.8889 8.05556 10.8889 7.44444C10.8889 6.83333 10.3889 6.33333 9.77778 6.33333ZM5.33333 6.33333C4.72222 6.33333 4.22222 6.83333 4.22222 7.44444C4.22222 8.05556 4.72222 8.55556 5.33333 8.55556C5.94444 8.55556 6.44444 8.05556 6.44444 7.44444C6.44444 6.83333 5.94444 6.33333 5.33333 6.33333ZM6.44444 17.4444H10.8889V15.2222H6.44444V11.8889L2 16.3333L6.44444 20.7778V17.4444ZM14.2222 17.4444C14.8333 17.4444 15.3333 16.9444 15.3333 16.3333C15.3333 15.7222 14.8333 15.2222 14.2222 15.2222C13.6111 15.2222 13.1111 15.7222 13.1111 16.3333C13.1111 16.9444 13.6111 17.4444 14.2222 17.4444ZM18.6667 17.4444C19.2778 17.4444 19.7778 16.9444 19.7778 16.3333C19.7778 15.7222 19.2778 15.2222 18.6667 15.2222C18.0556 15.2222 17.5556 15.7222 17.5556 16.3333C17.5556 16.9444 18.0556 17.4444 18.6667 17.4444Z"
                                fill="#FFA9FC"
                              />
                            </svg>
                            Test Connection
                          </>
                        )}
                      </>
                    )}
                    {testStatus === TestStatus.successful && (
                      <>
                        <IntegrationsCheckIcon />
                        Test Successful
                      </>
                    )}
                    {testStatus === TestStatus.failed && (
                      <>
                        <IntegrationsFailIcon />
                        Test Failed
                      </>
                    )}
                  </>
                </ThemeButton>
                <ThemeButton
                  themevariant="tertiary"
                  type="button"
                  sx={{ paddingLeft: '16px !important', paddingRight: '16px !important' }}
                  onClick={handleClearAll}
                  disabled={loading || formik.isSubmitting}
                >
                  <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.2222 8.66667V19.7778H7.33333V8.66667H16.2222ZM14.5556 2H9L7.88889 3.11111H4V5.33333H19.5556V3.11111H15.6667L14.5556 2ZM18.4444 6.44444H5.11111V19.7778C5.11111 21 6.11111 22 7.33333 22H16.2222C17.4444 22 18.4444 21 18.4444 19.7778V6.44444Z"
                        fill="#FFA9FC"
                      />
                    </svg>
                    Clear All
                  </>
                </ThemeButton>

                {/*                  <ThemeButton
                 themevariant="tertiary"
                 type="button"
                 sx={{ paddingLeft: '8px !important', paddingRight: '8px !important' }}
                 onClick={() => {
                 navigate(-1)
                 }}
                 >
                 <>
                 <RightIconButton style={{ transform: 'rotate(180deg)' }} /> Discard Changes
                 </>
                 </ThemeButton>
                 <ThemeButton
                 type="button"
                 themevariant="quaternary"
                 onClick={() => handleSave(values)}
                 disabled={
                 testStatus !== TestStatus.successful ||
                 isSubmitting ||
                 !isValid ||
                 loading ||
                 !integration?.config?.schema
                 }
                 endIcon={saving && <CircularProgress size="24px" color="secondary" />}
                 >
                 <>
                 <SaveIconButton />
                 Save & Exit
                 </>
                 </ThemeButton> */}
              </Box>
            </Box>

            {instructionsOpen && (
              <Box id="right-side" className="right">
                <Box className="right-text-wrapper">
                  <Typography className="instructions-text">Instructions</Typography>
                  {integrationLoading ? (
                    ''
                  ) : (
                    <>
                      {!instructions?.length ? (
                        <Typography className="instructions-description">N/A</Typography>
                      ) : (
                        <Typography className="instructions-description">
                          Follow these steps to successfully set up and configure your connection
                        </Typography>
                      )}
                    </>
                  )}
                </Box>

                {instructions?.map((instruction, index) => {
                  const thisInstruction = instruction.slice(3)
                  const idx = index + 1
                  return (
                    <Box className="instructions-inner" key={instruction}>
                      {stepsImages[integration.documentation.path] &&
                      stepsImages[integration.documentation.path].includes(idx) ? (
                        // <EnlargedImage index={index} integration={integration} />
                        <img
                          alt={`step_${idx}_image`}
                          src={`/${integration.documentation.path}_step_${idx}.png`}
                          width="160px"
                          className="instructions-image"
                          onClick={() => {
                            handleOpenDialog()
                            setDialogProps({
                              image: `/${integration.documentation.path}_step_${idx}.png`,
                              instruction,
                              index: idx
                            })
                          }}
                        />
                      ) : (
                        <Box className="empty-image" />
                      )}

                      <Box className="instructions-right-side">
                        <Typography className="step-text">STEP {idx}</Typography>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{thisInstruction}</ReactMarkdown>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )}
          </EditIntegrationContainer>
        </form>
        {/*         </Form>
         )} */}
        {/* </Formik> */}
      </IntegrationsContainer>
      <EnlargeImageDialog handleCloseDialog={handleCloseDialog} open={dialogOpen} dialogProps={dialogProps} />
    </>
  )
}

export default SetupIntegrationInstance
