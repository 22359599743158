import { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/* Utils */
import { IChartData, PerformanceStatus } from '../../../../../models'
import { PerformanceScoreTooltipProps } from './PerformanceScoreTooltip'
import { categoryToUrl } from '../../../library/utils'

/* Components */
import {
  EmptyPerformanceCategoriesWrapper,
  PerformanceCategoriesWrapper,
  PerformanceCategoryItem,
  PerformanceScoreWrapper
} from './performanceScore.styles'
import PerformanceScoreChart from './PerformanceScoreChart'
import { EmptyPizza } from '../../../../components/svg'

interface Props {
  chartLoading: boolean
  chartData: IChartData[]
  initialLoad: boolean
  noCategories: boolean
  isEmpty: boolean
  handleCategoryClick: (category: string) => void
  lastUpdatePerformance: string
}

const PerformanceScore: FC<Props> = ({
  chartLoading,
  chartData,
  initialLoad,
  isEmpty,
  handleCategoryClick,
  noCategories,
  lastUpdatePerformance
}) => {
  const navigate = useNavigate()
  const [tooltip, setTooltip] = useState<PerformanceScoreTooltipProps>({ x: 0, y: 0, content: null })
  const [overperformanceIconTooltip, setOverperformanceIconTooltip] = useState<PerformanceScoreTooltipProps>({
    x: 0,
    y: 0,
    content: null
  })

  const handleCategoryItemClick = (cat: string) => {
    const url = categoryToUrl(cat)
    navigate(`/library/categories/${url}`)
  }

  const handleLibraryCategoryRedirect = (cat: string) => {
    navigate('/library', { state: { navigatedCategory: cat } })
  }

  const data = [...chartData.map((n) => ({ ...n, score: n.score === 100 ? 99.99 : n.score }))]
  if (data.length < 6) {
    data.push({ category: '', status: PerformanceStatus.unavailable, score: 0 })
  }

  return (
    <PerformanceScoreWrapper isempty={isEmpty ? 'true' : 'false'}>
      <Box className="title-wrapper">
        <Box className="title-block">
          <img src="/title-vector.svg" alt="title-vector" />
          <Typography className="chart-title">Category Performance Score</Typography>
        </Box>

        <Typography className="date">Last Update: {lastUpdatePerformance}</Typography>
      </Box>

      <Box className="pizza-chart-container">
        <Box className="pizza-chart-wrapper">
          {!chartLoading && chartData.length > 0 && !isEmpty && (
            <PerformanceScoreChart
              // ONY-2326 Show only available CPIs with 1 "Coming Soon" slice
              // ONY-3272 Show inactive slice only when 5 or fewer slices are shown on pizza chart
              data={data}
              noCategory={initialLoad && noCategories}
              tooltip={tooltip}
              setTooltip={setTooltip}
              overperformanceIconTooltip={overperformanceIconTooltip}
              setOverperformanceIconTooltip={setOverperformanceIconTooltip}
              handleCategoryClick={handleCategoryClick}
            />
          )}
          {isEmpty && <EmptyPizza />}
        </Box>

        {isEmpty && (
          <EmptyPerformanceCategoriesWrapper>
            <Box className="category-row">
              <Typography className="name">Detection & Response</Typography>
              <Typography className="value">%</Typography>
            </Box>
            <Box className="category-row">
              <Typography className="name">Vulnerability Management</Typography>
              <Typography className="value">%</Typography>
            </Box>
            <Box className="category-row">
              <Typography className="name">Traininig & Awareness</Typography>
              <Typography className="value">%</Typography>
            </Box>
            <Box className="category-row">
              <Typography className="name">Cloud Security</Typography>
              <Typography className="value">%</Typography>
            </Box>
          </EmptyPerformanceCategoriesWrapper>
        )}
        {!isEmpty && (
          <PerformanceCategoriesWrapper className="performance-categories-list-wrapper">
            {data.map(({ category, status, score }) => {
              if (!category || status === PerformanceStatus.unavailable) return null
              return (
                <PerformanceCategoryItem
                  key={category}
                  onClick={() => {
                    if (status === PerformanceStatus.available) {
                      handleLibraryCategoryRedirect(category)
                    } else {
                      handleCategoryItemClick(category)
                    }
                  }}
                  isselected={tooltip.content && tooltip.content.category === category ? 'true' : 'false'}
                >
                  <Typography className="name">{category}</Typography>
                  {status === PerformanceStatus.available ? (
                    <Typography className="explore-category">Explore CPI Library</Typography>
                  ) : (
                    <Typography className="value">{Math.round(score)}%</Typography>
                  )}
                </PerformanceCategoryItem>
              )
            })}
          </PerformanceCategoriesWrapper>
        )}
      </Box>
    </PerformanceScoreWrapper>
  )
}

export default PerformanceScore
