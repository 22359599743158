import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const MainSectionContent = styled(Box)<{ bg: string }>`
  border-radius: 16px;
  background: transparent;
  width: 100%;
  flex-basis: 50%;
  padding: 24px 24px 0;
  overflow-y: auto;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 24px;
  }

  .main-section-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    .main-section-header__left {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 707px;

      .title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .description {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: pre-line;
      }

      .details-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        .details-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10.5px;

          &.second {
            //align-items: center;
            text-transform: capitalize;
          }

          &.last {
            //align-items: flex-end;
          }

          .details-item-title {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 510;
            line-height: normal;
            white-space: nowrap;
          }

          .details-item-text {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 20px;

            &.no-bold {
              font-weight: 400;
            }
          }
        }

        .section-divider {
          width: 1px;
          height: 51px;
          background: #4d444b;
          margin: 0;
        }
      }
    }

    .main-section-header__right {
      padding: 0 8px;
    }
  }
`

export const HistorySectionContent = styled(Box)<{ bg: string }>`
  width: 545px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 1440px) {
    width: 100%;
  }

  .main-section-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 20px 12px 16px;
    border-radius: 16px;
    position: relative;
    z-index: 1;

    img {
      z-index: 10;
    }

    .header-text {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-left: 8px;
      letter-spacing: 0.5px;
      flex-grow: 1;
    }

    .header-date {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .content-inner {
    padding: 0 16px 13px;
    width: 100%;

    .performance-title {
      margin-bottom: 12.5px;
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .performance-description {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-top: 12px;
      margin-right: 24px;
    }
  }
`

export const HeaderBackground = styled(Box)<{ bg: string }>`
  width: 543px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 16px 0 0;
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;

  @media (max-width: 1440px) {
    width: 100%;
  }
`

export const ContentInner = styled(Box)`
  padding: 45px 0 0 0;

  .performance-text {
    margin-bottom: 12.5px;
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .main-performance-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 32px;
  }
`

export const InsightsImageWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #292128;
  padding: 40px 0;
`
