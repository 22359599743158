import { FC, Dispatch, SetStateAction, SyntheticEvent } from 'react'
import { Box, IconButton, Tab } from '@mui/material'
import { useLocation } from 'react-router-dom'

/* Utils */
import { a11yProps } from '../../library/library-details/components/LibraryDetailsTabPanel'

/* Components */
import { HeaderContainer } from './insightsHeader.styles'
import { LibraryDetailsHoverTooltip } from '../../library/library-details/components/LibraryDetailsTooltip'
import { IntegrationsTabs } from '../../settings/integrations/integrations.styles'
import InsightsSearch from './InsightsSearch'
import ToolbarComponent from '../../../components/header/ToolbarComponent'

interface Props {
  tabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  title: string
  tabLabels: string[]
  hideIntegrationsTabs?: boolean
  loading?: boolean
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  setFiltersToolbarOpen: Dispatch<SetStateAction<boolean>>
  filtersToolbarOpen: boolean
  filtersActive: boolean
}

const InsightsHeader: FC<Props> = ({
  tabValue,
  handleTabChange,
  title,
  loading,
  tabLabels,
  hideIntegrationsTabs,
  search,
  setSearch,
  setFiltersToolbarOpen,
  filtersToolbarOpen,
  filtersActive
}) => {
  const location = useLocation()

  return (
    <ToolbarComponent className="insights-header">
      <HeaderContainer className="insights-header-container">
        <Box className="header-content-container">
          <Box className="search-container">
            <InsightsSearch search={search} setSearch={setSearch} placeholder="i.e. insight text or cpi number" />
          </Box>

          <Box className="date-select-container">
            <IconButton
              onClick={() => setFiltersToolbarOpen(!filtersToolbarOpen)}
              sx={{
                background: filtersToolbarOpen ? '#590060' : '',
                position: 'relative',

                '&:hover': {
                  background: '#590060',
                  svg: {
                    path: {
                      fill: '#fff'
                    }
                  }
                }
              }}
            >
              {filtersActive ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M5.702 4.5H18.202L11.9395 12.375L5.702 4.5ZM2.2645 4.0125C4.7895 7.25 9.452 13.25 9.452 13.25V20.75C9.452 21.4375 10.0145 22 10.702 22H13.202C13.8895 22 14.452 21.4375 14.452 20.75V13.25C14.452 13.25 19.102 7.25 21.627 4.0125C22.2645 3.1875 21.677 2 20.6395 2H3.252C2.2145 2 1.627 3.1875 2.2645 4.0125Z"
                      fill={filtersToolbarOpen ? '#fff' : '#FFA9FC'}
                    />
                  </svg>

                  <Box className="active-circle">
                    <Box className="circle" />
                  </Box>
                </>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M5.702 4.5H18.202L11.9395 12.375L5.702 4.5ZM2.2645 4.0125C4.7895 7.25 9.452 13.25 9.452 13.25V20.75C9.452 21.4375 10.0145 22 10.702 22H13.202C13.8895 22 14.452 21.4375 14.452 20.75V13.25C14.452 13.25 19.102 7.25 21.627 4.0125C22.2645 3.1875 21.677 2 20.6395 2H3.252C2.2145 2 1.627 3.1875 2.2645 4.0125Z"
                      fill={filtersToolbarOpen ? '#fff' : '#FFA9FC'}
                    />
                  </svg>
                </>
              )}
            </IconButton>
          </Box>
        </Box>

        {!hideIntegrationsTabs && (
          <IntegrationsTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label={`${title} tabs`}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            isdetails="true"
          >
            {tabLabels.map((label, index) => {
              if (label === 'Data Sources' && location.pathname === '/asset-coverage-analyzer') {
                return (
                  <LibraryDetailsHoverTooltip key={index} title="Enabled data sources to analyze" arrow>
                    <Tab label={label} {...a11yProps(index)} />
                  </LibraryDetailsHoverTooltip>
                )
              }

              return <Tab label={label} {...a11yProps(index)} key={index} disabled={loading} />
            })}
          </IntegrationsTabs>
        )}
      </HeaderContainer>
    </ToolbarComponent>
  )
}

export default InsightsHeader
