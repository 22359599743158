import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const Wrapper = styled(Box)<{ isSelected: string; isloading?: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #372a36;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  height: 108px;
  width: 100%;

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
      background: #573F56;
  `};

  &:hover {
    cursor: pointer;
    background: #573f56;

    ${({ isloading }) =>
      isloading &&
      isloading === 'true' &&
      `
    cursor: wait;
  `};
  }
`

export const CardHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .card-header-left {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    .icon-wrapper {
      position: relative;

      .unread-indicator {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 2;
      }

      .card-icon {
        z-index: 1;
      }
    }

    .card-header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: calc(100% - 72px);
      gap: 8px;

      .card-header-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .insight-header-text {
          flex-grow: 1;
          color: #d9bfd4;
          font-size: 10px;
          font-style: normal;
          font-weight: 590;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-right: 8px;
        }

        .insight-date-text {
          color: #d9bfd4;
          font-size: 10px;
          font-style: normal;
          font-weight: 590;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .insight-title-text {
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
`

export const CardFooter = styled(Box)`
  display: flex;
  align-items: center;
  //width: calc(100% - 56px);
  width: 100%;

  .card-footer-left {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    //flex-shrink: 1;
    //flex-grow: 0;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }

    .cpi-name-text {
      color: #d9bfd4;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .card-footer-right {
    //flex-grow: 1;
    //width: 100%;
  }
`

export const FooterDivider = styled(Box)`
  background: #998d96;
  height: 20px;
  margin: 0 16px;
  width: 1px;
  flex-shrink: 0;
`
