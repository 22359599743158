import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #4d444b;
  background: transparent;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .back-arrow-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 100%;
      border-right: 1px solid ${theme.colors.borderLow};
    }

    .header-content {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-left: 24px;

      .header-title {
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .header-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding-right: 24px;
    }
  }
`
