import { Box, Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background: #141218;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
    min-width: 320px;
    padding: 16px 24px;
    border-left: 1px solid #998d96;
    overflow: visible;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }

    .wrapper-inner {
      width: 100%;
      margin-bottom: 100px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4px;

        .close-icon {
          width: 40px;
          height: 40px;

          &:hover {
            background: rgba(202, 196, 208, 0.12);
          }

          svg {
            path {
              fill: #ffffff;
            }
          }
        }

        .title {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 22px;
          line-height: 28px;
          color: #ffffff;
        }
      }

      .cpi-wrapper {
        display: inline-flex;
        border-radius: 18px;
        background: #debdd9;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
        padding: 2px 8px;
        margin-bottom: 24px;

        .MuiTypography-root {
          font-family: 'Quicksand', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #3f293e;
        }
      }

      .content {
        .select-category-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          flex-direction: column;
          margin-bottom: 24px;

          .select-category-header {
            .MuiTypography-root {
              color: #fff;
              font-family: Quicksand, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.1px;
            }
          }

          .select-category-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;
            width: 100%;

            .select-category-text {
              min-width: 75px !important;
              overflow: hidden;
              color: #fff;
              text-overflow: ellipsis;
              text-align: left;
              font-family: Quicksand, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .text {
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #ffffff;
        }

        .red-required {
          display: inline-block;
          color: #fe455d;
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
        }

        .text-sub {
          font-family: 'Quicksand', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #ffffff;
        }

        .also-available-wrapper {
          display: flex;
          align-items: center;
          margin: 8px 0 16px;

          .also-available-text {
            font-family: 'Quicksand', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #e9e0e4;
          }

          .also-available-link {
            font-family: 'Quicksand', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #ffa9fc;
            cursor: pointer;
            text-decoration: underline;
            text-transform: capitalize;
          }
        }

        .benchmarks-title {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-top: 24px;

          .benchmark-header {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;

            span {
              color: #fe455d;
            }
          }

          .benchmark-subheader {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4px;
          }
        }

        .benchmarks-wrapper {
          margin-top: 8px;
          border-radius: 16px;
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

          .benchmarks-header {
            border-radius: 16px 16px 0 0;
            margin-bottom: 8px;
            padding: 12px 16px;
            gap: 24px;
            height: 56px;
            align-items: center;

            .head-1 {
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.5px;
              color: #ffffff;
              word-wrap: break-word;
              overflow-wrap: break-word;
              max-width: 80px;
            }

            .head-2 {
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.5px;
              color: #ffffff;
            }
          }

          .benchmarks-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            border-radius: 0 0 16px 16px;

            .benchmarks-row {
              height: 56px;
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 42px;

              &:first-of-type {
                margin-top: 24px;
              }

              &:last-of-type {
                border-radius: 16px;
              }

              .info-tooltip {
                padding-top: 8px;
              }

              .MuiFormControl-root {
                max-width: 238px;

                .all-line {
                  display: flex;
                  align-items: center;
                }

                .MuiInputLabel-root {
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 400;
                  color: #ffffff;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.5px;
                }

                .Mui-focused,
                .MuiFormLabel-filled {
                  background: #141218;
                  font-family: 'Quicksand', sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  letter-spacing: 0.4px;
                  color: #fff;
                  padding-left: 4px;
                  padding-right: 4px;
                }

                .MuiOutlinedInput-root {
                  height: 56px;
                  line-height: normal;
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 400;
                  font-size: 14px;
                  letter-spacing: 0.25px;
                  color: #ffffff;
                  border: 1px solid #998d96;
                  width: 230px;

                  input {
                    height: 56px;
                    padding-top: 0;
                    padding-bottom: 0;
                    width: 36px;
                  }

                  fieldset {
                    line-height: normal;
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    color: #ffffff;
                  }
                }

                .fixed-w input {
                  width: 150px !important;
                }

                .MuiFormHelperText-root {
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 16px;
                  letter-spacing: 0.5px;
                  color: #ffb4ab;
                  margin-left: 0;
                }
              }

              .MuiInputAdornment-root {
                .MuiTypography-root {
                  font-family: 'Quicksand', sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0.5px;
                  color: #968f92;
                  padding-left: 6px;
                }
              }
            }
          }
        }

        .drawer-actions {
          background: #131013;
          border-top: 1px solid #998d96;
          padding: 16px 24px 22px;
          display: flex;
          align-items: center;
          position: fixed;
          bottom: 0;
          width: 320px;
          right: 0;

          .deactivate-button {
            margin-left: 8px;
            background: #ffb4ab !important;
            border-radius: 100px !important;
            font-family: 'Quicksand', sans-serif !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 20px !important;
            display: flex !important;
            align-items: center !important;
            text-align: center !important;
            letter-spacing: 0.1px !important;
            color: #690005 !important;
            white-space: nowrap;
          }
        }
      }
    }
  }
`

export const IntegrationItem = styled(Box)<{ active?: boolean }>`
  position: relative;

  img {
    border: 1px solid #998d96;
    border-radius: 50%;
    ${({ active }) =>
      active &&
      `
       border: 1px solid #aed581;
      `};
  }

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #998d96;
    position: absolute;
    right: -3px;
    top: 10px;
    ${({ active }) =>
      active &&
      `
        background: #aed581;
      `};
  }

  .circle-new {
    z-index: 10;
    top: 6px;
    right: -7px;
  }
`

export const IntegrationTextItem = styled(Box)<{ active: boolean }>`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .MuiTypography-root {
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .box-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 8px;

    .text {
      font-family: 'Quicksand', sans-serif;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 0 !important;
      color: #998d96 !important;

      ${({ active }) =>
        active &&
        `
        color: #aed581!important;
      `};
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: #998d96;

      ${({ active }) =>
        active &&
        `
        background: #aed581;
      `};
    }
  }
`

export const IntegrationInstanceCard = styled(Box)<{ isFailed?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;
  background: #30262f;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 8px 12px 8px 8px;
  height: 56px;

  img {
    margin-left: ${({ isFailed }) => (isFailed ? '0' : '6px')};
    object-fit: cover;
    min-width: 40px;
  }

  .MuiTypography-root {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #e9e0e4;
    margin-left: 8px;
    word-break: break-all;
  }
`
