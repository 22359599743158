import { FC, useCallback, Dispatch, SetStateAction } from 'react'
import { Typography } from '@mui/material'
import unique from 'lodash.uniq'

/* Utils */
import { Insight } from '../../../../../models'

/* Components */
import { Wrapper, InsightGroupWrapper, CardsGroup } from './insightsList.styles'
import InsightsCard from '../../components/InsightsCard'
import ListToolbar from './ListToolbar'
import { Filters } from '../../OnyxAIInsights'
import CardLoadingSkeleton from './CardLoadingSkeleton'
import HeaderLoadingSkeleton from './HeaderLoadingSkeleton'

interface Props {
  insightsGroups: Array<Insight[]>
  selectedInsight: Insight
  setSelectedInsight: Dispatch<SetStateAction<Insight | null>>
  handleClickInsight: (insightId: string) => void
  changeReadStatus: (integrationIds: string[], read: boolean) => Promise<void>
  loading: boolean
  handleChangeFilters: (newValue: any, field: string) => void
  filters: Filters
  setGroups: Dispatch<SetStateAction<Insight[][]>>
  setLoadingMarking: Dispatch<SetStateAction<boolean>>
  loadingMarking: boolean
  search: string
  data: Insight[]
}

const InsightsList: FC<Props> = ({
  insightsGroups,
  selectedInsight,
  handleClickInsight,
  changeReadStatus,
  setSelectedInsight,
  loading,
  handleChangeFilters,
  filters,
  setGroups,
  setLoadingMarking,
  loadingMarking,
  search,
  data
}) => {
  const allIds = insightsGroups.flat().map((n) => n.id)

  const getTitle = useCallback(
    (index: number) => {
      const defaultSortByDate =
        filters.sortDirection === 'DESC'
          ? index === 0
            ? 'This Week'
            : index === 1
            ? 'Last Week'
            : index === 2
            ? 'This Month'
            : 'Earlier this year'
          : index === 3
          ? 'This Week'
          : index === 2
          ? 'Last Week'
          : index === 1
          ? 'This Month'
          : 'Earlier this year'

      if (!data || !data.length) return defaultSortByDate

      if (filters.sortBy === 'cpi_name') {
        const groups = unique(
          data.map((n) => (n.type === 'ssm_insight' ? 'No CPI' : n.data?.cpi_display_name || ''))
        ).sort()
        const grouped = filters.sortDirection === 'DESC' ? groups : groups.reverse()

        return grouped[index]
      }
      if (filters.sortBy === 'type') {
        const groups = unique(data.map((n) => n.data?.header || '')).sort()
        const grouped = filters.sortDirection === 'DESC' ? groups : groups.reverse()

        return grouped[index]
      }

      return defaultSortByDate
    },
    [data, filters]
  )

  const renderCards = useCallback(() => {
    return (
      <>
        {insightsGroups.map((group, index) =>
          group.length > 0 ? (
            <InsightGroupWrapper key={index} className="insight-group-wrapper">
              {!loadingMarking && loading ? (
                <HeaderLoadingSkeleton />
              ) : (
                <Typography className="group-header-title">{getTitle(index)}</Typography>
              )}

              <CardsGroup className="cards-group">
                {group.map((insight, i) =>
                  !loadingMarking && loading ? (
                    <CardLoadingSkeleton key={i} />
                  ) : (
                    <InsightsCard
                      search={search}
                      loadingMarking={loadingMarking}
                      key={insight.id}
                      insight={insight}
                      selected={insight.id === selectedInsight.id}
                      handleClickInsight={handleClickInsight}
                    />
                  )
                )}
              </CardsGroup>
            </InsightGroupWrapper>
          ) : null
        )}
      </>
    )
  }, [insightsGroups, selectedInsight, loadingMarking, loading])

  return (
    <Wrapper className="insights-list">
      <ListToolbar
        markMenuOptions={['Mark All as Read', 'Mark All as Unread']}
        changeReadStatus={changeReadStatus}
        allIds={allIds}
        selectedInsight={selectedInsight}
        setSelectedInsight={setSelectedInsight}
        handleChangeFilters={handleChangeFilters}
        filters={filters}
        numItems={insightsGroups.flat().length}
        setGroups={setGroups}
        insightsGroups={insightsGroups}
        setLoadingMarking={setLoadingMarking}
      />

      {renderCards()}
      {/*      {filters.sortDirection === 'DESC'
        ? insightsGroups.map((group, index) =>
            group.length > 0 ? (
              <InsightGroupWrapper key={index} className="insight-group-wrapper">
                {renderHeader(group)}
                <CardsGroup className="cards-group">
                  {group.map((insight, i) =>
                    !loadingMarking && loading ? (
                      <CardLoadingSkeleton key={i} />
                    ) : (
                      <InsightsCard
                        search={search}
                        loadingMarking={loadingMarking}
                        key={insight.id}
                        insight={insight}
                        selected={insight.id === selectedInsight.id}
                        handleClickInsight={handleClickInsight}
                      />
                    )
                  )}
                </CardsGroup>
              </InsightGroupWrapper>
            ) : null
          )
        : insightsGroups.reverse().map((group, index) =>
            group.length > 0 ? (
              <InsightGroupWrapper key={index} className="insight-group-wrapper">
                {renderHeader(group)}
                <CardsGroup className="cards-group">
                  {group.map((insight, i) =>
                    !loadingMarking && loading ? (
                      <CardLoadingSkeleton key={i} />
                    ) : (
                      <InsightsCard
                        search={search}
                        loadingMarking={loadingMarking}
                        key={insight.id}
                        insight={insight}
                        selected={insight.id === selectedInsight.id}
                        handleClickInsight={handleClickInsight}
                      />
                    )
                  )}
                </CardsGroup>
              </InsightGroupWrapper>
            ) : null
          )} */}
    </Wrapper>
  )
}

export default InsightsList
