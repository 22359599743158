import { Autocomplete } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 300px;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  border: 1px solid #998d96;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #d0c3cc;

  @media (max-width: 1330px) {
    min-width: 270px;
  }

  &.Mui-focused {
    border: 1px solid #ffa9fc;
  }

  .MuiOutlinedInput-root {
    padding: 0 16px;
    height: 40px;
    color: #e3e3e3;

    .MuiAutocomplete-endAdornment {
      top: 1px;

      .MuiButtonBase-root {
        padding: 6px;
        visibility: visible;

        &:hover {
          background: rgba(202, 196, 208, 0.08);
        }

        svg {
          path {
            fill: #d0c3cc;
          }
        }
      }
    }

    .MuiOutlinedInput-input {
      padding-left: 10px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      letter-spacing: 0.1px;

      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::placeholder {
        opacity: 1;
        overflow: hidden;
        color: #867083;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &::placeholder {
      opacity: 1;
    }

    fieldset {
      border: none;
    }
  }
`
