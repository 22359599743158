import React from 'react'

const TrendsFilterIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.702 12.5H26.202L19.9395 20.375L13.702 12.5ZM10.2645 12.0125C12.7895 15.25 17.452 21.25 17.452 21.25V28.75C17.452 29.4375 18.0145 30 18.702 30H21.202C21.8895 30 22.452 29.4375 22.452 28.75V21.25C22.452 21.25 27.102 15.25 29.627 12.0125C30.2645 11.1875 29.677 10 28.6395 10H11.252C10.2145 10 9.627 11.1875 10.2645 12.0125Z"
      fill="white"
    />
  </svg>
)

export default TrendsFilterIcon
