import { FC } from 'react'
import { ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'
import { AuditLogFormControl, AuditLogSelect, CategoryMenuProps } from './timePickerCustomSelectField.styles'
import { SelectDropdownIcon } from '../../../components/svg'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  noborder?: string
  labeltext?: string
  disabled: boolean
  handleCustomClick?: () => void
  anchorEl: any
  setAnchorEl: any
  closeCustomPicker: any
  customTimePeriodValue: string
  hideSelected?: boolean
  maxWidth?: string
}

const TrendsChartCustomSelectField: FC<Props> = ({
  options,
  labeltext,
  handleChange,
  placeholder,
  noborder,
  minWidth,
  value,
  disabled,
  handleCustomClick,
  anchorEl,
  setAnchorEl,
  closeCustomPicker,
  customTimePeriodValue,
  hideSelected,
  maxWidth,
  ...rest
}) => {
  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  return (
    <AuditLogFormControl
      sx={{ minWidth, maxWidth: maxWidth || "unset" }}
      isselected={
        hideSelected
          ? 'false'
          : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
          ? 'true'
          : 'false'
      }
      is_opened={anchorEl ? 'true' : 'false'}
      is_disabled={disabled ? 'true' : 'false'}
    >
      <AuditLogSelect
        noborder={noborder}
        isselected={
          hideSelected
            ? 'false'
            : placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== ''))
            ? 'true'
            : 'false'
        }
        labelId="library-select-label"
        id="library-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: { maxHeight: 'unset', display: anchorEl ? 'block' : 'none' }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon.MuiSelect-iconOutlined': {
            transform: anchorEl ? 'rotate(180deg) !important' : 'rotate(0deg) !important'
          }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (customTimePeriodValue) return customTimePeriodValue
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          if (!selected) return labeltext
          if (labeltext) {
            return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
          }
          return typeof noborder !== 'undefined' ? `Sort by: ${selected}` : selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput onMouseDown={handleClick} />}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
                closeCustomPicker()
              }
            }}
            selected={option === value}
            disableRipple
          >
            <ListItemText primary={option} />
          </MenuItem>
        ))}
        {/* Custom Picker Option */}
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',

            '.MuiListItemText-root': {
              width: 'fit-content',
              maxWidth: '60px',

              '.MuiTypography-root': {
                width: 'fit-content'
              }
            },

            svg: {
              marginLeft: '8px'
            }
          }}
          onClick={handleCustomClick}
        >
          <ListItemText primary="Custom..." />
          {/*          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17L15 12L10 7V17Z" fill="#D0C3CC" />
          </svg> */}
        </MenuItem>
      </AuditLogSelect>
    </AuditLogFormControl>
  )
}

export default TrendsChartCustomSelectField
