import { FC } from 'react'
import { Typography, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'

/* Components */
import { Wrapper } from './unreadInsights.styles'

interface Props {
  value: number
}

const UnreadInsights: FC<Props> = ({ value }) => (
  <Wrapper>
    <Typography className="text">You have {value} unread insights!</Typography>
    <MuiLink component={Link} to="/onyxai-insights" className="link-text">
      read now
    </MuiLink>
  </Wrapper>
)

export default UnreadInsights
