import React, { FC, ReactElement } from 'react'
import { Wrapper } from './ContentWrapper.styles'

interface Props {
  children?: ReactElement
  line: number
  paddingBottom?: number
  marginTop?: number
  scrollHeight?: number
  normalHeight?: string
  sx?: Record<string, unknown>
}

const ContentWrapper: FC<Props> = ({ children, sx, normalHeight, line, paddingBottom, scrollHeight, marginTop }) => {
  return (
    <Wrapper
      line={line}
      marginTop={marginTop}
      paddingBottom={paddingBottom}
      scrollHeight={scrollHeight}
      normalHeight={normalHeight}
      sx={sx}
      className="content-wrapper"
    >
      {children}
    </Wrapper>
  )
}

export default ContentWrapper
