import { FC, MouseEvent } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

/* Components */
import { MarkMenu } from '../listToolbar.styles'

interface Props {
  markMenuOpen: boolean
  anchorEl: null | HTMLElement
  handleClick: (event: MouseEvent<HTMLElement>) => void
  handleClose: () => void
  markMenuOptions: string[]
  handleMarkMenuOption: (option: string) => void
}

const MarkReadMenu: FC<Props> = ({
  markMenuOptions,
  markMenuOpen,
  handleMarkMenuOption,
  handleClick,
  handleClose,
  anchorEl
}) => {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={markMenuOpen ? 'long-menu' : undefined}
        aria-expanded={markMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: markMenuOpen ? '#D9BFD4' : '#FFA9FC',
          background: markMenuOpen ? '#590060' : '',
          '&:hover': {
            background: '#EC85EC',

            svg: {
              path: {
                fill: '#590060'
              }
            }
          }
        }}
      >
        <MoreVertIcon
          sx={{
            path: {
              fill: markMenuOpen ? '#fff' : '#FFA9FC'
            }
          }}
        />
      </IconButton>
      <MarkMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={markMenuOpen}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            marginTop: '5px',
            borderRadius: '4px',
            width: '200px',
            maxHeight: '98px',
            border: '1px solid #4D444B',
            background: '#372A36',
            padding: 0,

            '.MuiList-root': {
              padding: '8px',

              '.MuiMenuItem-root': {
                borderRadius: '4px',
                height: '40px',
                overflow: 'hidden',
                color: '#FFF',
                textOverflow: 'ellipsis',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                padding: '0 8px',

                '&:hover': {
                  background: '#573F56'
                }
              }
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {markMenuOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleMarkMenuOption(option)}>
            {option}
          </MenuItem>
        ))}
      </MarkMenu>
    </div>
  )
}

export default MarkReadMenu
