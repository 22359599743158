import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  position: sticky;
  top: 0px;
  z-index: 1;
  //background: #573f56;
  padding-top: 16px;
  padding-bottom: 16px;

  .search-icon {
    position: absolute;
    left: 35px;
    top: 26px;
    z-index: 1;
  }

  .clear-btn {
    position: absolute;
    right: 20px;
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border: 1px solid #998d96;
    border-radius: 28px;
    background: #372a36;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
    color: #d0c3cc;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    &::placeholder {
      color: #e9e0e4;
      opacity: 1;
    }

    &:focus {
      border-color: #ffa9fc;
    }
  }

  fieldset {
    border: none;
  }
`
