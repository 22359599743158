import { Dialog as MuiDialog } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Dialog = styled(MuiDialog)`
  width: 100%;
  height: 100%;

  .MuiDialog-container {
    .MuiPaper-root {
      height: 480px;
      background: #292128;
      border-radius: 16px;

      .header {
        display: flex;
        align-items: center;
        height: 72px;
        padding: 16px 24px;
        gap: 8px;
        border-radius: 16px 16px 0 0;
        background: #372a36;

        .header-title {
          font-family: Quicksand, sans-serif;
          color: #ffa9fc;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .MuiDialogContent-root {
        padding: 24px;
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .content-header {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-family: Quicksand, sans-serif;
        }

        .content-description {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          font-family: Quicksand, sans-serif;
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 24px;
        border-radius: 0 0 16px 16px;
        background: #372a36;
        gap: 8px;
      }
    }
  }
`
