import * as Yup from 'yup'
import {
  CPIFrequency,
  CPIManifestUIDateRangeValue,
  CPITimePeriod,
  FrameworkCategoryOption,
  IntegrationInstance,
  IntegrationStatusMessage
} from '../../../models'

export const defaultLibraryCategoriesOnyxia: FrameworkCategoryOption[] = [
  {
    title: 'Detection & Response',
    id: 'detection-and-response'
  },
  {
    title: 'Vulnerability Management',
    id: 'vulnerability-management'
  },
  {
    title: 'Training & Awareness',
    id: 'training-and-awareness'
  },
  {
    title: 'Cloud Security',
    id: 'cloud-security'
  },
  {
    title: 'Identity & Access Management',
    id: 'iam'
  },
  {
    title: 'Detection & Response',
    id: 'detection-and-response'
  },
]

export const defaultLibraryCategoriesNIST: FrameworkCategoryOption[] = [
  {
    title: 'Identify',
    id: 'identify'
  },
  {
    title: 'Protect',
    id: 'protect'
  },
  {
    title: 'Detect',
    id: 'detect'
  },
  {
    title: 'Respond',
    id: 'respond'
  },
  {
    title: 'Recover',
    id: 'recover'
  }
]

export const defaultLibraryLabelDataSources = [
  {
    label: 'Crowdstrike',
    name: 'crowdstrike_spotlight'
  },
  {
    label: 'MS Sentinel',
    name: 'ms_sentinel'
  }
]

export const defaultLibraryStatusOptions = [
  {
    label: 'Active',
    name: 'active'
  },
  {
    label: 'Inactive',
    name: 'inactive'
  }
]

export const librarySortOptions = ['Favorites First', 'Newest to Oldest', 'Oldest to Newest']

export const getDataSourceAllOption = (length: number): IntegrationInstance => ({
  connection_id: '',
  created_at: '',
  created_by: '',
  description: '',
  id: '',
  integration_name: '',
  name: `All (${length})`,
  prefect_deployment_id: '',
  source_id: '',
  categories: [''],
  tenant_id: '',
  updated_at: '',
  updated_by: '',
  syncStatus: { status: IntegrationStatusMessage.success, errorMessage: undefined }
})

export const adjustTimePeriodValues = (defaultTimePeriodValues: CPIManifestUIDateRangeValue[]) =>
  defaultTimePeriodValues.map((n) =>
    n.name === '1w'
      ? CPITimePeriod.Week
      : n.name === '1m'
      ? CPITimePeriod.Month
      : n.name === '3m'
      ? CPITimePeriod.ThreeMonths
      : n.name === '6m'
      ? CPITimePeriod.SixMonths
      : CPITimePeriod.PastYear
  )

export const adjustDefaultTimePeriod = (defaultTimePeriod: string, defaultFrequencyValue: CPIFrequency) => {
  switch (defaultTimePeriod) {
    case '1m':
      if (defaultFrequencyValue === CPIFrequency.Monthly) {
        return CPITimePeriod.ThreeMonths
      } else {
        return CPITimePeriod.Month
      }
    case '3m':
      return CPITimePeriod.ThreeMonths
    case '6m':
      return CPITimePeriod.SixMonths
    case '1y':
      return CPITimePeriod.PastYear
    case '1w':
    default:
      return CPITimePeriod.Week
  }
}

export const defaultTimePeriodValues: Array<CPITimePeriod> = [
  CPITimePeriod.Week,
  CPITimePeriod.Month,
  CPITimePeriod.ThreeMonths,
  CPITimePeriod.SixMonths,
  CPITimePeriod.PastYear,
  CPITimePeriod.Custom
]

export const defaultCategoriesTimePeriodValues: Array<CPITimePeriod> = [
  CPITimePeriod.Month,
  CPITimePeriod.ThreeMonths,
  CPITimePeriod.SixMonths,
  CPITimePeriod.PastYear
]

export const defaultFrequencyValues: Array<CPIFrequency> = [
  CPIFrequency.Daily,
  CPIFrequency.Weekly,
  CPIFrequency.Monthly
]

export const INITIALIZING_TIMER = 1000 * 10
export const RECALCULATING_TIMER = 1000 * 10
export const DEFAULT_TIMER = 1000 * 10 * 60

export interface FormValues {
  all: string
  critical: string
  high: string
  medium: string
  low: string
  cpiCategoryValue: string
}

export const initialValues = {
  all: '24',
  critical: '6',
  high: '12',
  medium: '24',
  low: '48',
  sla: '15',
  cpiCategoryValue: ''
}

const integerMessage = 'Numbers with decimal format are not allowed!'

export const schema = Yup.object({
  all: Yup.number().integer(integerMessage).optional(),
  critical: Yup.number().integer(integerMessage).optional(),
  high: Yup.number().integer(integerMessage).optional(),
  medium: Yup.number().integer(integerMessage).optional(),
  low: Yup.number().integer(integerMessage).optional(),
  sla: Yup.number().integer(integerMessage).optional(),
  cpiCategoryValue: Yup.string().optional()
})
