import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const IntelFeedIcon: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_2313_10082)">
      <path
        d="M10 6.36364C7.99091 6.36364 6.36364 7.99091 6.36364 10C6.36364 12.0091 7.99091 13.6364 10 13.6364C12.0091 13.6364 13.6364 12.0091 13.6364 10C13.6364 7.99091 12.0091 6.36364 10 6.36364ZM18.1273 9.09091C17.7091 5.3 14.7 2.29091 10.9091 1.87273V0H9.09091V1.87273C5.3 2.29091 2.29091 5.3 1.87273 9.09091H0V10.9091H1.87273C2.29091 14.7 5.3 17.7091 9.09091 18.1273V20H10.9091V18.1273C14.7 17.7091 17.7091 14.7 18.1273 10.9091H20V9.09091H18.1273ZM10 16.3636C6.48182 16.3636 3.63636 13.5182 3.63636 10C3.63636 6.48182 6.48182 3.63636 10 3.63636C13.5182 3.63636 16.3636 6.48182 16.3636 10C16.3636 13.5182 13.5182 16.3636 10 16.3636Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2313_10082">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IntelFeedIcon
