import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ResetWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: #372a36;
  width: 100%;
  padding-bottom: 8px;
`

export const ResetButton = styled(Button)`
  border-radius: 100px;
  cursor: pointer;
  font-family: Quicksand, sans-serif;
  text-transform: none;
  padding: 10px 16px;
  width: 100%;
  height: 40px;
  background: transparent;
  border: 1px solid #998d96;
  opacity: 1;
  color: #ffa9fc;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;

  &:hover {
    background: rgba(234, 221, 255, 0.08);
  }

  &.Mui-disabled {
    color: #7f747c;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 510;
    opacity: 1;
    border: 1px solid transparent;
    background: #483646;
  }
`
