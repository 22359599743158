import { FC, MouseEvent, useState, Dispatch, SetStateAction } from 'react'
import { IconButton, Typography } from '@mui/material'

/* Components */
import { Wrapper } from './listToolbar.styles'
import MarkReadMenu from './list-toolbar-components/MarkReadMenu'
import SortMenu from './list-toolbar-components/SortMenu'
import { Insight, InsightSortBy } from '../../../../../models'
import { Filters } from '../../OnyxAIInsights'

const sortOptions = ['CPI', 'Date & Time', 'Insight Type']

interface Props {
  markMenuOptions: string[]
  changeReadStatus: (integrationIds: string[], read: boolean) => Promise<void>
  allIds: string[]
  selectedInsight: Insight
  setSelectedInsight: Dispatch<SetStateAction<Insight | null>>
  handleChangeFilters: (newValue: any, field: string) => void
  filters: Filters
  numItems: number
  setGroups: Dispatch<SetStateAction<Insight[][]>>
  insightsGroups: Insight[][]
  setLoadingMarking: Dispatch<SetStateAction<boolean>>
}

const ListToolbar: FC<Props> = ({
  markMenuOptions,
  changeReadStatus,
  allIds,
  selectedInsight,
  setSelectedInsight,
  handleChangeFilters,
  filters,
  numItems,
  setGroups,
  insightsGroups,
  setLoadingMarking
}) => {
  const [anchorElMark, setAnchorElMark] = useState<null | HTMLElement>(null)
  const [anchorElSort, setAnchorElSort] = useState<null | HTMLElement>(null)
  const sortMenuOpen = Boolean(anchorElSort)
  const markMenuOpen = Boolean(anchorElMark)

  const handleClickMark = (event: MouseEvent<HTMLElement>) => {
    setAnchorElMark(event.currentTarget)
  }

  const handleCloseMark = () => {
    setAnchorElMark(null)
  }

  const handleClickSort = (event: MouseEvent<HTMLElement>) => {
    setAnchorElSort(event.currentTarget)
  }

  const handleCloseSort = (option: string) => {
    if (option.length > 0) {
      const value = option === 'CPI' ? 'cpi_name' : option === 'Date & Time' ? 'insight_date' : 'type'
      handleChangeFilters(value as InsightSortBy, 'sortBy')
    }
    setAnchorElSort(null)
  }

  const handleMarkMenuOption = async (option: string) => {
    setLoadingMarking(true)
    const readValue = !option.includes('Unread')
    const prevInsight = { ...selectedInsight }
    const prevGroups = [...insightsGroups]
    setSelectedInsight((pre: any) => ({ ...pre, read: readValue }))
    setGroups(insightsGroups.map((c) => c.map((n) => ({ ...n, read: readValue }))))
    handleCloseMark()

    try {
      await changeReadStatus(allIds, readValue)
    } catch (err) {
      console.error(err)
      setSelectedInsight(prevInsight)
      setGroups(prevGroups)
    } finally {
      setLoadingMarking(false)
    }
  }

  return (
    <Wrapper>
      <SortMenu
        handleClose={handleCloseSort}
        handleClick={handleClickSort}
        markMenuOpen={sortMenuOpen}
        anchorEl={anchorElSort}
        markMenuOptions={sortOptions}
        selectedOption={
          filters.sortBy === 'cpi_name' ? 'CPI' : filters.sortBy === 'insight_date' ? 'Date & Time' : 'Insight Type'
        }
      />
      <IconButton
        className={`sort-icon-button ${filters.sortDirection === 'ASC' ? 'sort-asc' : 'sort-desc'}`}
        onClick={() => handleChangeFilters(filters.sortDirection === 'ASC' ? 'DESC' : 'ASC', 'sortDirection')}
        sx={{
          '&:hover': {
            background: '#EC85EC',

            svg: {
              path: {
                fill: '#590060'
              }
            }
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M22 12L20.2375 10.2375L13.25 17.2125V2H10.75V17.2125L3.775 10.225L2 12L12 22L22 12Z"
            fill="#FFA9FC"
          />
        </svg>
      </IconButton>
      <Typography className="count-text">
        {numItems} item{numItems !== 1 && 's'}
      </Typography>
      <MarkReadMenu
        handleClose={handleCloseMark}
        handleClick={handleClickMark}
        markMenuOpen={markMenuOpen}
        anchorEl={anchorElMark}
        markMenuOptions={markMenuOptions}
        handleMarkMenuOption={handleMarkMenuOption}
      />
    </Wrapper>
  )
}

export default ListToolbar
