import { FC, useEffect, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

/* Utils */
import {
  CategoryFramework,
  CPILibraryItem,
  CybersecurityFramework,
  IntegrationStatusMessage,
  LibraryFilters,
  PerformanceScoreCategoryDetailsDataSources
} from '../../../../models'
import { formatCPIDisplayNameForURL } from '../../../../lib/utils'
import { ErroredInstance } from '../Library'

/* Components */
import {
  LibraryCardWrapper,
  LibraryFooter,
  LibraryFooterCategory,
  LibraryFooterCustomCategory,
  LibraryFooterLogo
} from './library-components.styles'
import { ThemeButton } from '../../../components/buttons'
import { FavoriteCPIIcon, NewIntegrationIcon, NotFavoriteCPIIcon, RightIconButton } from '../../../components/svg'
import { LibraryDetailsHoverTooltip } from '../library-details/components/LibraryDetailsTooltip'
import NistFrameworkCategory from '../../../components/nist-framework-category'
import { PotentialSourcesTooltip } from './PotentialSourcesTooltip'
import DataSourceErrorTooltip from '../../../components/data-source-error-tooltip'
import CpiActiveButton from '../../../components/cpi-active-button'

interface Props {
  item: CPILibraryItem
  handleChangeFavorite: (name: string, isFavorite: boolean) => void
  active: boolean
  loading: boolean
  filters: LibraryFilters
  erroredInstances: ErroredInstance[]
  customColors?: any
  framework?: CategoryFramework
}

const LibraryCard: FC<Props> = ({
  item,
  customColors,
  erroredInstances,
  handleChangeFavorite,
  active,
  loading,
  filters,
  framework
}) => {
  const navigate = useNavigate()
  const [favorite, setFavorite] = useState(item.favorite)

  useEffect(() => {
    if (!loading) {
      setFavorite(item.favorite)
    }
  }, [loading, item.favorite])

  const getIntegrationLogo = (name: string) => {
    if (name) {
      return `/icons/rounded/${name}`
    }

    return '/icons/rounded/crowdstrike_falcon_icon.svg'
  }

  const handleClick = (cpiDisplayName: string) => {
    localStorage.setItem('tempFilters', JSON.stringify(filters))
    const path = `/library/cpis/${formatCPIDisplayNameForURL(cpiDisplayName)}`

    if (active) {
      navigate(path)
    } else {
      navigate(path, { state: { openConfig: false } })
    }
  }

  const isCpiNew = () => {
    const a = moment(item.published_at)
    const b = moment()
    const diff = Math.abs(a.diff(b, 'days')) + 1

    return diff < 30
  }

  const renderActiveSources = () => {
    const integrations: PerformanceScoreCategoryDetailsDataSources[] = item.configuration?.dataSources || []
    if (!integrations.length) return null

    const visibleIntegrations = integrations.length > 3 ? integrations.slice(0, 3) : integrations
    const notVisibleIntegrations = integrations.length > 3 ? integrations.slice(3) : []

    return (
      <Box className="data-sources">
        <Typography>Active Data Sources: </Typography>
        <Box className="data-sources-wrapper">
          {visibleIntegrations.map(
            ({ logo_path: logoPath, display_name: displayName, integrationConfig }, index: number) => {
              const erroredInstance =
                erroredInstances.length > 0
                  ? erroredInstances.find((n) => n?.id === integrationConfig?.id || '')
                  : undefined
              if (erroredInstance && erroredInstance.status === IntegrationStatusMessage.failed) {
                return (
                  <DataSourceErrorTooltip
                    key={index}
                    displayName={displayName}
                    errorMessage={erroredInstance.errorMessage || ''}
                    logoPath={logoPath}
                    instanceName={integrationConfig.integration_name}
                    id={erroredInstance.id}
                    logoSize={22}
                    hideLink
                  />
                )
              }
              return (
                <LibraryDetailsHoverTooltip key={index} title={integrationConfig?.name || ''} arrow>
                  <LibraryFooterLogo logo={getIntegrationLogo(logoPath)} />
                </LibraryDetailsHoverTooltip>
              )
            }
          )}
          {notVisibleIntegrations.length > 0 && (
            <PotentialSourcesTooltip
              title={
                <>
                  <Box className="tooltip-list">
                    <Typography className="tooltip-header">Potential Data Sources</Typography>
                    {integrations.map((n, i) => (
                      <Box className="tooltip-wrapper" key={i}>
                        <img src={`/icons/circle/${n.logo_path}`} alt="" width={24} height={24} />
                        <Typography className="tooltip-title">{n.integrationConfig.name || n.display_name}</Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              }
            >
              <Typography className="data-sources-count-text">+ {notVisibleIntegrations.length}</Typography>
            </PotentialSourcesTooltip>
          )}
        </Box>
      </Box>
    )
  }

  const renderPotentialSources = () => {
    if (item.configuration) return null

    const integrations = item.supported_integrations
    const visibleIntegrations = integrations.length > 3 ? integrations.slice(0, 3) : integrations
    const notVisibleIntegrations = integrations.length > 3 ? integrations.slice(3) : []

    return (
      <Box className="data-sources-wrapper">
        {visibleIntegrations.map((n) => (
          <PotentialSourcesTooltip
            key={n.display_name}
            title={
              <>
                <Box className="tooltip-list">
                  <Typography className="tooltip-header">Potential Data Sources</Typography>
                  {integrations.map((n) => (
                    <Box className="tooltip-wrapper" key={n.logo_url}>
                      <img src={`/icons/circle/${n.logo_url}`} alt="" width={24} height={24} />
                      <Typography className="tooltip-title">{n.display_name}</Typography>
                    </Box>
                  ))}
                </Box>
              </>
            }
          >
            <LibraryFooterLogo logo={getIntegrationLogo(n.logo_url)} />
          </PotentialSourcesTooltip>
        ))}
        {notVisibleIntegrations.length > 0 && (
          <PotentialSourcesTooltip
            title={
              <>
                <Box className="tooltip-list">
                  <Typography className="tooltip-header">Potential Data Sources</Typography>
                  {integrations.map((n) => (
                    <Box className="tooltip-wrapper" key={n.logo_url}>
                      <img src={`/icons/circle/${n.logo_url}`} alt="" width={24} height={24} />
                      <Typography className="tooltip-title">{n.display_name}</Typography>
                    </Box>
                  ))}
                </Box>
              </>
            }
          >
            <Typography className="data-sources-count-text">+ {notVisibleIntegrations.length}</Typography>
          </PotentialSourcesTooltip>
        )}
      </Box>
    )
  }

  return (
    <LibraryCardWrapper onClick={() => handleClick(item.display_name)}>
      <Box className="favorite-icon">
        <IconButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setFavorite(!favorite)
            handleChangeFavorite(item.name, favorite)
          }}
        >
          {favorite ? <FavoriteCPIIcon /> : <NotFavoriteCPIIcon />}
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <Box className="cpi-name">
          <Typography>{item.display_name}</Typography>
        </Box>

        {isCpiNew() && (
          <Box className="cpi-new">
            <NewIntegrationIcon />
            <Typography>NEW</Typography>
          </Box>
        )}
      </Box>

      <Box className="title-wrapper">
        <Typography className="title">{item.title}</Typography>{' '}
      </Box>
      <Typography className="text"> {item.short_description}</Typography>
      <LibraryFooter>
        <Box className="left-footer">
          <CpiActiveButton active={!!item.configuration} />

          {framework === CategoryFramework.NIST ? (
            <NistFrameworkCategory
              categoryName={item.category?.title}
              categoryId={item.category?.id}
              cpiName={item.display_name}
              onlyFunction
            />
          ) : framework === CategoryFramework.Onyxia ? (
            <LibraryFooterCategory category={item.category?.id}>
              <Typography>{item.category?.title}</Typography>
            </LibraryFooterCategory>
          ) : (
            <>
              {customColors && item.category && (
                <LibraryFooterCustomCategory bgColor={customColors.chip_bg_color} textColor={customColors.chip_color}>
                  <Typography>{item.category.title}</Typography>
                </LibraryFooterCustomCategory>
              )}
            </>
          )}

          {item.configuration ? (
            renderActiveSources()
          ) : (
            <Box className="data-sources">
              <Typography>Potential Data Sources: </Typography>
              {renderPotentialSources()}
            </Box>
          )}
          {item.cybersecurity_frameworks.length && item.cybersecurity_frameworks[0].display_name !== 'Gartner' ? (
            <Box className="framework-panel">
              {item.cybersecurity_frameworks.map((framework: CybersecurityFramework) => {
                if (framework.display_name === 'Gartner') return null
                return (
                  <LibraryDetailsHoverTooltip key={framework.name} title={framework.metric_display_name} arrow>
                    <img src={`/${framework.logo_path}`} alt={framework.name} />
                  </LibraryDetailsHoverTooltip>
                )
              })}
            </Box>
          ) : null}
        </Box>

        <Box className="actions-wrapper">
          <ThemeButton themevariant="secondary">
            <>
              <RightIconButton />
              View Details
            </>
          </ThemeButton>
        </Box>
      </LibraryFooter>
    </LibraryCardWrapper>
  )
}

export default LibraryCard
