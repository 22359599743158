import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)<{
  line?: number
  paddingBottom?: number
  marginTop?: number
  scrollHeight?: number
  normalHeight?: string
}>`
  height: ${({ line, normalHeight, scrollHeight }) =>
    normalHeight === 'true'
      ? '100vh'
      : line === 1
      ? 'calc(100vh - 100px)'
      : scrollHeight
      ? `calc(100vh - ${scrollHeight}px - 40px)`
      : 'calc(100vh - 200px)'};
  padding-bottom: ${({ paddingBottom }) => (typeof paddingBottom !== 'undefined' ? `${paddingBottom}px` : '40px')};
  //overflow-y: auto;
  //overflow-x: hidden;
  overflow: hidden auto;
  //overflow: hidden;
  //position: relative;
  //overflow: visible;

  ${({ normalHeight }) =>
    normalHeight === 'true'
      ? `
    margin-top: 80px;
    `
      : null};

  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px !important` : '0')};

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: #4d444b;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }

  .month-picker-container {
    position: absolute;
    top: 70px;
    left: 321px;
    //top: -90px;
    //left: 1000px;

    z-index: 10000;
    width: 420px;
    display: none;
    border-radius: 4px 4px 0 0;
    background: #372a36;
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: 16px 12px 4px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 207.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }
    }

    p {
      color: #d0c3cc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      border-bottom: 1px solid #614964;

      > div:first-of-type {
        border-right: 1px solid #614964;
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: #ffa9fc;
        color: #590060;
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }
`
