import React, { FC } from 'react'
import { ResetWrapper } from './styles/resetSearch.styles'
import { ThemeButton } from '../buttons'

interface Props {
  resetData: () => void
  disabled?: boolean
}

const ResetSearch: FC<Props> = ({ resetData, disabled }) => (
  <ResetWrapper className="reset-wrapper">
    <ThemeButton themevariant="secondary" onClick={() => resetData()} disabled={disabled} className="reset-button">
      Reset
    </ThemeButton>
  </ResetWrapper>
)

export default ResetSearch
