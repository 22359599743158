import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #131013;
`

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-li {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-transform: capitalize;
  }

  .MuiBreadcrumbs-separator {
    color: #ffa9fc;
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const Content = styled(Box)`
  display: flex;
  padding: 24px;
  width: 100%;
  flex-basis: 100%;
  gap: 24px;
`

export const SectionDivider = styled(Box)<{ height: string }>`
  background: #4d444b;
  width: 1px;
  height: ${({ height }) => height};
  align-self: flex-end;
  margin-left: 10px;
  margin-top: 50px;
`

export const paperPropsSx = {
  sx: {
    background: '#372A36',
    border: '1px solid #4D444B',
    borderRadius: '4px',
    padding: '8px',
    maxHeight: 40 * 4.5,
    marginTop: '4px',
    width: '225px',
    boxSizing: 'border-box',
    height: '56px',
    overflowY: 'hidden',

    '.MuiList-root': {
      padding: 0,

      '.MuiMenuItem-root': {
        height: '40px',
        background: 'transparent',

        '&:hover': {
          background: '#573F56'
        },

        '&.disabled-menu': {
          color: '#867083',

          '&:hover': {
            cursor: 'default',
            background: 'transparent'
          }
        },

        borderRadius: '4px',
        overflow: 'hidden',
        color: '#FFF',
        textOverflow: 'ellipsis',
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
      }
    }
  }
}
