import { FC, ReactElement } from 'react'
import { HeaderBreadcrumbsWrapper, Wrapper } from './HeaderComponent.styles'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ChevronRight from '../svg/ChevronRight'

interface breadCrumbs {
  name: string
  link?: string
}

interface Props {
  type?: string
  noBg?: boolean
  breadcrumbs: breadCrumbs[]
  children?: ReactElement
  sx?: Record<string, unknown>
  borderBottom?: boolean
}

const InsightsCustomHeader: FC<Props> = ({ breadcrumbs, children, borderBottom, sx, type, noBg }) => {
  return (
    <Wrapper
      type={type}
      noBg={noBg}
      className="header-container"
      sx={sx}
      borderbottom={borderBottom ? 'true' : 'false'}
    >
      <HeaderBreadcrumbsWrapper>
        {breadcrumbs.map((item, index) => {
          return (
            <Box key={index}>
              {item.link ? (
                <Box className="link-block">
                  <MuiLink component={Link} color="inherit" to={item.link} className="text link">
                    {item.name}
                  </MuiLink>
                  <ChevronRight />
                </Box>
              ) : (
                <Typography className="text">{item.name}</Typography>
              )}
            </Box>
          )
        })}
      </HeaderBreadcrumbsWrapper>

      <Box>{children}</Box>
    </Wrapper>
  )
}

export default InsightsCustomHeader
