import {
  ListItemButton as MuiListItemButton,
  Collapse as MuiCollapse,
  Box,
  ListItemText as MuiListItemText,
  List as MuiList
} from '@mui/material'
import styled from '@emotion/styled/macro'

export const ListItemText = styled(MuiListItemText)<{ fs?: string }>`
  .MuiTypography-root {
    font-size: ${({ fs }) => fs} !important;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #e9e0e4;
  }
`

export const Chip = styled(Box)<{ isNew: boolean }>`
  position: absolute;
  right: ${({ isNew }) => (isNew ? '16px' : '8px')};
  top: auto;
  padding: 2px 8px 2px 6px;
  border-radius: 18px;
  background: #3754fc;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 6px;
  opacity: 1;
  animation: cssAnimation 0.2s;

  .chip-title {
    color: #fff !important;
    font-family: Quicksand, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    letter-spacing: 0.5px !important;
  }

  @keyframes cssAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const LogoWrapper = styled(Box)`
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 64px;
  padding-left: 4px;

  .onyxia-logo-wrapper {
    margin-top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .expanded-logo-wrapper {
    padding-left: 20px;
    flex-grow: 1;
    display: flex;
    width: 100%;

    .onyxia-logo-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }
  }
`

export const MenuItem = styled(Box)<{ selected?: boolean; isUrlExists: boolean }>`
  width: 100%;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: ${({ isUrlExists }) => (isUrlExists ? 'pointer' : 'not-allowed')};
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid transparent;

    &:hover {
      background-color: #573f56;

      .MuiTypography-root.list-item-text {
        color: #d0c3cc;
      }
    }

    .arrow-icon {
      &.no-rotate {
        transform: rotate(90deg);
        margin-right: 6px;
      }

      &.rotate {
        transform: rotate(-90deg);
      }
    }
  }

  .MuiTypography-root.list-item-text {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;

    &.user-profile-name {
      margin-left: 16px;
    }

    &.MuiLink-root {
      text-decoration: none;
    }
  }

  .MuiButtonBase-root {
    height: 40px;
    width: 40px;
    border: 1px solid transparent;
  }

  ${({ selected }) =>
    selected &&
    `
      .MuiTypography-root {
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #FBD8F5;
      }
      
      .MuiButtonBase-root {
        svg path {
          fill: #DEBDD9;
        }
      }
      
      .wrapper {
        border-radius: 100px;
        background-color: #573f56;
        border: 1px solid #998d96;
        
      &:hover {
        background-color: #573f56;
        border-radius: 100px;
      }
    }
  `};
`

export const SidebarLayout = styled(Box)`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: #292528;
  overflow-x: hidden;
  position: relative;
`

export const Drawer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #292528;
  z-index: 10001;
  opacity: 1;
  transition: width 0.2s ease-in-out;
  border-right: 1px solid #4d444b;
`

export const SidebarContainer = styled(Box)`
  display: flex;
  height: 100%;
  transition: width 0.2s ease-in-out;

  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: #292528;
  overflow-x: hidden;
  position: relative;
`

export const MainMenuWrapper = styled(Box)<{ isHome: boolean; isExpanded: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;

  ${({ isHome }) =>
    isHome &&
    `
   margin-top: 0px
  `};

  ${({ isExpanded }) =>
    isExpanded &&
    `
        align-items: flex-start;
    `}
`

export const List = styled(MuiList)`
  background: #292528;

  &.main-list {
    width: 100%;
  }

  &.footer-list {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }
`

export const ListItemButton = styled(MuiListItemButton)<{
  selected?: boolean
  isexpanded?: string
  hassubmenu?: string
  isProfile?: boolean
  isselected?: string
}>`
  position: relative;
  padding-right: 0;
  gap: 24px;
  border-radius: 100px;
  height: 40px;

  ${({ isProfile }) =>
    isProfile &&
    `
      gap: 16px;
  `}

  &:not(:first-of-type) {
    margin-top: 32px;
  }

  &.footer-list-item {
    margin: 0;

    &.footer-list-profile {
      padding-left: 0 !important;
      gap: 16px;
    }

    &:nth-of-type(1) {
      padding-left: 8px !important;

      ${({ isselected }) => {
        if (isselected && isselected === 'true') {
          return `
        padding-left: 5px !important;
        `
        }
      }};
    }

    &:nth-of-type(2) {
      padding-right: 0 !important;
    }

    &:first-of-type {
      margin-bottom: 32px;
    }

    &:last-of-type {
      margin-bottom: 24px;
      padding-right: 16px;
      padding-left: 0;
    }

    &:hover {
      background: #544152;
    }
  }

  .MuiListItemText-root {
    color: ${({ selected }) => (selected ? '#e9e0e4' : '#D0C3CC')};
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    .MuiTypography-root {
      color: ${({ selected }) => (selected ? '#e9e0e4' : '#D0C3CC')};
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      white-space: nowrap;
    }
  }

  .MuiListItemIcon-root {
    min-width: unset;
  }

  .arrow-icon {
    &.no-rotate {
      transform: rotate(90deg);
      margin-right: 6px;
    }

    &.rotate {
      transform: rotate(-90deg);
    }
  }

  ${({ selected, isexpanded }) =>
    selected &&
    (!isexpanded || (isexpanded && isexpanded === 'false')) &&
    `
        .MuiListItemIcon-root {
          border-radius: 50%;
          padding: 6px;
          margin-left: -4px;
          border: 1px solid #998D96;
          background: #573F56;
        }
    `};

  ${({ selected, isexpanded, hassubmenu }) =>
    selected &&
    isexpanded &&
    isexpanded === 'true' &&
    hassubmenu !== 'true' &&
    `
      border: 1px solid #998D96;
      border-radius: 100px;
      background: #544152 !important;
  `};
`

export const Collapse = styled(MuiCollapse)<{ selected?: boolean }>`
  transition: all 0.2s ease-in-out;

  &.footer-collapse {
    .MuiCollapse-wrapper {
      .MuiCollapse-wrapperInner {
        > .MuiList-root:first-of-type {
          margin-top: -16px;
        }
      }
    }
  }
`

export const SubList = styled(MuiList)<{ selected?: boolean; lgPadding?: string; parentselected: string }>`
  margin-top: 16px;
  padding-left: 22px;
  margin-left: 18px;
  margin-right: 6px;

  &.footer-sublist {
    margin-top: 0;
    margin-bottom: 16px;

    .MuiListItemButton-root {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.MuiList-root:first-of-type {
      margin-top: 16px;
    }

    .MuiListItemButton-root:first-of-type {
      margin-left: 2px !important;
    }

    &.profile-sublist {
      margin-left: 12px !important;
    }

    ${({ lgPadding }) =>
      lgPadding &&
      lgPadding === 'true' &&
      `
        padding: 0;
        margin-left: 12px !important;
      }
  `};
  }

  .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root {
    color: ${({ selected, parentselected }) => (selected || parentselected === 'true' ? '#e9e0e4' : '#D0C3CC')};
  }

  &:hover {
    background-color: #573f56;
    border-radius: 100px;
  }

  ${({ selected }) =>
    selected &&
    `
      border: 1px solid #998D96;
      border-radius: 100px;
      background: #544152;
  `};
`
