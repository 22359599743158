import { Box, Menu } from '@mui/material'
import styled from '@emotion/styled'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: -8px;
  right: 0;

  .count-text {
    color: #d9bfd4;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 8px;
  }

  .sort-icon-button {
    &.sort-asc {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const MarkMenu = styled(Menu)`
  .sort-menu-title {
    color: #867083;
    font-size: 10px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 9px;
  }
`
