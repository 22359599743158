import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const InstanceTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33px',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    background: '#362E35',
    boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.30)',
    margin: 0,
    // width: '99px'
    width: '100%'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',

    '.arrow-up': {
      position: 'absolute',
      top: '-20px',
      left: 'calc(50% - 8px)'
    }
  },
  '.tooltip-text': {
    color: '#FFF',
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    whiteSpace: 'nowrap'
  }
})
