import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`

export const Content = styled(Box)<{ isFiltersOpen?: string }>`
  display: flex;
  justify-content: center;
  height: calc(100vh - 100px);
  overflow-y: hidden;
  padding-bottom: 40px;
  width: 100%;
  flex-basis: 100%;
  transition: all 0.2s ease-in-out;

  ${({ isFiltersOpen }) =>
    isFiltersOpen &&
    isFiltersOpen === 'true' &&
    `
      height: calc(100vh - 188px);
    `};

  .insights-list-wrapper {
    width: 100%;
    flex-basis: 50%;
    padding: 16px 24px 0;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }
  }
`

export const NoResultsWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  .no-results-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .no-results-header {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .no-results-text {
      color: #d9bfd4;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .svg-wrapper {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 1;
  }
`

export const dateRangePickerPopperSx = {
  '.MuiPaper-root': {
    position: 'absolute',

    display: 'flex',
    background: 'transparent',

    '.MuiPickersToolbar-root': {
      display: 'none'
    },
    '.MuiPickersLayout-root': {
      borderRadius: '8px',
      background: '#573f56',
      display: 'flex',
      flexDirection: 'column',

      '.MuiPickersCalendarHeader-root': {
        '.MuiPickersCalendarHeader-labelContainer': {
          '.MuiPickersCalendarHeader-label': {
            color: '#d0c3cc',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px'
          }
        },

        '.MuiPickersArrowSwitcher-root': {
          '.MuiButtonBase-root': {
            color: '#d0c3cc',

            '&.Mui-disabled': {
              opacity: 0.26
            }
          }
        }
      },

      '.MuiDayCalendar-root': {
        '.MuiDayCalendar-header': {
          '.MuiTypography-root': {
            color: '#e9e0e4',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.5px'
          }
        },

        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              background: 'transparent !important'
            }
          },

          '.MuiDateRangePickerDay-root': {
            '.MuiButtonBase-root': {
              color: '#e9e0e4',
              textAlign: 'center',
              fontFamily: "'Quicksand', sans-serif",
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.25px',

              '&.Mui-selected': {
                borderRadius: '50%',
                color: '#590060',
                background: '#ffa9fc'
              },

              '&.Mui-disabled': {
                color: '#d0c3cc',
                opacity: 0.38
              }
            },

            '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: 'rgba(106, 83, 113, 1)'
            }
          }
        }
      },

      '.MuiDialogActions-root': {
        '.MuiButton-root': {
          color: '#ffa9fc',
          textAlign: 'center',
          fontFamily: "'Quicksand', sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.1px',

          '&:first-of-type': {
            textTransform: 'capitalize'
          }
        }
      }
    }
  }
}
