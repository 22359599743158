import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const LibraryDetailsInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #998d96',
    background: '#4d444b',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)'
  },
  '.tooltip-text': {
    color: '#E9E0E4',
    fontSize: '14px',
    fontFamily: "'Quicksand', sans-serif",
    lineHeight: '20px',
    letterSpacing: '0.25px'
  }
})

export const LibraryDetailsHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFA9FC'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#590060',
    letterSpacing: '0.1px',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15)',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: '#FFA9FC'
  }
})

export const AcaHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#998D96'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#fff',
    letterSpacing: '0.1px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15)',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: '#362E35'
  }
})
