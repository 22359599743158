import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const TableToolbarWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;

  .left-side {
    .date {
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: 16px;

    .count {
      color: #d9bfd4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .filter-icon-block {
    position: relative;
    display: flex;
    border-radius: 50%;
    background: transparent;
    border: 1px solid transparent;
    padding: 8px;

    .active-circle {
      position: absolute;
      top: 3px;
      right: 3px;
      background: #1e1a1d;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;

      .circle {
        width: 8px;
        height: 8px;
        background: #ffe082;
        border-radius: 50%;
      }
    }
  }
`
