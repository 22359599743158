import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper, ListDivider } from './onyxiaSelect.styles'
import ResetSearch from '../../../components/form-components/ResetSearch'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  iscustom?: string
  label?: string
  hideReset?: boolean
  maxWidth?: string
}

const OnyxiaSelect: FC<Props> = ({
  label,
  options,
  handleChange,
  iscustom,
  handleReset,
  placeholder,
  minWidth,
  value,
  hideReset,
  maxWidth,
  disabled,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    if (rest.multiple) {
      const pruned = value.filter((s: string) => s !== '')
      const option = options.find((n) => n.name === pruned[0])

      if (option) {
        if (pruned.length === 1) {
          return option.label
        }

        if (placeholder && pruned.length === 0) {
          return placeholder
        }

        return `${option.label} +${pruned.length - 1}`
      }
    } else {
      const option = options.find((option) => option.name === value)

      if (option) {
        return (option as any).label || placeholder
      }
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper className="onyxai-select-wrapper">
      {label && <Typography className="label-text">{label}</Typography>}
      <LibraryFormControl
        sx={{ minWidth: minWidth ? `${minWidth} !important` : '113px', maxWidth: maxWidth || 'unset' }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length > 0 && value[0] !== '') ? 'true' : 'false'}
        iscustom={iscustom}
        isdisabled={disabled ? 'true' : 'false'}
      >
        <LibrarySelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          disabled={disabled}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={(e) => {
            handleChange(e)
          }}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {options.map((option) => (
            <MenuItem
              disableRipple
              autoFocus={false}
              key={option.name}
              value={option.name}
              disabled={option.name === 'predictions'}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
              sx={{ position: 'relative' }}
            >
              {rest.multiple && (
                <Checkbox
                  checked={rest.multiple ? value.indexOf(option.name) > -1 : value === option.name}
                  icon={<InstanceUncheckedIcon />}
                  checkedIcon={<InstanceCheckedIcon />}
                />
              )}

              {option.icon && option.icon}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}

          {!hideReset && <ListDivider />}

          {!hideReset && (
            <ResetSearch resetData={handleReset} disabled={!value.length || (value.length === 1 && value[0] === '')} />
          )}
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default OnyxiaSelect
