import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  box-sizing: border-box;
  height: 260px;

  .actions-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .delete-button {
      padding: 10.5px 17px;
    }

    .disabled-delete-button {
      padding: 10.5px 17px;
      min-width: 91px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: #483646;
      cursor: default;
      color: #7f747c;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      white-space: nowrap;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: #7f747c;
        }
      }
    }
  }
`

export const FrameworkSelectCardContainer = styled(Box)<{ isSelected: string; isloading: string }>`
  border-radius: 8px;
  background: #372a36;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  max-width: 400px;
  min-width: 354px;
  width: 100%;
  box-sizing: border-box;
  height: 196px;

  &:hover {
    cursor: pointer;
    background: #573f56;
  }

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
      background: #573F56;
    `};

  ${({ isloading }) =>
    isloading === 'true' &&
    `
      &:hover {
        cursor: wait;
      }    
    `};
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .header-framework {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 8px;

    .header-title {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`

export const Content = styled(Box)`
  .content-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
