import { FC } from 'react'
import { Box } from '@mui/material'

/* Components */
import StubPerformanceScore from './components/StubPerformanceScore'
// import EnvironmentStats from '../EnvironmentStats'
import StubCPIsSection from './components/StubCPIsSection'
import StubFeedSection from './components/StubFeedSection'
import {
  DashboardContainer,
  DashboardWrapper,
  HeroSectionWrapper,
  Main,
  MainScrollSection
} from '../../dashboard.styles'
import StubBenchmarking from './components/StubBenchmarking'
import StubOverPerformance from './components/StubOverPerformance'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import { breadcrumbs } from '../../../../../lib/utils'
import InsightsWidget from '../InsightsWidget'

interface Props {
  feedHeight: number
}

const DashboardLoading: FC<Props> = ({ feedHeight }) => {
  return (
    <DashboardContainer>
      <HeaderComponent breadcrumbs={breadcrumbs.dashboard} type={'dashboard'} />

      <Main>
        <DashboardWrapper>
          <HeroSectionWrapper>
            <Box className="performance-score-section">
              <StubOverPerformance />
              <StubPerformanceScore />
            </Box>
            <InsightsWidget
              unreadInsightsCount={0}
              insightsCountLoading={false}
              insightsData={{
                performanceDegradation: 0,
                suggestedOptimization: 0,
                performanceImprovement: 0
              }}
              isLoading
            />
          </HeroSectionWrapper>

          <Box className="main-content-container">
            <Box className="main-scroll-wrapper">
              <MainScrollSection>
                <StubCPIsSection />
              </MainScrollSection>
            </Box>

            <StubBenchmarking />
          </Box>

          <StubFeedSection feedHeight={feedHeight} />
        </DashboardWrapper>
      </Main>
    </DashboardContainer>
  )
}

export default DashboardLoading
