/* eslint camelcase: 0 */
import { FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 0

export const AuditLogFormControl = styled(FormControl)<{ isselected?: string; is_opened: string; is_disabled: string }>`
  border-radius: 4px;
  min-width: 113px;
  background: transparent;
  border: 1px solid #998d96;
  height: 40px;
  z-index: 10;

  &:hover {
    border: 1px solid #ffa9fc;
    background: rgba(208, 188, 255, 0.16);
  }

  justify-content: center;

  .MuiOutlinedInput-root {
    background: transparent;
    border-color: transparent;
  }
`

export const AuditLogSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  background: transparent;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #998d96;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  height: 40px;

  .MuiOutlinedInput-root {
    height: 40px;
  }

  svg {
    top: calc(50% - 0.8em);
    right: 8px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      //color: rgba(0, 0, 0, 0.54);
      color: #fff;
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: #fff;
    }
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      marginTop: '4px',
      background: '#573F56',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.30), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      // minWidth: '167px',
      // width: '167px',
      minWidth: '250px !important',
      maxHeight: '486px !important',

      '.MuiList-root': {
        padding: '8px',
        background: '#372A36',
        borderRadius: '4px'
      },

      '.MuiMenuItem-root': {
        borderRadius: '4px',
        height: '40px',
        gap: '4px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: '#573F56'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#fff !important'
        },

        '&.Mui-selected': {
          pointerEvents: 'none',

          '.MuiListItemText-root .MuiTypography-root': {
            overflow: 'hidden',
            color: '#867083 !important',
            textOverflow: 'ellipsis',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },

        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        },

        '&.Mui-disabled': {
          opacity: 1,

          '.MuiCheckbox-root': {
            opacity: 0.38
          },

          svg: {}
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#3e2e3d'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
