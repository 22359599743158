import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { CategoryActivityItem } from '../../../../../models'
import { categoryToUrl } from '../../../library/utils'
import { dateFormat } from '../../../../../lib/utils'

/* Components */
import { CategoryActivityIcon } from '../../../../components/svg'
import { CPIsSectionHeader, ExploreCategoriesWrapper, LoadingWrapper } from '../../dashboard.styles'
import ExploreCategoryCard from '../../components/ExploreCategoryCard'
import Category from './components/Category'

interface Props {
  categories: CategoryActivityItem[]
  handleCpiRedirect: (category: CategoryActivityItem) => void
  initialLoad: boolean
  categoryOptions: string[]
}

const CategoryActivity: FC<Props> = ({ categories, handleCpiRedirect, initialLoad, categoryOptions }) => {
  const secondBreakpoint = useMediaQuery('(min-width:1920px)')
  const navigate = useNavigate()

  const handleCategoryClick = useCallback((category: string) => {
    const url = categoryToUrl(category)
    navigate(`/library/categories/${url}`)
  }, [])

  const getLastUpdate = () => {
    if (categories.length > 0) {
      const data = categories[0].data

      if (data?.length > 0) {
        const lastValue = categories[0].data[data.length - 1].xValue || ''
        const nextMonday = dayjs(lastValue).weekday(8)

        return dayjs(nextMonday).format(dateFormat.lastUpdate)
      }
    }

    return dayjs().format(dateFormat.lastUpdate)
  }

  return (
    <>
      {initialLoad && (
        <CPIsSectionHeader>
          <Box className="title-block">
            <CategoryActivityIcon />
            <Typography>Category Performance Score Trends</Typography>
          </Box>

          <Typography className="date">Last Update : {getLastUpdate()}</Typography>
        </CPIsSectionHeader>
      )}
      {!initialLoad && (
        <LoadingWrapper>
          <CircularProgress size="36px" color="primary" />
        </LoadingWrapper>
      )}
      {initialLoad && (
        <>
          {categories.length > 0 ? (
            <Box
              sx={{
                // overflowY: 'auto',
                height: '100%',
                // maxHeight: '400px',
                padding: '0 16px',
                margin: '0 -16px'
              }}
            >
              <Grid container spacing={3}>
                {categories.map((category) => (
                  <Grid item xs={secondBreakpoint ? 3 : 4} key={category.id}>
                    <Category
                      key={category.id}
                      category={category}
                      handleCategoryClick={handleCategoryClick}
                      handleCpiRedirect={handleCpiRedirect}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <ExploreCategoriesWrapper>
              {categoryOptions.map((category) => (
                <ExploreCategoryCard key={category} category={category} />
              ))}
            </ExploreCategoriesWrapper>
          )}
        </>
      )}
    </>
  )
}

export default CategoryActivity
