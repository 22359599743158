import { Dispatch, FC, ReactElement, SetStateAction } from 'react'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

/* Components */
import { HeaderBreadcrumbsWrapper, Wrapper } from '../../../components/header/HeaderComponent.styles'
import ChevronRight from '../../../components/svg/ChevronRight'
import InsightsHeader from './InsightsHeader'

interface breadCrumbs {
  name: string
  link?: string
}

interface Props {
  type?: string
  breadcrumbs: breadCrumbs[]
  children?: ReactElement
  sx?: Record<string, unknown>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  setFiltersToolbarOpen: Dispatch<SetStateAction<boolean>>
  filtersToolbarOpen: boolean
  filtersActive: boolean
  hasIntegrations: boolean
}

const HeaderComponent: FC<Props> = ({
  breadcrumbs,
  search,
  setSearch,
  children,
  sx,
  type,
  setFiltersToolbarOpen,
  filtersToolbarOpen,
  filtersActive,
  hasIntegrations
}) => {
  return (
    <Wrapper type={type} noBg className="header-container" sx={sx} borderbottom="true">
      <HeaderBreadcrumbsWrapper>
        {breadcrumbs.map((item, index) => {
          return (
            <Box key={index}>
              {item.link ? (
                <Box className="link-block">
                  <MuiLink component={Link} color="inherit" to={item.link} className="text link">
                    {item.name}
                  </MuiLink>
                  <ChevronRight />
                </Box>
              ) : (
                <Typography className="text">{item.name}</Typography>
              )}
            </Box>
          )
        })}
      </HeaderBreadcrumbsWrapper>

      <Box>{children}</Box>

      {hasIntegrations && (
        <InsightsHeader
          tabValue={0}
          filtersActive={filtersActive}
          handleTabChange={() => {
            //
          }}
          title="Insights"
          tabLabels={[]}
          hideIntegrationsTabs
          search={search}
          setSearch={setSearch}
          filtersToolbarOpen={filtersToolbarOpen}
          setFiltersToolbarOpen={setFiltersToolbarOpen}
        />
      )}
    </Wrapper>
  )
}

export default HeaderComponent
