import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  //width: 415px;
  height: 310px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%);
  //box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 100%;

  @media (min-width: 1110px) {
    //width: 290px;
  }

  @media (min-width: 1440px) {
    //width: 308px;
  }

  @media (min-width: 1920px) {
    //width: 415px;
    padding: 22px 32px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .circle {
    width: 220px;
    margin: 15px auto 0 auto;
  }
`
