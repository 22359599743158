import { useCallback, useEffect, useRef, useState } from 'react'
import axios from '../../../../lib/axios'
import {
  ChartData,
  CompetitorAnalysisData,
  ConfiguredCPI,
  CPIFrequency,
  CPIIntegrationItem,
  CPILibraryIntegration,
  CPILibraryItem,
  CPIManifest,
  CPIManifestUIDateRangeValue,
  CPIManifestUIFrequencyValue,
  CPISeverity,
  CPITimePeriod,
  CustomTrendsDataPayload,
  DetailedComparisonData,
  IntegrationConfigSyncStatus,
  IntegrationInstance,
  IntegrationStatusMessage,
  PredictionChartData,
  PredictionData,
  PredictionDataExtended,
  TrendsDataPayload,
  IFramework
} from '../../../../models'
import { fetchSyncStatus, formatCPIDisplayNameForURL, months } from '../../../../lib/utils'
import { Checked, CustomColors } from './LibraryDetails'
import {
  filterPredictionData,
  formatCustomTimePickerValue,
  getConfiguredInstancesApplicableToCpi,
  getEnabledInstances,
  getNotConfiguredInstancesApplicableToCpi,
  getPayloadTrendsChart
} from '../utils'
import {
  adjustDefaultTimePeriod,
  adjustTimePeriodValues,
  defaultFrequencyValues,
  defaultTimePeriodValues,
  getDataSourceAllOption
} from '../library.constants'
import dayjs from 'dayjs'
import { useLocation, useParams } from 'react-router-dom'
import { DataSourceError } from '../../dashboard/sections/category-activity/components/DataSources'
import { useAppSelector } from '../../../../store/hooks'
import { accountSettingsSelector } from '../../../../store/user/userSlice'

interface Props {
  cpiName: string | undefined
}

const useLibraryDetailsData = ({ cpiName }: Props) => {
  const location = useLocation()
  const params = useParams()
  const [userConfiguredIntegrations, setUserConfiguredIntegrations] = useState<IntegrationInstance[]>([])
  const [cpi, setCpi] = useState<CPILibraryItem | null>(null)
  const [allCpis, setAllCpis] = useState<CPILibraryItem[]>([])
  const [cpiConfiguration, setCpiConfiguration] = useState<ConfiguredCPI | null>(null)
  const [manifestData, setManifestData] = useState<CPIManifest | null>(null)
  const [competitorAnalysisData, setCompetitorAnalysisData] = useState<CompetitorAnalysisData | null>(null)
  const [detailedComparisonData, setDetailedComparisonData] = useState<DetailedComparisonData | null>(null)
  const [renderingChart, setRenderingChart] = useState(true)

  const [active, setActive] = useState(false)
  const [showBenchmarkingTab, setShowBenchmarkingTab] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [activeSources, setActiveSources] = useState<IntegrationInstance[]>([])
  const [checked, setChecked] = useState<Checked>({})
  const [favorite, setFavorite] = useState(Boolean(cpi?.favorite))
  const [hasPredictionsEnabled, setHasPredictionsEnabled] = useState(false)
  const isCategoryPage = Boolean(!cpiName?.includes('cpi'))

  const [stripes, setStripes] = useState<number | null>(null)
  const [selectedSeverity, setSelectedSeverity] = useState<CPISeverity>(CPISeverity.Critical)
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<CPITimePeriod>(CPITimePeriod.Month)
  const [customTimePeriodValue, setCustomTimePeriodValue] = useState<string>('')
  const [timePeriodValues, setTimePeriodValues] = useState<Array<CPITimePeriod | string>>(defaultTimePeriodValues)
  const [frequencyValues, setFrequencyValues] = useState<Array<CPIFrequency>>(defaultFrequencyValues)
  const [selectedFrequency, setSelectedFrequency] = useState<CPIFrequency>(CPIFrequency.Daily)
  const [selectedType, setSelectedType] = useState<string>('')
  const [predictionData, setPredictionData] = useState<PredictionChartData | null>(null)
  const previousCpi = useRef(cpiName)

  const [configuredInstancesApplicableToCpi, setConfiguredInstancesApplicableToCpi] = useState<IntegrationInstance[]>(
    []
  )
  const [notConfiguredInstancesApplicableToCpi, setNotConfiguredInstancesApplicableToCpi] = useState<
    IntegrationInstance[]
  >([])
  const [cpiSupportedAndUserConfiguredList, setCpiSupportedAndUserConfiguredList] = useState<IntegrationInstance[]>([])
  const [tempCpiSupportedAndUserConfiguredList, setTempCpiSupportedAndUserConfiguredList] = useState<
    IntegrationInstance[]
  >([])
  const [dataSourceValue, setDataSourceValue] = useState('All')
  const [dataSourceOptions, setDataSourceOptions] = useState<IntegrationInstance[]>([])
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState<string[]>([''])
  const [predictionModelAvailable, setPredictionModelAvailable] = useState<boolean>(false)
  const [daysUntilDataAvailable, setDaysUntilDataAvailable] = useState<number>(0)
  const [tempDataSources, setTempDataSources] = useState<any[]>([])
  const [sourcesLoaded, setSourcesLoaded] = useState(false)
  const [customColors, setCustomColors] = useState<CustomColors | null>(null)

  const [customFramework, setCustomFramework] = useState<IFramework | null>(null)
  const account = useAppSelector(accountSettingsSelector)
  const framework = account?.cyFramework
  const fw = framework === 'onyxia' ? 'onyxia' : framework === 'nist' ? 'nist' : 'custom'

  const getStatus = async (dataSource: any) => {
    const id = dataSource?.id || ''
    const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(id)

    if (!dataSource.name) return dataSource
    if (res && res.status && res.status === IntegrationStatusMessage.failed) {
      const payload: DataSourceError = {
        ...dataSource,
        syncStatus: {
          status: res.status,
          errorMessage: res.errorMessage
        }
      }
      return payload
    } else return dataSource
  }

  useEffect(() => {
    if (tempDataSources.length > 0 && !sourcesLoaded) {
      const sources: any = []
      tempDataSources.forEach(async (dataSource) => {
        if (dataSource.id) {
          const payload = await getStatus(dataSource)
          sources.push(payload)
        }
      })

      setSourcesLoaded(true)
      setDataSourceOptions(sources)
    }
  }, [tempDataSources, sourcesLoaded])

  useEffect(() => {
    if (sourcesLoaded) {
      setSourcesLoaded(false)
      setTempDataSources([])
      setDataSourceOptions([])
    }
  }, [location.pathname, location.search, params.results])

  useEffect(() => {
    if (tempCpiSupportedAndUserConfiguredList.length > 0 && dataSourceOptions.length > 0) {
      const response: any[] = []

      tempCpiSupportedAndUserConfiguredList.forEach((n) => {
        const foundDataSource = dataSourceOptions.find((c) => c.id === n.id)
        if (foundDataSource) {
          response.push({
            ...n,
            syncStatus: foundDataSource.syncStatus
          })
        } else {
          response.push(n)
        }
      })

      setCpiSupportedAndUserConfiguredList(response)
    }
  }, [tempCpiSupportedAndUserConfiguredList, dataSourceOptions])

  useEffect(() => {
    getNameForManifest(allCpis, userConfiguredIntegrations, cpiName, true)
  }, [allCpis])

  const getNameForManifest = (
    allCpis: CPILibraryItem[],
    userConfiguredIntegrations: IntegrationInstance[],
    name = '',
    isSet = false
  ) => {
    if (!allCpis.length) {
      return {
        cpiManifestName: '',
        currentCpiName: '',
        cpiSupportedAndUserConfiguredList: []
      }
    }
    const finalName = name || cpiName
    const currentCpi = allCpis.find((n: CPILibraryItem) => formatCPIDisplayNameForURL(n.display_name) === finalName)
    let cpiNameForManifest = finalName
    let fetchedCpi: CPILibraryItem | null = null
    if (currentCpi) {
      fetchedCpi = currentCpi
      cpiNameForManifest = currentCpi.name
    } else {
      const newCpi = allCpis.find((n: CPILibraryItem) => n.name === finalName)
      if (newCpi) {
        fetchedCpi = newCpi
        cpiNameForManifest = newCpi?.name ?? ''
      }
    }
    setCpi(fetchedCpi)

    let list: IntegrationInstance[] = []
    if (fetchedCpi) {
      setFavorite(fetchedCpi.favorite)
      const cpiSupportedIntegrationNames = fetchedCpi.supported_integrations.map((n: CPILibraryIntegration) => n.name)

      list = userConfiguredIntegrations.filter((n) => cpiSupportedIntegrationNames.includes(n.integration_name))
      if (isSet) {
        const newList: IntegrationInstance[] = []
        list.forEach(async (instance) => {
          const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(instance.id)
          if (res && res.status === IntegrationStatusMessage.failed) {
            newList.push({
              ...instance,
              syncStatus: {
                status: res.status,
                errorMessage: res.errorMessage
              }
            })
          } else {
            newList.push(instance)
          }
        })
        setCpiSupportedAndUserConfiguredList(newList)
      }
    }

    return {
      cpiManifestName: cpiNameForManifest || '',
      currentCpiName: fetchedCpi?.name || '',
      cpiSupportedAndUserConfiguredList: list
    }
  }

  const getActiveSources = useCallback(async (dataSourcesConfiguredForCpi: CPIIntegrationItem[]) => {
    if (!dataSourcesConfiguredForCpi) return
    // eslint-disable-next-line array-callback-return
    let activeSourcesIds: string[] = dataSourcesConfiguredForCpi.map((n: any) => {
      if (!n.deleted) {
        return n.id
      }
    })
    activeSourcesIds = activeSourcesIds.filter(Boolean)
    const activeSourcesPromises = activeSourcesIds.map(async (c: string) => {
      const resp = await axios.get(`/api/v3/integrations/config/${c}`)

      return resp.data.data
    })
    const activeSourcesResult = await Promise.all(activeSourcesPromises)
    setActiveSources(activeSourcesResult)
  }, [])

  const deactivateCpi = useCallback(() => {
    setActive(false)
  }, [])

  const refetchCpi = useCallback(async () => {
    if (!cpi) return

    try {
      const res = await axios.get<{ data: { cpis: CPILibraryItem[] } }>(`/api/v3/cpis`)

      if (res.status === 200) {
        const {
          data: {
            data: { cpis: allCpis }
          }
        } = res

        const newCpi = allCpis.find((n: CPILibraryItem) => n.name === cpi.name)
        if (newCpi) {
          setCpi(newCpi)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [cpi])

  const updateFavorite = useCallback(async () => {
    if (!cpi) return

    try {
      if (cpi.favorite) {
        setFavorite(false)

        await axios.delete('/api/v3/cpis/favorites', {
          data: {
            name: cpi.name
          }
        })
      } else {
        setFavorite(true)

        await axios.post('/api/v3/cpis/favorites', {
          name: cpi.name
        })
      }

      await refetchCpi()
    } catch (e) {
      console.error(e)
      if (cpi.favorite) {
        setFavorite(true)
      } else {
        setFavorite(false)
      }
    }
  }, [cpi])

  const fetchUserConfiguredIntegrations = useCallback(async () => {
    try {
      const res = await axios.get<{ instances: IntegrationInstance[] }>('/api/v3/integrations/configured')

      if (res.status === 200) {
        const { instances } = res.data
        setUserConfiguredIntegrations(instances)

        return instances as IntegrationInstance[]
      }
    } catch (e) {
      console.error(e)
    }

    return []
  }, [])

  const fetchAllCpis = useCallback(async (): Promise<CPILibraryItem[]> => {
    try {
      const res = await axios.get(`/api/v3/cpis`)
      if (res.status === 200) {
        const {
          data: {
            data: { cpis }
          }
        } = res
        setAllCpis(cpis)

        return cpis
      }
    } catch (e) {
      console.error(e)
    }

    return []
  }, [])

  const fetchManifest = useCallback(
    async (cpiManifestName: string) => {
      try {
        const res = await axios.get<{ data: CPIManifest | null }>(`/api/v3/cpis/${cpiManifestName}/details`)

        if (res.status === 200) {
          const { data } = res.data
          setManifestData(data)

          if (!data) return null

          try {
            const customFramework = await axios.get('/api/v3/frameworks/custom')
            if (customFramework && data) {
              setCustomFramework(customFramework.data.framework)
              const categories = customFramework.data.framework.data.functions
              const thisCategory = data.category.title || ''
              const foundCategory = categories.find((n: any) => n.title === thisCategory)
              if (foundCategory) {
                setCustomColors({
                  chip_bg_color: foundCategory.chip_bg_color,
                  chip_color: foundCategory.chip_color
                })
              }
            }
          } catch (err) {
            console.error(err)
          }

          let defaultSeverity = data.ui.trend_chart.user_input.visualization_param.default_section as any
          if (data.ui.trend_chart.user_input.visualization_param.sections_name === 'email_sizes') {
            const defaultSectionName = data.ui.trend_chart.user_input.visualization_param.default_section
            const findItem = data.ui.trend_chart.user_input.visualization_param.sections.find(
              (item) => item.name === defaultSectionName
            )
            defaultSeverity = findItem?.display_name
          }
          const defSec = data.ui.trend_chart.user_input.visualization_param.sections.find(
            (c) => c.name === defaultSeverity
          )
          const defaultFrequencyValue = data.ui.trend_chart.frequency.default as CPIFrequency
          const defaultTimePeriod = data.ui.trend_chart.date_range.default
          const defaultTimePeriodValues: CPIManifestUIDateRangeValue[] = data.ui.trend_chart.date_range.values
          const adjustedTimePeriodValues = adjustTimePeriodValues(defaultTimePeriodValues)
          const adjustedDefaultTimePeriod = adjustDefaultTimePeriod(defaultTimePeriod, defaultFrequencyValue)
          const defaultType = data.ui.trend_chart.type ? data.ui.trend_chart.type.values[0].display_name : null

          const insightFilters = localStorage.getItem('insightFilters')

          if (insightFilters) {
            const parsed = JSON.parse(insightFilters)

            if (parsed.dates) {
              const { dateFrom, dateTo } = parsed.dates
              const dFrom = dateFrom.split('-')
              const dFromYear = dFrom[0].slice(2, 4)
              const monthIndex = Number(dFrom[1] - 1).toString()
              const dFromMonth = months[Number(monthIndex)]
              const dFromMonthShort = dFromMonth.slice(0, 3)

              const dTo = dateTo.split('-')
              const dToYear = dTo[0].slice(2, 4)
              const monthToIndex = Number(dTo[1] - 1).toString()
              const dToMonth = months[Number(monthToIndex)]
              const dToMonthShort = dToMonth.slice(0, 3)

              let ct = `${dFromMonthShort} '${dFromYear}-${dToMonthShort} '${dToYear}`
              if (dFromYear === dToYear) {
                ct = `${dFromMonthShort}-${dToMonthShort} '${dToYear}`
              }
              setCustomTimePeriodValue(ct)
              const severity = (parsed.severity.charAt(0).toUpperCase() + parsed.severity.slice(1)) as CPISeverity
              setSelectedTimePeriod(CPITimePeriod.Custom)
              setSelectedFrequency(parsed.frequency as CPIFrequency)
              setSelectedSeverity(severity)
            }

            setTimeout(() => {
              localStorage.removeItem('insightFilters')
            }, 1000)
          } else if (location.state) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { dates, frequency, severity, timePeriod } = location.state
            setSelectedSeverity(severity)
            setSelectedFrequency(frequency)
            if (dates) {
              const formattedValue = formatCustomTimePickerValue(dates.dateFrom, dates.dateTo)
              setCustomTimePeriodValue(formattedValue)
              setSelectedTimePeriod(CPITimePeriod.Custom)
            } else if (timePeriod) {
              setSelectedTimePeriod(timePeriod)
            }
          } else {
            setSelectedSeverity(defSec ? defSec.display_name : defaultSeverity)
            setSelectedFrequency(defaultFrequencyValue)
            setSelectedTimePeriod(adjustedDefaultTimePeriod)
            if (defaultType) {
              setSelectedType(defaultType)
            }
          }

          setFrequencyValues(
            data.ui.trend_chart.frequency.values.map((n: CPIManifestUIFrequencyValue) => n.name) as CPIFrequency[]
          )
          setTimePeriodValues(adjustedTimePeriodValues)

          return data
        }
      } catch (e) {
        console.error(e)
        const message = (e as any).response?.data?.code || ''
        if (message === 'manifest_not_found') {
          return null
        }
      }

      return null
    },
    [initialLoad]
  )

  const fetchCompetitorAnalysis = useCallback(async (cpiManifestName: string) => {
    try {
      const res = await axios.get(`api/v3/benchmarks/${cpiManifestName}/performance_metrics`)

      if (res.status === 200) {
        const {
          data: { data: competitorAnalysisData }
        } = res

        setShowBenchmarkingTab(!!competitorAnalysisData)
        setCompetitorAnalysisData(competitorAnalysisData)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchDetailedComparison = useCallback(async (cpiManifestName: string) => {
    try {
      const detailedComparisonDataRes = await axios.get(`api/v3/benchmarks/${cpiManifestName}/score`)

      if (detailedComparisonDataRes.status === 200) {
        const {
          data: { data: detailedComparisonData }
        } = detailedComparisonDataRes

        setShowBenchmarkingTab(!!detailedComparisonData)
        setDetailedComparisonData(detailedComparisonData)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchCpiConfig = useCallback(
    async (
      currentCpiName: string,
      manifestData: CPIManifest | null,
      userConfiguredIntegrations: IntegrationInstance[],
      cpiSupportedAndUserConfiguredList: IntegrationInstance[]
    ) => {
      try {
        const res = await axios.get<{ data: ConfiguredCPI }>(`/api/v3/cpis/${currentCpiName}`)

        if (res.status === 200) {
          const {
            data: { data }
          } = res
          setActive(true)
          setCpiConfiguration(data)

          const configuredIntegrations = data.integrations as CPIIntegrationItem[]
          if (!configuredIntegrations) return

          await getActiveSources(configuredIntegrations)
          const enabledInstances = getEnabledInstances(configuredIntegrations, userConfiguredIntegrations)
          setChecked(enabledInstances)
          const findItems = configuredIntegrations.find((item) => item.deleted)
          let unique = [] as IntegrationInstance[]
          if (findItems) {
            const list: IntegrationInstance[] = []
            configuredIntegrations.forEach((item) => {
              if (item.deleted) {
                const obj = {
                  connection_id: '',
                  created_at: '',
                  created_by: '',
                  description: '',
                  id: item.id,
                  config_name: item.configName,
                  integration_name: item.name,
                  name: item.configName,
                  prefect_deployment_id: '',
                  source_id: '',
                  tenant_id: '',
                  updated_at: '',
                  updated_by: '',
                  deleted: true
                } as IntegrationInstance
                list.push(obj)

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setChecked((prevState) => ({ ...prevState, [item.configName]: true }))
              } else {
                const findItem = cpiSupportedAndUserConfiguredList.find((cpi) => cpi.name === item.name)
                if (findItem) list.push(findItem)
              }
            })
            const combineItems = [...cpiSupportedAndUserConfiguredList, ...list]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            unique = [...new Map(combineItems.map((item) => [item.name, item])).values()]
            setTempCpiSupportedAndUserConfiguredList(unique)
          } else setTempCpiSupportedAndUserConfiguredList(cpiSupportedAndUserConfiguredList)

          const configuredInstancesIds: string[] = data.integrations?.map((n) => n.id) || []
          const filterList = unique.length ? unique : cpiSupportedAndUserConfiguredList
          const allConfiguredInstances = filterList.filter((n) => configuredInstancesIds.includes(n.id))
          setDataSourceValue(`All (${data.integrations.length})`)
          const allOption = getDataSourceAllOption(data.integrations.length)

          const adjustedConfigured = []
          allConfiguredInstances.forEach(async (instance) => {
            const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(instance.id)
            if (res && res.status === IntegrationStatusMessage.failed) {
              adjustedConfigured.push({
                ...instance,
                syncStatus: {
                  status: res.status,
                  errorMessage: res.errorMessage
                }
              })
            } else {
              adjustedConfigured.push(instance)
            }
          })

          const dataSourceOptions = [allOption, ...allConfiguredInstances]
          // setDataSourceOptions(dataSourceOptions)
          setTempDataSources(dataSourceOptions)

          const configuredInstancesApplicableToCpi = getConfiguredInstancesApplicableToCpi(
            configuredIntegrations,
            userConfiguredIntegrations
          )
          setConfiguredInstancesApplicableToCpi(configuredInstancesApplicableToCpi)
          setSelectedDataSourceIds(configuredInstancesIds)

          if (!manifestData) return
          const potentialSources = getNotConfiguredInstancesApplicableToCpi(
            manifestData,
            configuredIntegrations,
            userConfiguredIntegrations
          )
          setNotConfiguredInstancesApplicableToCpi(potentialSources)
        }
      } catch (e) {
        console.error(e)
        setActive(false)
        if (!manifestData) return
        const cpiSupportedIntegrationsNames = manifestData.supported_integrations.map((n) => n.name)
        const notConfiguredInstancesApplicableToCpi = userConfiguredIntegrations.filter((n) =>
          cpiSupportedIntegrationsNames.includes(n.integration_name)
        )
        setNotConfiguredInstancesApplicableToCpi(notConfiguredInstancesApplicableToCpi)
      }
    },
    []
  )

  const checkAIPredictions = async (manifestData: CPIManifest | null) => {
    if (manifestData && manifestData.predictions && manifestData.predictions.is_supported) {
      setHasPredictionsEnabled(true)
      return true
    } else {
      setHasPredictionsEnabled(false)
      return false
    }
  }

  const fetchPredictionData = async (cpiName: string, severity = selectedSeverity, dataSourceName = '') => {
    const { cpiManifestName } = getNameForManifest(allCpis, userConfiguredIntegrations, cpiName)
    const thisSelectedSource = activeSources.find((n) => n.name === dataSourceValue)

    const getSourceName = () => {
      if (!dataSourceName) {
        return thisSelectedSource
          ? thisSelectedSource.integration_name
          : dataSourceValue.toLowerCase().includes('all (')
          ? 'all'
          : activeSources.length > 0
          ? activeSources[0].integration_name
          : ''
      } else {
        if (dataSourceName === 'all') return 'all'
        const integrationName = activeSources.find((n) => n.id === dataSourceName)

        if (integrationName) {
          return integrationName.integration_name
        }
      }
    }
    let sourceName = getSourceName()
    const sourceId =
      dataSourceName ||
      (activeSources.length > 0 ? (thisSelectedSource ? thisSelectedSource.id : activeSources[0].id) : '')

    if (sourceId.length > 0 && sourceName !== 'all') {
      const sourceSplit = sourceId.split('-')
      sourceName = `${sourceName}_${sourceSplit[0]}`
    }
    const payload = {
      severity_level: severity.toLowerCase(),
      datasource_name: sourceName
    }

    try {
      const res = await axios.post(`/api/v3/ai/predictions/cpis/${cpiManifestName}?predictions-data`, payload)

      const getIntegrationId = () => {
        if (!dataSourceName) {
          return activeSources.length > 0
            ? thisSelectedSource
              ? thisSelectedSource.id
              : dataSourceValue.toLowerCase().includes('all (')
              ? 'all'
              : activeSources[0].id
            : ''
        }

        return dataSourceName
      }

      try {
        const firstPayload: any = {
          section: severity.toLowerCase()
        }
        if (getIntegrationId() !== 'all') {
          firstPayload.integration_id = getIntegrationId()
        }
        const firstDataRes = await axios.post(
          `/api/v3/cpis/${cpiManifestName}/data/first?days-until-available-predictions`,
          firstPayload
        )

        if (firstDataRes.status.toString().includes('20')) {
          const diff = dayjs().diff(firstDataRes.data.data.date || '', 'days')
          setDaysUntilDataAvailable(diff)
        }
      } catch (err) {
        console.error(err)
      }

      if (res.data.statusCode.toString().includes('20')) {
        setPredictionModelAvailable(true)
        return res.data.body.predictions.map((n: any) => ({ xValue: n.date, yValue: n.prediction }))
      }

      if (res.data.statusCode === 404 && res.data.body?.message === 'Model not available') {
        setPredictionModelAvailable(false)
      }
    } catch (e) {
      console.error(e)
    }

    return []
  }

  const fetchTrendsDataForPredictions = async (
    payload: TrendsDataPayload,
    cpiName: string,
    predictionData: PredictionData[]
  ) => {
    const { cpiManifestName } = getNameForManifest(allCpis, userConfiguredIntegrations, cpiName)

    const res = await axios.post(`/api/v3/cpis/${cpiManifestName}/data?fetch-trends-chart-data`, {
      ...payload,
      section: payload.section.toLowerCase()
    })

    if (res.status === 201) {
      const { data }: { data: ChartData } = res.data
      const allValues: PredictionDataExtended[] = [
        ...data.values.map((n) => ({ xValue: n.xValue, yValue: n.yValue, prediction: false })),
        ...predictionData.map((n) => ({ ...n, prediction: true }))
      ]

      const chartData: PredictionChartData = {
        ...data,
        values: allValues
      }
      const filteredData: PredictionDataExtended[] = filterPredictionData(2, chartData)
      setPredictionData({
        ...chartData,
        values: [...filteredData]
      })

      return data
    }

    return null
  }

  const fetchAll = useCallback(
    async (name = '') => {
      if (name === previousCpi.current) return
      previousCpi.current = name

      try {
        const userConfiguredIntegrations = await fetchUserConfiguredIntegrations()
        const cpis = await fetchAllCpis()
        const { cpiManifestName, currentCpiName, cpiSupportedAndUserConfiguredList } = getNameForManifest(
          cpis,
          userConfiguredIntegrations,
          name
        )
        const manifestData = await fetchManifest(cpiManifestName)

        if (!manifestData) return

        await Promise.all([
          checkAIPredictions(manifestData),
          fetchCpiConfig(currentCpiName, manifestData, userConfiguredIntegrations, cpiSupportedAndUserConfiguredList),
          fetchCompetitorAnalysis(cpiManifestName),
          fetchDetailedComparison(cpiManifestName)
        ])
      } catch (e) {
        console.error(e)
      } finally {
        setInitialLoad(true)
      }
    },
    [activeSources]
  )

  const refetchPredictionData = async ({
    thisCpiName,
    severity,
    selectedSources,
    timePeriod
  }: {
    thisCpiName: string
    severity?: CPISeverity
    selectedSources?: string[]
    timePeriod?: CPITimePeriod
  }) => {
    const res = await fetchPredictionData(
      thisCpiName || cpiName || '',
      severity || selectedSeverity,
      selectedSources ? (selectedSources.length === 1 ? selectedSources[0] : 'all') : ''
    )

    const payload: any = getPayloadTrendsChart({
      severity: severity ? severity.toLowerCase() : (selectedSeverity.toLowerCase() as any),
      selectedDataSourceIds: selectedSources || selectedDataSourceIds,
      selectedTimePeriod: timePeriod || CPITimePeriod.ThreeMonths,
      customTimePeriodValue,
      selectedFrequency: selectedFrequency
    })
    return await fetchTrendsDataForPredictions(
      {
        ...payload,
        section: severity ? severity.toLowerCase() : selectedSeverity.toLowerCase()
      },
      cpiName || '',
      res
    )
  }

  useEffect(() => {
    checkAIPredictions(manifestData).then((hasPredictionEnabled) => {
      if (hasPredictionEnabled) {
        fetchPredictionData(cpiName || '').then((predictionValues: PredictionData[]) => {
          let payload: CustomTrendsDataPayload = getPayloadTrendsChart({
            severity: selectedSeverity,
            selectedDataSourceIds,
            selectedTimePeriod: CPITimePeriod.ThreeMonths,
            selectedFrequency: CPIFrequency.Daily
          })
          if (customTimePeriodValue.length > 0) {
            payload = getPayloadTrendsChart({
              severity: selectedSeverity,
              selectedDataSourceIds,
              // selectedTimePeriod: CPITimePeriod.ThreeMonths,
              // selectedFrequency: CPIFrequency.Daily
              // Changing this to fit when redirecting from Insights page to search data with correct params
              customTimePeriodValue,
              selectedTimePeriod,
              selectedFrequency
            })
          }
          fetchTrendsDataForPredictions(
            {
              ...payload,
              section: payload.section.toLowerCase() as any
            },
            cpiName || '',
            predictionValues
          )
        })
      }
    })
  }, [cpiName, allCpis, manifestData, selectedSeverity, activeSources])

  useEffect(() => {
    if (!isCategoryPage) {
      fetchAll().catch((e) => e)
    }

    return () => {
      previousCpi.current = ''
    }
  }, [isCategoryPage, cpiName, location])

  return {
    userConfiguredIntegrations,
    cpi,
    customColors,
    setInitialLoad,
    fetchPredictionData,
    allCpis,
    selectedType,
    setSelectedType,
    cpiConfiguration,
    manifestData,
    competitorAnalysisData,
    detailedComparisonData,
    active,
    showBenchmarkingTab,
    initialLoad,
    activeSources,
    checked,
    setChecked,
    configuredInstancesApplicableToCpi,
    notConfiguredInstancesApplicableToCpi,
    cpiSupportedAndUserConfiguredList,
    dataSourceValue,
    dataSourceOptions,
    deactivateCpi,
    favorite,
    updateFavorite,
    selectedSeverity,
    selectedTimePeriod,
    timePeriodValues,
    frequencyValues,
    selectedFrequency,
    setSelectedSeverity,
    setSelectedTimePeriod,
    setSelectedFrequency,
    setDataSourceValue,
    selectedDataSourceIds,
    setSelectedDataSourceIds,
    fetchCpiConfig,
    fetchAll,
    stripes,
    setStripes,
    customTimePeriodValue,
    setCustomTimePeriodValue,
    hasPredictionsEnabled,
    predictionData,
    setPredictionData,
    refetchPredictionData,
    predictionModelAvailable,
    daysUntilDataAvailable,
    customFramework,
    framework,
    fw,
    renderingChart,
    setRenderingChart
  }
}

export default useLibraryDetailsData
