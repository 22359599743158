import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './library-components.styles'
import SelectSearchbar from '../../../components/form-components/SelectSearchbar'
import useSearchLabel from '../../../../hooks/useSearchLabel'
import ResetSearch from '../../../components/form-components/ResetSearch'
import SortIcon from '../../../components/svg/SortIcon'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  maxHeight?: string
  noborder?: string
  iscustom?: string
  hideSearch?: boolean
  label?: string
  isSort?: boolean
  version?: string
}

const LibrarySelectField: FC<Props> = ({
  version,
  isSort,
  label,
  options,
  handleChange,
  placeholder,
  maxHeight,
  handleReset,
  noborder,
  minWidth,
  iscustom,
  value,
  hideSearch = false,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const convertedOptions = useMemo(() => {
    return options.map((item) => {
      return {
        label: item,
        name: item
      }
    })
  }, [options])

  const { search, setSearch, labelOptions, resetData, highlightMatch } = useSearchLabel(convertedOptions, handleReset)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}
      {isSort && <SortIcon />}
      <LibraryFormControl
        sx={{ minWidth }}
        isSort={isSort}
        iscustom={iscustom}
        noborder={noborder}
        isselected={
          anchorEl || (placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')))
            ? 'true'
            : 'false'
        }
      >
        <LibrarySelect
          noborder={noborder}
          isselected={
            placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
          }
          labelId="library-select-label"
          id="library-select"
          iscustom={iscustom}
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              },
              style: {
                ...CategoryMenuProps.PaperProps.style,
                maxHeight: maxHeight ?? CategoryMenuProps.PaperProps.style.maxHeight
              }
            },
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned.length} selected`
            }

            return typeof noborder !== 'undefined' ? `${selected}` : selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {!hideSearch && <SelectSearchbar search={search} setSearch={setSearch} placeholder={`Search ${version}`} />}

          {labelOptions.map((option) => (
            <MenuItem
              key={option.name}
              value={option.name}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
            >
              {rest.multiple && (
                <Checkbox
                  checked={value.indexOf(option.name) > -1}
                  icon={<InstanceUncheckedIcon />}
                  checkedIcon={<InstanceCheckedIcon />}
                />
              )}
              <ListItemText primary={highlightMatch(option.label)} />
            </MenuItem>
          ))}

          <ResetSearch resetData={resetData} />
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default LibrarySelectField
