import { Fragment, FC, useState } from 'react'
import { Box, InputAdornment, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'

/* Utils */
import { Option } from './DataSourceSelect'

/* Components */
import { FormControl, MenuProps, getMenuProps, OptionsWrapper, Result, Select } from './dataSourceSelectField.styles'
import { SelectDropdownIcon } from '../../../../../../components/svg'
import { IntegrationInstance } from '../../../../../../../models'
import SelectDataSearch from '../../../../components/SelectDataSearch'
import { AcaHoverTooltip } from '../../../../../library/library-details/components/LibraryDetailsTooltip'

interface Props extends SelectProps {
  options: Option[]
  value: string
  handleChange: any
  minWidth?: string
  order: 1 | 2
  disabled: boolean
  instances: IntegrationInstance[]
  getDisabledOption: (order: 1 | 2, value: string) => boolean
  numberOfAssets?: number
  fieldWidth?: string
  acaMenuProps?: boolean
}

const DataSourceSelectField: FC<Props> = ({
  options,
  handleChange,
  placeholder,
  order,
  minWidth,
  value,
  disabled,
  instances,
  getDisabledOption,
  numberOfAssets,
  fieldWidth,
  acaMenuProps,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [search, setSearch] = useState('')

  const searchedOptions = search.length
    ? options.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
    : options

  const isselected =
    placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'

  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  const findInstanceName = (instanceId: string) => {
    const res = instances.find((n) => n.id === instanceId)
    if (res) return res.name
    return ''
  }

  const findInstanceIcon = () => {
    const res = options.find((n) => n.value === value)
    if (res) return res.src
    return ''
  }

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: '#FFA9FC' }}>
          {part}
        </span>
      ) : (
        <Fragment key={index}>{part}</Fragment>
      )
    )
  }

  const menuProps = acaMenuProps
    ? getMenuProps({
        width: `${fieldWidth}px !important`
      })
    : MenuProps

  return (
    <FormControl
      sx={{ minWidth }}
      isselected={isselected}
      isopen={anchorEl ? 'true' : 'false'}
      is_disabled={disabled ? 'true' : 'false'}
      field_width={fieldWidth || undefined}
      className="data-source-form-control"
    >
      <Select
        isselected={isselected}
        labelId="library-select-label"
        id="library-select"
        className="data-source-select"
        displayEmpty
        MenuProps={{
          ...menuProps,
          PaperProps: {
            ...menuProps.PaperProps,
            style: { maxHeight: 'unset', display: anchorEl ? 'block' : 'none' }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (!selected) return placeholder
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }
          const value = findInstanceName(selected as string)

          return (
            <Box className="input-value-wrapper">
              <Typography className="input-value-integration">{value}</Typography>
              <Typography className="input-value-count">{numberOfAssets}</Typography>
            </Box>
          )
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            onMouseDown={handleClick}
            sx={{
              color: 'red'
            }}
            startAdornment={
              value ? (
                <InputAdornment position="start">
                  <img src={findInstanceIcon()} alt="" width={18} height={18} />
                </InputAdornment>
              ) : (
                ''
              )
            }
          />
        }
      >
        <SelectDataSearch search={search} setSearch={setSearch} />

        <Result>Results ({searchedOptions.length})</Result>

        <OptionsWrapper length={searchedOptions.length}>
          {searchedOptions.map((option) => (
            <Fragment key={option.value}>
              {getDisabledOption(order, option.value) ? (
                <AcaHoverTooltip
                  title="Instance already selected"
                  arrow
                  placement="left"
                  sx={{ zIndex: '100000000 !important' }}
                >
                  <MenuItem
                    className={getDisabledOption(order, option.value) ? 'disabled-item' : ''}
                    key={option.value}
                    value={option.value}
                    onClick={() => {
                      if (getDisabledOption(order, option.value)) return

                      handleChange(option.value)
                      if (!rest.multiple) {
                        setAnchorEl(null)
                      }
                    }}
                    disableRipple
                  >
                    <img src={option.src} alt="" width={24} height={24} />
                    <ListItemText
                      primary={<span style={{ textTransform: 'none' }}>{highlightMatch(option.label)}</span>}
                    />
                  </MenuItem>
                </AcaHoverTooltip>
              ) : (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={() => {
                    handleChange(option.value)
                    if (!rest.multiple) {
                      setAnchorEl(null)
                    }
                  }}
                  disabled={getDisabledOption(order, option.value)}
                  disableRipple
                >
                  <img src={option.src} alt="" width={24} height={24} />
                  <ListItemText
                    primary={<span style={{ textTransform: 'none' }}>{highlightMatch(option.label)}</span>}
                  />
                </MenuItem>
              )}
            </Fragment>
          ))}
        </OptionsWrapper>
      </Select>
    </FormControl>
  )
}

export default DataSourceSelectField
