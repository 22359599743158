import { CPIsSectionHeader } from '../../../dashboard.styles'
import { CategoryActivityIcon } from '../../../../../components/svg'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import StubCategoriesSection from './Stubs/StubCategoriesSection'
import { defaultLibraryCategoriesNIST, defaultLibraryCategoriesOnyxia } from '../../../../library/library.constants'

const StubCPIsSection = () => {
  const secondBreakpoint = useMediaQuery('(min-width:1920px)')
  const thirdBreakpoint = useMediaQuery('(max-width:1440px)')
  let storageFramework = ''
  if (typeof window !== 'undefined') {
    const framework = localStorage.getItem('framework')
    storageFramework = framework || ''
  }
  const categoryOptions = storageFramework === 'nist' ? defaultLibraryCategoriesNIST : defaultLibraryCategoriesOnyxia

  return (
    <>
      <CPIsSectionHeader
        sx={{ alignItems: 'center !important', justifyContent: 'flex-start !important', width: '100% !important' }}
      >
        <CategoryActivityIcon />
        <Typography>Category Performance Score Trends</Typography>
      </CPIsSectionHeader>

      <Grid container spacing={3}>
        {categoryOptions.map((category) => (
          <Grid item key={category.id} xs={secondBreakpoint ? 3 : 4}>
            <StubCategoriesSection key={category.id} thirdBreakpoint={thirdBreakpoint} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default StubCPIsSection
