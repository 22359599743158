import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const ScoreWrapper = styled(Box)`
  //width: 415px;
  width: 100%;
  height: 310px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%);
  padding: 16px;

  @media (min-width: 1110px) {
    //width: 290px;
  }

  @media (min-width: 1440px) {
    //width: 308px;
  }

  @media (min-width: 1920px) {
    //height: 310px;
    //width: 415px;
    //padding: 22px 32px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .content {
    width: 197px;
    //width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1445px) {
      flex-direction: column;
    }

    @media (max-width: 1370px) {
      justify-content: flex-start;
    }

    .over-perform {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-top: 12px;

      @media (max-width: 1445px) {
        margin-top: 0;
      }

      svg {
        width: 12px;
        min-width: 12px;
        margin-left: 8px;
      }

      b {
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      .value {
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }
`
