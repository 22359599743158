import { FC } from 'react'
import { CardLoadingWrapper } from './insightsList.styles'
import { Box, Skeleton } from '@mui/material'

const CardLoadingSkeleton: FC = () => {
  return (
    <CardLoadingWrapper>
      <Box className="up">
        <Box className="up-left">
          <Skeleton
            variant="rectangular"
            component="div"
            animation="wave"
            width="100%"
            height="100%"
            className="card-skeleton-inner card-up-left"
          />
        </Box>
        <Box className="up-right">
          <Skeleton
            variant="rectangular"
            component="div"
            animation="wave"
            width="100%"
            height="100%"
            className="card-skeleton-inner card-up-right-1"
          />
          <Skeleton
            variant="rectangular"
            component="div"
            animation="wave"
            width="100%"
            height="100%"
            className="card-skeleton-inner card-up-right-2"
          />
        </Box>
      </Box>
      <Box className="down">
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner card-bottom"
        />
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner card-bottom"
        />
        <Skeleton
          variant="rectangular"
          component="div"
          animation="wave"
          width="100%"
          height="100%"
          className="card-skeleton-inner card-bottom"
        />
      </Box>
    </CardLoadingWrapper>
  )
}

export default CardLoadingSkeleton
