import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from '../../../lib/axios'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import SaveIcon from '@mui/icons-material/Save'
import { useAuth } from '@frontegg/react'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { frameworkDefaultOptions } from './framework.constants'
import { notification } from '../../../store/notifications/notificationSlice'
import { IFramework } from '../../../models'
import { Role } from '../../../lib/utils'

/* Components */
import { Container, Header, ContentWrapper, Content, Divider } from './framework.styles'
import FrameworkSelectCard from './components/FrameworkSelectCard'
import { ThemeButton } from '../../components/buttons'
import { CustomFrameworkIcon } from '../../components/svg'
import { accountSettingsSelector, setAccountSettings } from '../../../store/user/userSlice'
import { ModalType, openModal } from '../../../store/modals/modalSlice'
import { openToast, ToastVariant } from '../../../store/toasts/toastSlice'

const Framework: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const accountSettings = useAppSelector(accountSettingsSelector)
  const { user: authUser } = useAuth()
  const [tenant, setTenant] = useState({} as any)
  const [selected, setSelected] = useState(1)
  const [initialLoad, setInitialLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [customFramework, setCustomFramework] = useState<IFramework | null>(null)
  const [hasEditRole, setHasEditRole] = useState(false)

  const getCustomFramework = async (): Promise<void> => {
    try {
      const res = await axios.get<{ framework: IFramework }>('/api/v3/frameworks/custom')

      if (res.status.toString().includes('20')) {
        const customFramework = res.data.framework

        setCustomFramework(customFramework)
      }
    } catch (err) {
      console.error(err)
      setCustomFramework(null)
    }
  }

  const fetchAccountData = async () => {
    try {
      const { data } = await axios.get('/api/v3/tenant')
      setTenant(data.data)

      const index = frameworkDefaultOptions.findIndex((n) => n.value === data.data.cyFramework)
      setSelected(index > -1 ? index + 1 : 3)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (authUser) {
      const roles = authUser ? authUser.roles : []

      if (roles.length > 0 && roles[0].name) {
        const role = roles[0].name.toLowerCase()
        setHasEditRole(role === Role.Owner || role === Role.Admin)
      }
    }
  }, [authUser])

  useEffect(() => {
    if (!initialLoad) {
      Promise.all([fetchAccountData(), getCustomFramework()]).finally(() => setInitialLoad(true))
    }
  }, [initialLoad])

  const getSaveDisabled = () => {
    if (tenant) {
      const { cyFramework } = tenant
      if (cyFramework === 'onyxia' && selected === 1) return true
      if (cyFramework === 'nist' && selected === 2) return true
      return cyFramework !== 'nist' && cyFramework !== 'onyxia' && selected === 3
    }
  }

  const getDiscardDisabled = () => {
    if (tenant) {
      const { cyFramework } = tenant
      if (cyFramework === 'onyxia') {
        if (selected === 1) return true
        if (selected === 2) return false
        if (selected === 3) return false
      }
      if (cyFramework === 'nist') {
        if (selected === 1) return false
        if (selected === 2) return true
        if (selected === 3) return false
      }
      if (cyFramework !== 'onyxia' && cyFramework !== 'nist') {
        if (selected === 1) return false
        if (selected === 2) return false
        if (selected === 3) return true
      }
    }

    return false
  }

  const updateFramework = async (framework: string) => {
    if (!tenant) return

    const currentFramework = tenant.cyFramework
    const nextFramework = framework
    const foundCurrent = frameworkDefaultOptions.find((n) => n.value === currentFramework)
    const foundNext = frameworkDefaultOptions.find((n) => n.value === nextFramework)

    try {
      dispatch(
        notification({
          title: `You’ve successfully changed your framework from ${foundCurrent?.label || currentFramework} to ${
            foundNext?.label || framework
          }.`,
          message: `You will now see all of the ${foundNext?.label || framework} categories and CPIs on the platfrom.`,
          variant: 'success',
          open: true
        })
      )
      const resp = await axios.post('/api/v3/frameworks', { framework })

      if (resp.status.toString().includes('20')) {
        let customFw: any = null
        try {
          customFw = await axios.get('/api/v3/frameworks/custom')
        } catch (err) {
          console.error(err)
        }
        dispatch(
          setAccountSettings({
            ...accountSettings,
            cyFramework:
              framework !== 'nist' && framework !== 'onyxia' ? customFw?.data?.framework?.id : (framework as any)
          } as any)
        )
        localStorage.setItem('framework', JSON.stringify(framework))
        await fetchAccountData()
      }
    } catch (err) {
      console.error(err)
      dispatch(
        notification({
          message: 'Failed updating framework. Please try again later.',
          variant: 'error',
          open: true
        })
      )
      return (err as any).response?.data?.message
    }
  }

  const handleSave = async () => {
    setLoading(true)
    const prevTenant = { ...tenant }

    try {
      const framework = selected === 1 ? 'onyxia' : selected === 2 ? 'nist' : 'custom'
      setTenant({ ...prevTenant, cyFramework: framework })

      await updateFramework(framework)
    } catch (err) {
      console.error(err)
      setTenant(prevTenant)
    } finally {
      setLoading(false)
    }
  }

  const handleViewDetails = (thisFrameworkIndex: number) => {
    const frameworkObj = frameworkDefaultOptions[thisFrameworkIndex]
    const framework = frameworkObj.value

    navigate(`/framework-configuration/${framework}`)
  }

  const handleDiscard = () => {
    if (tenant) {
      const fw = tenant.cyFramework
      if (fw === 'onyxia') {
        setSelected(1)
      } else if (fw === 'nist') {
        setSelected(2)
      } else {
        setSelected(3)
      }
    }
  }

  const handleDeleteFramework = async () => {
    const callback = async () => {
      const thisFramework = customFramework

      try {
        setCustomFramework(null)
        await axios.delete('/api/v3/frameworks/custom')
        await getCustomFramework()
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Framework removed.',
              description: ''
            }
          })
        )
      } catch (err) {
        console.error(err)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed to remove framework.',
              description: ''
            }
          })
        )
        setCustomFramework(thisFramework)
      }
    }

    dispatch(
      openModal({
        type: ModalType.confirmDeleteFramework,
        props: { callback }
      })
    )
  }

  if (!initialLoad) {
    return <div />
  }
  return (
    <Container className="framework-container">
      <Header className="header">
        <Typography className="header-title">Frameworks</Typography>
        {hasEditRole && (
          <Box className="header-actions">
            {initialLoad && (!customFramework || !customFramework?.created_at) && (
              <ThemeButton themevariant="primary" onClick={() => navigate('/framework-configuration/custom/create')}>
                <>
                  <CustomFrameworkIcon />
                  Create Custom Framework
                </>
              </ThemeButton>
            )}
            <ThemeButton themevariant="primary" disabled={loading || getDiscardDisabled()} onClick={handleDiscard}>
              <>
                <DeleteOutlineIcon />
                Discard
              </>
            </ThemeButton>
            <ThemeButton themevariant="primary" disabled={loading || getSaveDisabled()} onClick={handleSave}>
              <>
                <SaveIcon />
                Save
              </>
            </ThemeButton>
          </Box>
        )}
      </Header>
      <Typography className="sub-header-text">Select your framework:</Typography>
      <ContentWrapper className="content-wrapper">
        <Content className="content">
          <FrameworkSelectCard
            content="Measure the metrics, compare your security program across industry benchmarks, & continuously evaluate program performance based on the Onyxia Framework."
            title="Onyxia"
            headerIcon="/onyxia-logo.svg"
            selected={selected === 1}
            handleChange={() => {
              if (loading) {
                return
              }
              setSelected(1)
            }}
            handleViewDetails={() => handleViewDetails(0)}
            loading={loading}
          />
          <Divider />
          <FrameworkSelectCard
            content="The Cybersecurity Framework is a set of cybersecurity best practices and recommendations from the National Institute of Standards and Technology"
            title="NIST CSF"
            headerIcon="/nist-logo.svg"
            selected={selected === 2}
            handleChange={() => {
              if (loading) {
                return
              }
              setSelected(2)
            }}
            handleViewDetails={() => handleViewDetails(1)}
            loading={loading}
          />
          {customFramework && customFramework.created_at && (
            <>
              <Divider />
              <FrameworkSelectCard
                content="Customize your own framework based on weights you define for your preferred categories and the CPIs assigned to them."
                title="Custom"
                headerIcon="/custom-icon.svg"
                selected={selected === 3}
                handleChange={() => {
                  if (loading) {
                    return
                  }
                  setSelected(3)
                }}
                handleViewDetails={() => handleViewDetails(2)}
                handleDelete={handleDeleteFramework}
                deleteDisabled={!!customFramework && tenant.cyFramework !== 'nist' && tenant.cyFramework !== 'onyxia'}
                loading={loading}
              />
            </>
          )}
        </Content>
      </ContentWrapper>
    </Container>
  )
}

export default Framework
