import { Box, Select, FormControl } from '@mui/material'
import styled from '@emotion/styled'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: 8px;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    white-space: nowrap;
  }
`

export const ResetDivider = styled(Box)`
  background: #4d444b;
  height: 1px;
  width: 100%;
  margin: 12px 0;
`

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #998d96;
  position: relative;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.8em);
    right: 8px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: #fff;
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: #fff !important;
    }
  }

  &.Mui-disabled {
    svg.svg-dropdown {
      path {
        fill: #7f747c !important;
      }
    }
  }
`

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
  isdisabled?: string
}>`
  outline: none;
  min-width: 120px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  position: relative;
  max-width: ${({ isSort }) => (isSort ? 'auto' : '200px')};

  ${({ noborder }) =>
    noborder !== 'true' &&
    `
    border: 1px solid #998d96;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: white;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    &.Mui-disabled {
      pointer-events: none;
      border: 1px solid transparent;
    }

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: #7f747c;
      }
    }
  }

  &:hover {
    border: 1px solid #ffa9fc;
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ isdisabled }) =>
    isdisabled &&
    isdisabled === 'true' &&
    `
    border: 1px solid #7f747c;
    
        &:hover {
      border-color: #7f747c;
    }
  `};

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      height: 'unset',
      minWidth: '250px',
      maxHeight: '348px !important',
      marginTop: '4px',
      overflowY: 'hidden',

      '.list-items-wrapper': {
        overflowY: 'auto',
        maxHeight: '200px',
        height: '100%',

        '&::-webkit-scrollbar-track': {
          background: '#483646'
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#ffa9fc',
          height: '4px',
          width: '4px',
          border: '4px solid transparent',
          backgroundClip: 'content-box'
        }
      },

      '.MuiList-root': {
        background: '#372A36',
        padding: '0 8px !important',
        overflowY: 'auto',
        maxHeight: '325px',
        height: '100%',

        '&::-webkit-scrollbar-track': {
          background: '#483646'
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#ffa9fc',
          height: '4px',
          width: '4px',
          border: '4px solid transparent',
          backgroundClip: 'content-box'
        },

        '.results-header-text': {
          color: '#867083',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          margin: '0 8px 8px 0'
        }
      },

      '.MuiMenuItem-root': {
        height: '40px',
        padding: 0,
        paddingRight: '8px',
        borderRadius: '4px',

        '.integration-icon': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          borderRadius: '50%'
        },

        '&:hover': {
          background: '#573F56 !important'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root': {
          paddingLeft: '8px',

          '.MuiTypography-root': {
            fontFamily: '"Quicksand", sans-serif',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: '#fff',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
