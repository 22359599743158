import React, { FC } from 'react'
import { ResetWrapper, ResetButton } from './resetSearchOnyxiaSelect.styles'

interface Props {
  resetData: () => void
  disabled?: boolean
}

const ResetSearchOnyxiaSelect: FC<Props> = ({ resetData, disabled }) => (
  <ResetWrapper>
    <ResetButton onClick={() => resetData()} disabled={disabled}>
      Reset
    </ResetButton>
  </ResetWrapper>
)

export default ResetSearchOnyxiaSelect
